import React, { useRef, useContext, useState, useEffect } from 'react';

import { Formik } from 'formik';
import {
  FormInitialValues,
  ModifySubscriptionConfigureProductsSchema,
} from 'components/organizationManagement/modifySubscriptionProductsModal/formSchemaAndInitialValues';
import { provision, validateCredential } from 'utils/api/provisioning';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import {
  getPreSelectedAvailableApplicationsV2,
  getRedoxIdForSelectedApplicationsV2,
} from 'containers/organizationManagement/editSubscriptionConfigureProducts/utils';
import {
  EDIT_SUBSCRIPTION_PATH,
  EDIT_ORGANIZATION_PATH,
} from 'utils/configuration/links';
import { NotificationContext } from 'contexts/notificationContext';
import { SUBSCRIPTION, ADVISEINTERNAL } from 'utils/configuration/settings';
import {
  getSubscriptionOptions,
  getSubscriptionSettings,
} from 'utils/api/settings';
import { useRetrieveSubscriptionEntitlementPacket } from 'utils/hooks/useToCallApiEndpoints';
import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import { useHistory } from 'react-router-dom';
import ModifySubscription from 'components/organizationManagement/modifySubscriptionProductsModal/modifySubscription';
import { RecoilRoot } from 'recoil';
import { ADVISE } from 'utils/dictionary';

const ModifySubscriptionProducts = props => {
  const closeModal = useRef({ close: false });
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken } = useContext(AuthContext);
  const { showNotification } = React.useContext(NotificationContext);
  const [status, setStatus] = useState(null);
  const [validate, setValidate] = useState(false);
  const [redoxError, setRedoxError] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [settingList, setSettingList] = useState([]);
  const [adviseOptions, setAdviseOptions] = useState([]);

  const formRef = useRef();
  const history = useHistory();

  const { organizationId, rowData = {} } = props ?? {};
  const { subscriptionId, subscriptionName } = rowData;

  const { data } = useRetrieveSubscriptionEntitlementPacket(
    organizationId,
    subscriptionId,
  );

  const {
    entitlementPacket = {},
    subscriptionLicense = {},
    licensedApplications = {},
  } = data ?? {};

  //Entitlement Packet
  const { licensing = {} } = entitlementPacket;
  const { applications = [] } = licensing;

  //Subscription License
  const { licenseKeyId } = subscriptionLicense;

  const availableApplications = applications.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1,
  );
  let selectedApplications =
    licensedApplications.length > 0
      ? Object.assign(...licensedApplications.map(a => ({ [a.sourceId]: a })))
      : {};

  if (
    Object.keys(selectedApplications).length === 0 &&
    availableApplications.length > 0
  ) {
    selectedApplications = getPreSelectedAvailableApplicationsV2(
      availableApplications,
    );
  }

  const { success, message = null, date } = status ?? {};

  async function getData() {
    const optionsResult = await getSubscriptionOptions(
      SUBSCRIPTION,
      accessToken,
    );

    setSubscriptionOptions(optionsResult.data);
    const settingsResult = await getSubscriptionSettings(
      subscriptionId,
      accessToken,
    );

    setSettingList(settingsResult.data);

    const adviseResult = await getSubscriptionOptions(
      ADVISEINTERNAL,
      accessToken,
    );
    setAdviseOptions(adviseResult.data);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message) {
      showNotification(message, !success, 5000, date);
      status.message = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, message, date]);

  const validateAppSettings = valSettings => {
    const adviseSettings = adviseOptions.map(x => x.settingId);

    const changedSettings = valSettings.map(x => x.settingId);

    if (adviseSettings.length === changedSettings.length) return {};

    const missing = adviseOptions
      .filter(x => !changedSettings.includes(x.settingId))
      .reduce(
        (obj, cur) => ({
          ...obj,
          [cur.settingId]: `${cur.name} is required and must have a value`,
        }),
        {},
      );

    return missing;
  };

  const verifyCredential = async (applications, setFieldError) => {
    const found = getRedoxIdForSelectedApplicationsV2(applications);
    if (found && found.redoxId !== '') {
      const { error } = await validateCredential(
        subscriptionId,
        found.redoxId,
        accessToken,
      );

      if (error) {
        setRedoxError({ applicationCredentialId: error.details.error.message });
        setFieldError({ applicationCredentialId: error.details.error.message });
        return false;
      }
      setRedoxError({});
      return true;
    }
    setRedoxError({});
    return true;
  };

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_SUBSCRIPTION_PATH,
          state: {
            rowData: {
              subscriptionId,
              subscriptionName: rowData.subscriptionName,
              organizationName: rowData.organizationName,
            },
            organizationId,
          },
        }}
      />
    );
  }

  const handleClose = () => {
    setConfirmOpen(false);
    history.push({
      pathname: EDIT_ORGANIZATION_PATH,
      search: window.location.search,
      state: {
        organizationId: organizationId,
      },
    });
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const editConfigureSubscriptionProducts = async (
    values,
    { resetForm, setFieldError },
  ) => {
    let valid = await verifyCredential(values.applications, setFieldError);

    const applications = Object.values(values?.applications).flatMap(x => x);

    if (valid && applications.length > 0) {
      const { validFrom, validTo } = entitlementPacket;

      const ProvisioningDto = {
        licenseKeyId: licenseKeyId ?? null,
        organizationId: organizationId,
        subscriptionId: subscriptionId,
        validFrom: validFrom,
        validTo: validTo,
        applications: applications,
        subscriptionSettings: values.settings,
      };

      const { response, error } = await provision(
        organizationId,
        subscriptionId,
        ProvisioningDto,
        accessToken,
      );

      switch (response?.status) {
        case 201:
          setStatus({
            success: true,
            message: `Successfully modified ${subscriptionName}`,
            date: Date.now(),
          });
          closeModal.current = { close: true }; //Conditional to close modal in the component
          resetForm(FormInitialValues(selectedApplications));
          setRedirect({ isRedirect: true, data: { organizationId } });
          break;
        case 400:
          if (
            error.details.error.code === 'MalformedProvisionRequestException'
          ) {
            setStatus({
              success: false,
              message:
                'Error modifying tenant. Please try again or contact your Healthwise developers.',
              date: Date.now(),
            });
          } else if (error.details.error.code === 'CompositeError') {
            //TODO: Display specific error to the user
            setStatus({
              success: false,
              message: `${error.details.error.message}. Please refresh the page.`,
              date: Date.now(),
            });
          } else {
            setStatus({
              success: false,
              message: 'Bad request. Please try again.',
              date: Date.now(),
            });
          }
          break;
        case 404:
          setStatus({
            success: false,
            message: 'Not found.',
            date: Date.now(),
          });
          break;
        case 500:
          setStatus({
            success: false,
            message:
              'Error modifying tenant. Please try again or contact your Healthwise developers.',
            date: Date.now(),
          });
          break;
        default:
          if (!response) {
            setStatus({
              success: false,
              message: 'Network error.',
              date: Date.now(),
            });
          } else {
            setStatus({
              success: false,
              message: 'Unknown error.',
              date: Date.now(),
            });
          }
          break;
      }
    }
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={FormInitialValues(selectedApplications, [])}
      validateOnBlur={false}
      validateOnChange={validate}
      validationSchema={ModifySubscriptionConfigureProductsSchema()}
      onSubmit={editConfigureSubscriptionProducts}
      validate={values => {
        setValidate(true);

        const applications = Object.values(values?.applications).flatMap(
          x => x,
        );

        const adviseSelected = applications.find(x => x.sourceId === ADVISE);

        const required = adviseSelected
          ? validateAppSettings(values.settings)
          : false;

        const found = getRedoxIdForSelectedApplicationsV2(values.applications);
        let redoxRequired = {};
        if (found.found && found.redoxId === '') {
          redoxRequired = {
            applicationCredentialId: 'A Redox ID is required to continue.',
          };
        }

        if (Object.keys(redoxError).length > 0) {
          redoxRequired = redoxError;
        }
        Object.assign(required, redoxRequired);

        if (Object.keys(required).length > 0) {
          setConfirmOpen(false);
          let settingsReq = { settings: 'Please enter all required settings.' };

          Object.assign(required, settingsReq);
        }

        return required;
      }}
      children={props => {
        return (
          <>
            {confirmOpen && props.dirty ? (
              <ConfirmationModal
                open={confirmOpen}
                title="Save Changes?"
                text="You have unsaved changes that will be lost. Do you want to save changes before leaving this page?"
                confirmText="Save"
                handleClose={handleClose}
                handleCancel={handleClose}
                handleConfirm={handleSubmit}
                cancelText="Discard Changes"
              />
            ) : null}
            <RecoilRoot>
              <ModifySubscription
                {...props}
                selectedApplications={selectedApplications}
                licensedApplications={licensedApplications}
                availableApplications={availableApplications}
                subscription={rowData}
                subscriptionOptions={subscriptionOptions}
                settingList={settingList}
                adviseOptions={adviseOptions}
                setRedoxError={setRedoxError}
              />
            </RecoilRoot>
          </>
        );
      }}
    />
  );
};

export default ModifySubscriptionProducts;
