import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/dialog/dialog';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import { TextButton, PrimaryButton } from 'components/buttons/button';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { Typography } from 'components/typography/typography';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Tooltip from '@mui/material/Tooltip';

import style from './verifyModal.module.scss';

const VerifyModal = props => {
  const {
    confirmText,
    handleCancel,
    handleClose,
    handleConfirm,
    open,
    text,
    title,
    warningText,
    hideConfirmInstructions,
    alarmStyle = false,
  } = props;

  const [submitting, setSubmitting] = useState(false);

  const inputRef = useRef(null);
  useAddFocus(inputRef, true);

  const internalConfirmationHandler = async eventHandler => {
    setSubmitting(true);
    await eventHandler();
  };

  const DeleteSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          name: '',
        }}
        validationSchema={DeleteSchema}
        onSubmit={handleConfirm}
        children={props => {
          return (
            <>
              <Dialog
                role="dialog"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                aria-labelledby="dialog-title"
                aria-describedby="dialog-warning dialog-description"
              >
                {alarmStyle && <div className={style.alarm}></div>}

                <DialogTitle id="dialog-title" className={style.title}>
                  {title}{' '}
                </DialogTitle>

                {warningText && (
                  <div className={style.warningBanner}>
                    <Typography
                      className={style.warningTextTypography}
                      id="dialog-warning"
                    >
                      {warningText}
                    </Typography>
                  </div>
                )}
                <DialogContent>
                  <DialogContentText
                    className={style.dialogContentText}
                    id="dialog-description"
                  >
                    {text} {!hideConfirmInstructions && "Type 'confirm' below."}
                  </DialogContentText>
                  <BaselineTextInput
                    id="name"
                    name="name"
                    fullWidth
                    requiredErrorMessage="Please type 'confirm' to continue"
                    className={style.textField}
                  />
                </DialogContent>

                <DialogActions className={style.rightAlignedBtnGroup}>
                  <TextButton
                    buttonText={'Cancel'}
                    handleClick={() => {
                      handleCancel();
                    }}
                  />
                  {submitting ? (
                    <ProgressIndicator />
                  ) : (
                    <>
                      {props.touched.name &&
                      props.values?.name?.length === 0 ? (
                        <Tooltip
                          title="Please resolve errors before continuing"
                          aria-describedby="Please resolve errors before continuing"
                          placement="top"
                          arrow
                        >
                          <span>
                            <PrimaryButton
                              type="submit"
                              variant="contained"
                              buttonText={confirmText}
                            ></PrimaryButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <>
                          {props.values?.name?.length !== 0 ? (
                            <PrimaryButton
                              variant="contained"
                              type="submit"
                              buttonText={confirmText}
                              handleClick={() => {
                                internalConfirmationHandler(handleConfirm);
                              }}
                            />
                          ) : (
                            <PrimaryButton
                              variant="contained"
                              type="submit"
                              buttonText={confirmText}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      />
    </>
  );
};

export default VerifyModal;

VerifyModal.propTypes = {
  confirmText: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  open: PropTypes.bool.isRequired,
  warningText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hideConfirmInstructions: PropTypes.bool,
};

VerifyModal.defaultProps = {
  hideConfirmInstructions: false,
};
