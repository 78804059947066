import React from 'react';
import ApplicationSelector from 'components/applicationSelector/applicationSelector';
import style from './roleAssignment.module.scss';

// Pass in `readOnly` to disable the adding/removing of values
const RoleAssignment = props => {
  return (
    <>
      <ApplicationSelector
        className={style.subscriptionSelector}
        compact={true}
        allowMultiple={true}
        {...props}
      />
    </>
  );
};

export default RoleAssignment;
