import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Grid } from '@mui/material';
import { Formik } from 'formik';

import { getApplication, updateApplication } from 'utils/api/applications';

import { PageHeader } from 'components/pageHeader/pageHeader';
import { Container } from '@mui/material';
import ApplicationDetailsCard from './components/applicationDetailsCard';
import EditApplicationRoles from './components/editRoles';
import EditApplicationAssets from './components/editAssets';
import EditApplicationReports from './components/editReports';

import FeatureToggleHealthwiseOnly from 'components/featureToggleHealthwiseOnly/featureToggleHealthwiseOnly';
import { FEATURE_TOGGLE_REPORTS_CONFIG } from 'utils/dictionary/featureToggles';
import { AuthContext } from 'contexts/authContext';
import { NotificationContext } from 'contexts/notificationContext';

import {
  emptyApplication,
  applicationValidationSchema,
} from './components/application';

const applicationReducerFunction = (application, newInformation) => {
  return { ...application, ...newInformation };
};

const EditApplication = props => {
  const { applicationId } = props;

  const authContext = useContext(AuthContext);
  const { showNotification } = useContext(NotificationContext);
  const [etag, setEtag] = useState('');
  const [status, setStatus] = useState(null);

  // Used for the material table edit/display
  const [rolesList, setRolesList] = useState();
  const [assetsList, setAssetsList] = useState();
  const [reportsList, setReportsList] = useState();

  useEffect(() => {
    if (status) {
      showNotification(status.message, !status.success, 10000, Date.now());
      setStatus(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // The Application Information details
  // Merge the application information into a single datasource. Used during the data load of the
  // application from the backend service.
  const [applicationInformation, setApplicationInformation] = useReducer(
    applicationReducerFunction,
    emptyApplication,
  );

  // Load the roles from the applicationInformation.
  useEffect(() => {
    if (applicationInformation) {
      setRolesList(applicationInformation.roles);
    }
  }, [applicationInformation, applicationInformation.roles]);

  // Load the assets from the applicationInformation.
  useEffect(() => {
    if (applicationInformation) {
      setAssetsList(applicationInformation.applicationAssets);
    }
  }, [applicationInformation, applicationInformation.applicationAssets]);

     // Load the reports from the applicationInformation.
     useEffect(() => {
      if (applicationInformation) {
        setReportsList(applicationInformation.applicationReports);
      }
    }, [applicationInformation, applicationInformation.applicationReports]);

  // Load the requested application from the backend service
  useEffect(() => {
    async function getApplicationInformation() {
      try {
        const { response, data, etag, error } = await getApplication(
          applicationId,
          authContext.accessToken,
        );
        if (
          response.ok &&
          data.data.applicationId.toLowerCase() === applicationId.toLowerCase()
        ) {
          setApplicationInformation(data.data);
          setEtag(etag);
        } else {
          // We got a 4xx / 5xx in response
          throw new Error(`${response.status} - ${error.message}`);
        }
      } catch (err) {
        // TODO Render Error to user
        console.error('Unable to get the requested application');
        console.error(err);
      }
    }

    if (applicationId !== null && authContext && authContext.accessToken) {
      getApplicationInformation();
    }
  }, [applicationId, authContext, authContext.accessToken]);

  async function updateApplicationInformation(
    applicationId,
    updatedApplicationInformation,
    accessToken,
  ) {
    try {
      // So turns out having a state of 'etag' and a response of 'etag' collides. As much as I'd
      // like to do { response, etag } for the return values of updateApplication, that will cause
      // the etag state to be overriden or something.
      const result = await updateApplication(
        applicationId,
        updatedApplicationInformation,
        etag,
        accessToken,
      );

      if (result.response.ok) {
        setStatus({
          success: true,
          message: 'Application updated successfully',
        });
        setEtag(result.etag);
      } else {
        setStatus({
          success: false,
          message: 'Failed to update application',
        });
      }
    } catch (err) {
      // TODO Render Error to user
      console.error('Unable to update the application');
      console.error(err);
    }
  }

  const saveApplication = async (
    values,
    { initialValues, setSubmitting, setStatus },
  ) => {
    await updateApplicationInformation(
      values.applicationId,
      values,
      authContext.accessToken,
    );
  };

  return (
    <>
      <PageHeader title="Application Details" />
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Formik
              enableReinitialize
              initialValues={applicationInformation}
              validationSchema={applicationValidationSchema}
              onSubmit={saveApplication}
              children={props => (
                <ApplicationDetailsCard
                  application={applicationInformation}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item>
            <EditApplicationRoles
              applicationId={applicationId}
              rolesList={rolesList}
              setRolesMethod={setRolesList}
              setStatus={setStatus}
            />
          </Grid>
          <Grid item>
            <EditApplicationAssets
              applicationId={applicationId}
              assetsList={assetsList}
              setAssetsMethod={setAssetsList}
              setStatus={setStatus}
            />
          </Grid>
          <FeatureToggleHealthwiseOnly featureName={FEATURE_TOGGLE_REPORTS_CONFIG}>
            <Grid item>
              <EditApplicationReports
                applicationId={applicationId}
                reportsList={reportsList}
                setReportsMethod={setReportsList}
                setStatus={setStatus}
              />
            </Grid>
          </FeatureToggleHealthwiseOnly>
        </Grid>
      </Container>
    </>
  );
};

export default EditApplication;
