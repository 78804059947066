import { format, parseISO, formatISO } from 'date-fns';

export const FormatReadable = date => {
  return date ? format(date, 'MMMM dd, yyyy') : undefined;
};

export const TimeAndDateFormatReadable = date => {
  return date ? format(date, "MMM dd, yyyy 'at' h:mm aaa") : undefined;
};

export const DateFormatReadable = date => {
  return date ? format(date, 'MMM dd, yyyy') : undefined;
};

export const FormatISO = (date, dateFormat) => {
  return date ? format(parseISO(date), dateFormat) : undefined;
};

export const GetFormattedISODateOffsetString = date => {
  return date && !isNaN(date) ? formatISO(date) : undefined;
};

export const timeFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
});
export const dateFormatter = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

export default FormatISO;
