import React, { useState } from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import style from './notification.module.scss';

const BaseNotification = ({ duration, ...props }) => {
  const [open, setOpen] = useState(true);
  return ReactDOM.createPortal(
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        autoHideDuration={duration}
        onClose={() => setOpen(false)}
      >
        {props.children}
      </Snackbar>
    </>,
    document.body,
  );
};

const Notification = props => {
  const {
    duration = 5000,
    message,
    messageKey = 'default',
    error = false,
  } = props;

  return (
    <>
      <BaseNotification duration={duration} key={messageKey} {...props}>
        {error ? (
          <SnackbarContent
            className={`${style.snackBarContent} ${style.snackBarError}`}
            message={message}
          />
        ) : (
          <SnackbarContent
            className={style.snackBarContent}
            message={message}
          />
        )}
      </BaseNotification>
    </>
  );
};

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

export default Notification;
