import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import * as FeatureToggles from 'utils/dictionary/featureToggles';

import style from './featureList.module.scss';

const FeatureList = () => {
  const history = useHistory();
  const location = useLocation();

  const activeFeatures = JSON.parse(sessionStorage.getItem('features')) ?? [];
  const availableFeatures = Object.values(FeatureToggles);

  const handleCheckboxChange = () => {
    const checkedNodeList = document.querySelectorAll(
      'input[type="checkbox"]:checked',
    );

    const checkedElements = {};
    checkedNodeList.forEach(item => (checkedElements[item.value] = true));
    const newChecked = availableFeatures.filter(
      option => checkedElements[option],
    );

    const queryObject = {
      ...queryString.parse(location.search),
      features: newChecked.length ? newChecked : '',
    };

    history.replace({
      ...location,
      search: queryString.stringify(queryObject, { arrayFormat: 'comma' }),
    });
  };

  return (
    <>
      {availableFeatures.map(af => (
        <FormControlLabel
          key={`${af}-label`}
          label={af}
          className={style.checkboxLabel}
          control={
            <Checkbox
              key={`${af}-cb`}
              onChange={handleCheckboxChange}
              name={af}
              checked={activeFeatures.some(v => v === af)}
              value={af}
              className={style.checkbox}
              color="primary"
            />
          }
        />
      ))}
    </>
  );
};

export default FeatureList;
