import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'contexts/authContext';
import { getSubscriptionSettings } from 'utils/api/settings';

export const useIsContentFeedbackEnabled = () => {
  const [subscriptionSettings, setSubscriptionSettings] = useState({});

  const [contentFeedbackEnabled, setContentFeedbackEnabled] = useState(false);
  const { subscriptionId, accessToken } = useContext(AuthContext);

  async function getData(subscriptionId, accessToken) {
    const settingsResult = await getSubscriptionSettings(
      subscriptionId,
      accessToken,
    );

    if (settingsResult?.data) {
      const CfSetting = settingsResult?.data?.filter(setting => {
        return setting.name === 'Content Feedback' ? true : false;
      });
      setSubscriptionSettings(...CfSetting);
    }
  }

  useEffect(() => {
    if (subscriptionId) {
      getData(subscriptionId, accessToken);
    }
  }, [subscriptionId, accessToken]);

  useEffect(() => {
    if (subscriptionSettings?.option?.optionValue === 'true')
      setContentFeedbackEnabled(true);
  }, [subscriptionSettings]);

  return {
    contentFeedbackEnabled: contentFeedbackEnabled,
  };
};
