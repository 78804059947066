import React, { useContext } from 'react';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import NotesForm from 'components/notesForm/notesForm';
import {
  TenantNotesSchema,
  FormInitialValues,
} from 'components/tenantManagement/tenantNotes/formSchemaAndInitialValues';
import { AuthContext } from 'contexts/authContext';
import { createTenantNote } from 'utils/api/tenant';

const CreateTenantNotes = props => {
  const { accessToken } = useContext(AuthContext);
  const {
    rowData: { subscriptionId: tenantId },
    setTriggerRerender,
  } = props;

  const sendNotes = async (values, { setStatus }) => {
    const payload = {
      note: values?.note,
    };

    const { response } = await createTenantNote(tenantId, payload, accessToken);
    values.note = '';
    switch (response?.status) {
      case 201:
        setStatus({
          success: true,
          message: 'Tenant Note saved successfully.',
          messageKey: uuidv4(),
        });
        setTriggerRerender(uuidv4());
        break;
      case 401:
        setStatus({
          success: false,
          message: 'Unauthorized',
        });
        break;
      case 500:
        setStatus({
          success: false,
          message:
            'Unexpected error occurred creating new tenant note. Please wait a moment and try again.',
          messageKey: uuidv4(),
        });
        setTriggerRerender(uuidv4());
        break;
      default:
        if (!response) {
          setStatus({
            success: false,
            message: 'Network error.',
            messageKey: uuidv4(),
          });
        } else {
          setStatus({
            success: false,
            message: 'Unknown error.',
            messageKey: uuidv4(),
          });
        }
        break;
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={FormInitialValues}
      validationSchema={TenantNotesSchema}
      onSubmit={sendNotes}
      children={props => <NotesForm {...props} tenant={true} />}
    />
  );
};

export default CreateTenantNotes;
