import styled from 'styled-components';
import { Typography as MaterialTypography } from '@mui/material';

// This overrides certain aspects of Material UI's Typography styling with our
// own theme.
export const Typography = styled(MaterialTypography)`
  &.MuiTypography-h1 {
    font-size: ${props => props.theme.headerScale[1]};
  }
  &.MuiTypography-h2 {
    font-size: ${props => props.theme.headerScale[2]};
  }
  &.MuiTypography-h3 {
    font-size: ${props => props.theme.headerScale[3]};
  }
  &.MuiTypography-h4 {
    font-size: ${props => props.theme.headerScale[4]};
  }
  &.MuiTypography-h5 {
    font-size: ${props => props.theme.headerScale[5]};
  }
  &.MuiTypography-h6 {
    font-size: ${props => props.theme.headerScale[6]};
  }
`;
