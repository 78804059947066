import * as Yup from 'yup';

// Base Application Schema/Shape.
// Note that the 'newRedirectUrl', 'newLogoutUrls', and 'newWebOriginsUrls' are placeholders so 
//   that the ability to add new urls works.
export const emptyApplication = {
  applicationId: ``,
  sourceId: ``,
  active: false,
  applicationAssets: [],
  applicationReports: [],
  available: false,
  availableFrom: ``,
  description: ``,
  fullyQualifiedName: ``,
  icon: ``,
  name: ``,
  oktaId: ``,
  referenceId: ``,
  owner: ``,
  ownerEmail: ``,
  redirectUrls: [],
  logoutUrls: [],
  webOriginsUrls: [],
  roles: [],
  route: ``,
  versionNumber: ``,
  newRedirectUrl: ``,
  newLogoutUrl: ``,
  newWebOriginUrl: ``,

};

//
// Not sure why we need 3 functions to set/retrieve the schema, but that's how user.js does it.
//
// TODO - Add actual validation schema requirements, e.g. `.max(40, 'Must be 40 character or less')`
//
export const ApplicationValidationSchemaDefinition = {
  applicationId: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  ownerEmail: Yup.string().email('Invalid email').required('Required'),
};

export const getApplicationValidationSchema = schemaObject => {
  return Yup.object().shape(schemaObject);
};

export const applicationValidationSchema = getApplicationValidationSchema(
  ApplicationValidationSchemaDefinition,
);
