import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Form } from 'formik';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/dialog';
import { TextButton, PrimaryButton } from 'components/buttons/button';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import Tooltip from '@mui/material/Tooltip';
import style from './subscriptionSwitchModal.module.scss';

const SwitchModal = props => {
  const {
    active,
    open,
    handleClose,
    subscriptionName,
    handleSubmit,
    isSubmitting,
  } = props;

  const dialogText = active
    ? 'Deactivate this tenant?'
    : 'Activate this tenant?';
  const confirmationButtonText = active
    ? 'Deactivate Tenant'
    : 'Activate Tenant';

  return (
    <>
      <Dialog
        role="dialog"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-warning dialog-description"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle id="dialog-title">{dialogText}</DialogTitle>
          {active ? (
            <DialogContent className={style.dialogContent}>
              <p className={style.highlightedText} id="dialog-warning">
                Deactivating a tenant will immediately remove access to any{' '}
                {'\n'}
                applications and content within that tenant.
              </p>
              <p className={style.modalText} id="dialog-description">
                Are you sure you want to deactivate{' '}
                <strong>{subscriptionName}</strong>?{'\n'}
                Type 'confirm' below.
              </p>
              <div className={style.textFieldContainer}>
                <BaselineTextInput
                  id="subscriptionSwitch"
                  name="subscriptionSwitch"
                  fullWidth
                  requiredErrorMessage="Please type 'confirm' to continue"
                />
              </div>
            </DialogContent>
          ) : (
            <DialogContent className={style.dialogContent}>
              <p className={style.modalText} id="dialog-description">
                Activating <strong>{subscriptionName}</strong> will provide
                immediate access to any {'\n'}applications and content within
                that tenant.
              </p>
            </DialogContent>
          )}

          <DialogActions className={style.modalBtnsContainer}>
            <TextButton
              buttonText={'Cancel'}
              handleClick={() => {
                handleClose();
              }}
            />
            {isSubmitting ? (
              <ProgressIndicator />
            ) : (
              <>
                {props.touched.subscriptionSwitch &&
                (props.values?.subscriptionSwitch?.length === 0 ||
                  props.values?.subscriptionSwitch != 'confirm') ? (
                  <Tooltip
                    title="Please resolve errors before continuing"
                    aria-describedby="Please resolve errors before continuing"
                    placement="top"
                    arrow
                  >
                    <span>
                      <PrimaryButton
                        type="submit"
                        variant="contained"
                        buttonText={confirmationButtonText}
                      ></PrimaryButton>
                    </span>
                  </Tooltip>
                ) : (
                  <>
                    {props.values?.subscriptionSwitch?.length !== 0 ? (
                      <PrimaryButton
                        variant="contained"
                        type="submit"
                        buttonText={confirmationButtonText}
                        handleClick={() => {
                          handleSubmit();
                        }}
                      />
                    ) : (
                      <PrimaryButton
                        variant="contained"
                        type="submit"
                        buttonText={confirmationButtonText}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

const SubscriptionSwitchModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { closeModal, handleReset, closeMenu } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (handleReset) handleReset();
    setOpen(false);
    closeMenu();
  };

  //Close modal when form is submitted successfully
  //Couldn't think of a better way to implement this
  if (closeModal?.current?.close) {
    closeModal.current = { close: false };
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      handleOpen();
    },
  }));

  return (
    <SwitchModal
      {...props}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
    />
  );
});

export default SubscriptionSwitchModal;
