import React from 'react';
import { Form, useField } from 'formik';
import Notification from 'components/notificationV2/notification';
import { PrimaryButton, TextButton } from 'components/buttons/button';
import { BaselineTextField } from 'components/textInputV2/textInput';
import style from './notesForm.module.scss';

const NoteTextField = props => {
  const [field, meta] = useField(props);
  return <BaselineTextField {...props} {...field} error={!!meta.error} />;
};

const NotesForm = props => {
  const { isSubmitting, status, editNotes, handleClick, tenant } = props;
  const { success, message = '' } = status ?? {};

  return (
    <>
      <Form className={style.orgNotesform}>
        <NoteTextField
          name="note"
          id="note"
          multiline
          minRows="7"
          fullWidth
          placeholder="Add your note here…"
          aria-label="Add your note here…"
          className={`${style.addNoteTextField} ${
            tenant ? style.tenantAddNoteTextField : ''
          }`}
        />
        <div
          className={`${style.addNoteFooter} ${
            tenant ? style.tenantNoteFooter : style.orgNoteFooter
          }`}
        >
          {editNotes && (
            <TextButton
              onClick={handleClick}
              buttonText="Cancel"
              className={style.cancelBtn}
            />
          )}
          <PrimaryButton
            type="submit"
            disabled={isSubmitting}
            buttonText="Save Note"
          />
        </div>
      </Form>
      {success === false ? (
        <Notification error duration={3000} message={message} />
      ) : null}
    </>
  );
};

export default NotesForm;
