import React, {
  useRef,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import SubscriptionSwitchModal from 'components/organizationManagement/organizationManagementEditOrganization/subscriptionSwitchModal/subscriptionSwitchModal';
import { Formik } from 'formik';
import { AuthContext } from 'contexts/authContext';
import { Redirect } from 'react-router-dom';
import { updateSubscription, getSubscription } from 'utils/api/subscriptions';
import {
  SubscriptionSwitchSchema,
  SubscriptionSwitchEmptySchema,
  SubscriptionSwitchInitialValues,
  SubscriptionSwitchEmptyInitialValues,
} from 'components/organizationManagement/organizationManagementEditOrganization/subscriptionSwitchModal/formSchemaAndInitialValues';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { NotificationContext } from 'contexts/notificationContext';

const SubscriptionSwitch = forwardRef((props, ref) => {
  const [redirect, setRedirect] = useState({ isRedirect: false });
  const { accessToken } = useContext(AuthContext);
  const { rowData, organization, closeMenu } = props;
  const closeModal = useRef({ close: false }); //Need to think about better way to handle this, this is the best I can think of at this time
  const { active, subscriptionId, subscriptionName } = rowData ?? {};
  const { organizationId } = organization ?? {};
  const { showNotification } = React.useContext(NotificationContext);
  const [status, setStatus] = useState(null);

  const switchChildRef = useRef();

  useImperativeHandle(ref, () => ({
    handleOpen() {
      switchChildRef.current.handleOpen();
    },
  }));

  const { isError, message = null, date } = status ?? {};

  useEffect(() => {
    if (message) {
      let errorMsg = '';
      if (message === 'activated.') {
        errorMsg =
          'Error activating subscription. Please try again or contact your Healthwise developers.';
      } else if (message === 'deactivated.') {
        errorMsg =
          'Error deactivating subscription. Please try again or contact your Healthwise developers.';
      } else {
        errorMsg =
          'Error renaming subscription. Please try again or contact your Healthwise developers.';
      }
      showNotification(isError ? errorMsg : message, isError, 5000, date);
      status.message = null;
      errorMsg = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, message, date]);

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          search: window.location.search,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const subscriptionActivationDeactivation = async (values, { resetForm }) => {
    const subscription = { active: !active };

    const { etag: subscriptionEtag } = await getSubscription(
      organizationId,
      subscriptionId,
      accessToken,
    );

    const { response } = await updateSubscription(
      organizationId,
      subscriptionId,
      subscription,
      subscriptionEtag,
      accessToken,
    );

    const action = !active ? 'activated.' : 'deactivated.';

    switch (response?.status) {
      case 200:
        setStatus({
          isError: false,
          message: `${subscriptionName} has been successfully ${action}`,
          date: Date.now(),
        });
        closeModal.current = { close: true }; //Conditional to close modal in the create organization modal component. Have to be before resetform to cause a rerender
        resetForm(
          active
            ? SubscriptionSwitchInitialValues
            : SubscriptionSwitchEmptyInitialValues,
        );
        setRedirect({
          isRedirect: true,
          data: { organizationId },
        });
        break;
      default:
        setStatus({
          isError: true,
          message: action, //Passing action to set the appropriate error messages for activation, deactivation and renaming
          date: Date.now(),
        });
        break;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        active
          ? SubscriptionSwitchInitialValues
          : SubscriptionSwitchEmptyInitialValues
      }
      validationSchema={
        active ? SubscriptionSwitchSchema : SubscriptionSwitchEmptySchema
      }
      onSubmit={subscriptionActivationDeactivation}
      children={props => (
        <SubscriptionSwitchModal
          {...props}
          closeModal={closeModal}
          active={active}
          closeMenu={closeMenu}
          subscriptionName={subscriptionName}
          ref={switchChildRef}
        />
      )}
    />
  );
});

export default SubscriptionSwitch;
