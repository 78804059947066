import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_SCOPE,
  REACT_APP_FRONTDOOR_ISSUER,
} from 'utils/dictionary/env';

import '@fontsource/roboto';
import '@fontsource/roboto/700.css';

import * as serviceWorker from 'serviceWorker';
import { Auth0DataProvider } from 'contexts/auth0Context';
import { Auth0App } from 'components/auth0App/auth0App';

const container = document.getElementById('root');
// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <>
    <Auth0DataProvider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={REACT_APP_AUTH0_AUDIENCE}
      scope={REACT_APP_AUTH0_SCOPE}
      myHealthwiseUrl={REACT_APP_FRONTDOOR_ISSUER}
    >
      <Auth0App />
    </Auth0DataProvider>
  </>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
