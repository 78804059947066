import env from '@beam-australia/react-env';

export const REACT_APP_FRONTDOOR_ISSUER = env('FRONTDOOR_ISSUER');
export const REACT_APP_API_BASE_URL = env('API_BASE_URL');
export const REACT_APP_DEV_TOOLS = env('DEV_TOOLS');
export const REACT_APP_INLINE_RUNTIME_CHUNK = env('INLINE_RUNTIME_CHUNK');
export const REACT_APP_IMAGE_INLINE_SIZE_LIMIT = env('IMAGE_INLINE_SIZE_LIMIT');
export const REACT_APP_HELP_URL = env('HELP_URL');
export const REACT_APP_LOG_REQUEST_URL = env('LOG_REQUEST_URL');

export const REACT_APP_AUTH0_DOMAIN = env('AUTH0_DOMAIN');
export const REACT_APP_AUTH0_CLIENT_ID = env('AUTH0_CLIENT_ID');
export const REACT_APP_AUTH0_AUDIENCE = env('AUTH0_AUDIENCE');
export const REACT_APP_AUTH0_SCOPE = env('AUTH0_SCOPE');
export const PROVISIONING_ORIG_APP_ID = 'aCR1T00000000agWAA';

//Docker file injected environment variables
export const COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH;
export const BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER;
export const BUILD_ID = process.env.REACT_APP_BUILD_ID;
