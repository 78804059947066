/* FEATURE TOGGLE NAMES */
/* These are the possible feature toggles that can be used via adding the query
    parameter `features=<name>,<name>,<name>`. There's nothing special about 
    these strings, other than that they are shared keys. Note: Features hidden
    behind feature toggles ARE NOT SECURE and SHOULD NOT BE TREATED AS SUCH. */
export const FEATURE_TOGGLE_DEBUG = 'debug';

export const FEATURE_TOGGLE_DELETE_ORG = 'deleteorg';

export const FEATURE_TOGGLE_CONTENT_FEEDBACK = 'content-feedback';

export const FEATURE_TOGGLE_REMOVE_TENANT_ADMINISTRATOR = 'removeTenantAdmin';

export const FEATURE_TOGGLE_REPORTS_CONFIG = 'reportsconfig';