import { SUBSCRIPTIONS } from 'utils/configuration/subscriptions';
import { ORGANIZATIONS_ROOT } from 'utils/configuration/organizations';
import { PROVISIONING_START } from 'utils/configuration/provisioning';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;

const organizationsUrl = baseUrl + ORGANIZATIONS_ROOT;

export async function configureProductFeaturesAndContent(
  organizationId,
  subscriptionId,
  provisioning,
  accessToken,
) {
  return await utils.createResource(
    `${organizationsUrl}/${organizationId}/${SUBSCRIPTIONS}/${subscriptionId}/${PROVISIONING_START}`,
    provisioning,
    accessToken,
  );
}

export async function provision(
  organizationId,
  subscriptionId,
  provisioning,
  accessToken,
) {
  return await utils.createResource(
    `${organizationsUrl}/${organizationId}/${SUBSCRIPTIONS}/${subscriptionId}/provision`,
    provisioning,
    accessToken,
  );
}

export async function validateCredential(
  subscriptionId,
  credentialId,
  accessToken,
) {
  return await utils.getResource(
    `${organizationsUrl}/${SUBSCRIPTIONS}/${subscriptionId}/credential/${credentialId}`,
    accessToken,
  );
}
