import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import routes from 'utils/routes';
import { Grid } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import HeaderV2 from 'components/headerV2/headerV2';
import Footer from 'components/footer/footer';

import { AuthHandler } from 'contexts/authContext';
import { ProfileProvider } from 'contexts/profileContext';
import { NotificationProvider } from 'contexts/notificationContext';
import { DelegatedAccessProvider } from 'contexts/delegatedAccessContext';

import DelegatedAccessList from 'pages/delegates/components/delegatedAccessList/delegatedAccessList';
import NotFoundPage from 'pages/404';
import { theme } from 'utils/styles';
import { TenantProvider } from 'contexts/tenantContext';
import { OrganizationProvider } from 'contexts/organizationContext';
import ContentFooter from 'components/contentFooter/contentFooter';
import { AuthContext } from 'contexts/authContext';
import 'styles/_styles.scss';
import { DELEGATED_ACCESS_PATH, LOGOUT_PATH } from 'utils/configuration/links';
import NavigationTabs from 'components/navigationTabs/navigationTabs';

const App = () => {
  const { organizationAlias } = useContext(AuthContext);

  // Need to adjust the route here
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              height: '100%',
            }}
          >
            <OrganizationProvider>
              <TenantProvider>
                <ProfileProvider>
                  <HeaderV2 />
                  <NavigationTabs />
                </ProfileProvider>

                <main
                  style={{
                    marginBottom: '5em',
                    flex: '1 1 auto',
                    zIndex: '900',
                  }}
                  id="main"
                  tabIndex="-1"
                >
                  <Grid
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={10}>
                      <Switch>
                        {routes.map((route, index) => (
                          <Route
                            key={index}
                            path={`/${organizationAlias}${route.path}`}
                            exact={route.exact}
                            component={props => {
                              return <route.main {...props} />;
                            }}
                          />
                        ))}
                        <Route path={`/${organizationAlias}/${LOGOUT_PATH}`}>
                          <></>
                        </Route>
                        <Route
                          path={`/${organizationAlias}/${DELEGATED_ACCESS_PATH}`}
                        >
                          <DelegatedAccessProvider>
                            <DelegatedAccessList />
                          </DelegatedAccessProvider>
                        </Route>

                        <Route path="/index.html">
                          <Redirect to={`/${organizationAlias}/`} />
                        </Route>
                        <Route exact path={`/${organizationAlias}/`}>
                          <Redirect to={`/${organizationAlias}/users`} />
                        </Route>
                        <Route path="*">
                          <NotFoundPage />
                        </Route>
                      </Switch>
                    </Grid>
                    <AuthHandler />
                  </Grid>
                </main>

                <footer style={{ flex: '0 1 auto', marginTop: '-150px' }}>
                  <ContentFooter />
                  <Footer />
                </footer>
              </TenantProvider>
            </OrganizationProvider>
          </div>
        </NotificationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
