import React, { useContext, useState, useEffect } from 'react';
import { Grid, Tabs, Tab } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';

import * as PATHS from 'utils/configuration/links';

import { AuthContext } from 'contexts/authContext';
import { OrganizationContext } from 'contexts/organizationContext';
import style from './navigationTabs.module.scss';
import {
  TENANT_ADMINISTRATOR_ROLE,
  HEALTHWISE_ORG,
  APPLICATION_ADMINISTRATOR_ROLE,
} from 'utils/dictionary/overview';

function currentTab(location) {
  let path = location.pathname.toLowerCase();
  if (path.includes('user')) return 1;
  else if (path.includes('delegates')) return 3;
  else if (
    path.includes('/organizations') ||
    path.includes('/edit-organization')
  )
    return 4;
  else if (path.includes('/applications') || path.includes('/edit-application'))
    return 5;
  else return 1;
}

const NavigationTabs = () => {
  const location = useLocation();
  const { organization } = useContext(OrganizationContext);
  const { managementRoles, isProvisioningManager, organizationAlias } =
    useContext(AuthContext);

  const [tabValue, setTabValue] = useState(currentTab(location));

  useEffect(() => {
    setTabValue(currentTab(location));

    const scrollButtons = document.getElementsByClassName(
      'MuiTabs-scrollButtons',
    );
    for (let i = 0; i < scrollButtons.length; i++) {
      scrollButtons[i].setAttribute('aria-hidden', 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container className={style.gridContainer}>
      <Grid item container justifyContent="left" className={style.gridItem}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="nav tabs"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              height: '4px',
              backgroundColor: 'red',
              marginBottom: '10px',
              transform: 'scaleX(0.83)',
              borderRadius: '8px',
            },
          }}
        >
          {managementRoles.includes(TENANT_ADMINISTRATOR_ROLE) && (
            <Tab
              className={style.tab}
              label="Manage Users"
              component={Link}
              to={{
                pathname: `/${organizationAlias}/${PATHS.USER_MANAGEMENT_PATH}`,
                search: window.location.search,
              }}
              value={1}
              tabIndex={0}
              disableRipple
            />
          )}
          {Object.keys(organization).length !== 0 &&
            organization?.organizationName !== HEALTHWISE_ORG && (
              <Tab
                className={style.tab}
                label="Delegate Access"
                to={{
                  pathname: `/${organizationAlias}/${PATHS.DELEGATED_ACCESS_PATH}`,
                  search: window.location.search,
                }}
                component={Link}
                value={3}
                tabIndex={0}
                disableRipple
              />
            )}
          {isProvisioningManager && (
            <Tab
              className={style.tab}
              label="Organization Dashboard"
              component={Link}
              to={{
                pathname: `/${organizationAlias}/${PATHS.ORGANIZATION_LIST}`,
                search: window.location.search,
              }}
              value={4}
              tabIndex={0}
              disableRipple
            />
          )}
          {managementRoles.includes(APPLICATION_ADMINISTRATOR_ROLE) && (
            <Tab
              className={style.tab}
              label="Applications"
              to={{
                pathname: `/${organizationAlias}/${PATHS.APPLICATIONS_PATH}`,
                search: window.location.search,
              }}
              component={Link}
              value={5}
              style={{ minWidth: '100px' }}
              tabIndex={0}
              disableRipple
            />
          )}
        </Tabs>
      </Grid>
    </Grid>
  );
};

export default NavigationTabs;
