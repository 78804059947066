import React from 'react';
import style from './pageHeader.module.scss';

/**
 * @param title The title of the page
 * @param secondary Page description
 */
export const PageHeader = props => {
  const { title, secondary } = props;

  return (
    <>
      <div className={style.container}>
        <div className={style.inner}>
          {title ? (
            <span className={`${style.title} ${secondary ? '' : style.center}`}>
              {title}
            </span>
          ) : null}

          {secondary ? (
            <span className={style.secondary}>{secondary}</span>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={style.gap}></div>
    </>
  );
};

export default PageHeader;
