import React from 'react';
import style from './internalTenantNotesPanel.module.scss'
import CreateTenantNotes from 'containers/tenantManagement/createTenantNotes/createTenantNotes';
import { useGetTenantNotes } from 'utils/hooks/tenants/useGetTenantNotes';
import EditTenantNotes from 'containers/tenantManagement/editTenantNotes/editTenantNotes';
import DeleteTenantNotes from 'containers/tenantManagement/deleteTenantNotes/deleteTenantNotes';
import SavedNotes from 'components/savedNotes/savedNotes';

const InternalTenantNotesPanel = props => {
  const [triggerRerender, setTriggerRerender]= React.useState("")
  const { subscriptionEtag } = props;

  const { data } = useGetTenantNotes(props?.rowData?.subscriptionId, triggerRerender)

  return (
    <div className={style.container}>
        <div className={style.title}>Internal Notes</div>
        <div className={style.subTitle}>
          These notes are NOT visible to the client.
        </div>
        <div className={style.notesContainer}>
          <CreateTenantNotes setTriggerRerender={setTriggerRerender} {...props} />
          {data?.map((note, index) => {
              return (
                <div key={note.tenantNotesId} className={style.noteCard}>
                    <SavedNotes
                      key={index}
                      index={index}
                      note={note}
                      tenant={true}
                      renderDeleteSavedNotes={props => {
                        return (
                          <DeleteTenantNotes {...props} setTriggerRerender={setTriggerRerender} subscriptionEtag={subscriptionEtag} currentNote={note}/>
                        )
                      }}
                      renderEditNotes={(props, editNotes, handleClick) => {
                        return (
                          <EditTenantNotes setTriggerRerender={setTriggerRerender} subscriptionEtag={subscriptionEtag} editNotes={editNotes} handleClick={handleClick} {...props} />
                        )
                      }}
                    />
                </div>
              )
          })}
        </div>
    </div>
  )
}

export default InternalTenantNotesPanel