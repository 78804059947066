import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import { BaselineTextInput } from 'components/textInputV2/textInput';
import { CalendarMonthIcon, ClearIcon } from 'components/icons/icons';
import { FormatReadable } from 'utils/timeAndDate';

import style from './accountLifespanFields.module.scss';

const DatepickerField = props => {
  const [isDateOpen, setDateOpen] = React.useState(false);

  const {
    fieldName,
    isRequired,
    defaultCalendarMonth,
    minDate,
    maxDate,
    value,
    setValue,
    setMessage,
    fieldLabel,
    externalLabel,
    helperTextId,
    errorLabel,
    inputRef,
    textProps,
    disableCloseButton,
    disabled,
    removeAdornments,
    trim,
    focusStartDate,
  } = props;

  const components = {
    OpenPickerIcon: CalendarMonthIcon,
  };

  // https://github.com/mui/mui-x/issues/4487
  const commonProps = {
    components: components,
    disableMaskedInput: true,
    inputFormat: 'dd MMM yyyy',
    componentsProps: {
      leftArrowButton: { disableFocusRipple: true },
      rightArrowButton: { disableFocusRipple: true },
      switchViewButton: { disableFocusRipple: true },
    },
  };

  const popperProps = {
    className: style.dateFieldDialog,
    placement: 'bottom-start',
  };

  const dialogDescriptionId = `${fieldName}DialogDescription`;

  const commonTextProps = {
    readOnly: true,
    isRequiredErrorMessage: false,
    hideValidationAdornment: true,
    ...textProps,
    tabIndex: -1,
  };

  const getOpenDialogAriaText = (date, utils) => {
    const requiredText = isRequired ? 'Required' : 'Optional';

    if (!date) {
      return `Choose ${fieldName} (${requiredText})`;
    }

    return `Choose ${fieldName} (${requiredText}), selected date is ${utils.format(
      utils.date(date),
      'fullDate',
    )}`;
  };

  const renderDay = (day, _, dayProps) => {
    const labelId = day.toISOString();
    const readableDay = FormatReadable(day);

    return (
      <React.Fragment key={labelId}>
        <label id={labelId} className={style.srOnly}>
          {readableDay}
        </label>
        <PickersDay {...dayProps} aria-labelledby={labelId} />
      </React.Fragment>
    );
  };

  const renderCloseButton = (fieldName, onClickHandler, describedBy) => {
    return (
      <IconButton
        aria-label={`Clear ${fieldName}`}
        aria-describedby={describedBy}
        onClick={() => {
          onClickHandler();
          setMessage(`${fieldName} cleared`);
          setTimeout(() => setMessage(null), 1000);
        }}
        disabled={disableCloseButton}
      >
        <ClearIcon />
      </IconButton>
    );
  };

  const onClickInput = e => {
    if (!isDateOpen && e.target === inputRef.current && !e.target.disabled) {
      setDateOpen(true);
    }
  };

  return (
    <Grid item xs className={`${style.dateField} ${trim ? style.trim : ''}`}>
      <p id={dialogDescriptionId} className={style.srOnly}>
        {getOpenDialogAriaText()}
      </p>
      <DatePicker
        disabled={disabled}
        defaultCalendarMonth={defaultCalendarMonth}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onChange={setValue}
        open={isDateOpen}
        onOpen={() => setDateOpen(true)}
        onClose={() => setDateOpen(false)}
        getOpenDialogAriaText={getOpenDialogAriaText}
        {...commonProps}
        PopperProps={{
          ...popperProps,
          'aria-describedby': dialogDescriptionId,
          anchorEl: inputRef.current?.parentElement,
        }}
        OpenPickerButtonProps={{
          'aria-describedby': helperTextId,
          autoFocus: focusStartDate && fieldName === 'startDate' ? true : false,
        }}
        renderDay={renderDay}
        renderInput={({ InputProps, inputProps, ...params }) => {
          const newInputProps = {
            startAdornment: InputProps.endAdornment,
            endAdornment: renderCloseButton(
              fieldLabel,
              () => setValue(null),
              helperTextId,
            ),
          };

          const newinputProps = {
            ...inputProps,
            'aria-hidden': true,
          };
          return (
            <BaselineTextInput
              id={fieldName}
              name={fieldName}
              externalLabel={externalLabel}
              errorlabel={errorLabel}
              onClick={onClickInput}
              onBlur={e => e.preventDefault()}
              onKeyPress={e => {
                if (
                  e.key === 'Enter' ||
                  e.key === ' ' ||
                  e.code === 'Space' ||
                  e.keyCode === 32
                ) {
                  onClickInput(e);
                }
              }}
              {...commonTextProps}
              {...params}
              inputRef={inputRef}
              InputProps={removeAdornments ? {} : newInputProps}
              inputProps={newinputProps}
            />
          );
        }}
      />
    </Grid>
  );
};

export default DatepickerField;
