import React from 'react';
import { Grid } from '@mui/material';

import { BaselineTextInput } from 'components/textInputV2/textInput';
import AccountLifespanFields from 'components/users/accountLifespanFields/accountLifespanFields';

import style from './userDetails.module.scss';
import { CardDetails } from 'pages/users/createUser/components/cardDetails/cardDetails';

/**
 * The user fields provide a common arrangement of the requested user details
 * with some logic baked in. UserFields is just the raw arrangement of the
 * fields without styling or containering.
 */
export const UserFields = props => {
  const {
    startDate,
    endDate,
    minimumEndDate,
    maximumStartDate,
    setStartDate,
    setEndDate,
    createUser,
    status,
    initialValues,
  } = props;

  return (
    <div>
      {createUser ? (
        <>
          <Grid container className={style.fieldRow}>
            <Grid item xs={8}>
              <BaselineTextInput
                id="firstName"
                externalLabel="First Name"
                name="firstName"
                errorlabel="First Name"
                requiredErrorMessage="First name is required"
              />
            </Grid>
          </Grid>
          <Grid container className={style.fieldRow}>
            <Grid item xs={8}>
              <BaselineTextInput
                id="lastName"
                externalLabel="Last Name"
                name="lastName"
                errorlabel="Last Name"
                requiredErrorMessage="Last name is required"
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid className={style.fieldRow}>
          <Grid item xs={6}>
            <BaselineTextInput
              className={style.textInput}
              id="firstName"
              externalLabel="First Name"
              name="firstName"
              requiredErrorMessage="First name is required"
            />
          </Grid>
          <Grid item xs={6} style={{ paddingTop: '12px' }}>
            <BaselineTextInput
              className={style.textInput}
              id="lastName"
              externalLabel="Last Name"
              name="lastName"
              requiredErrorMessage="First name is required"
            />
          </Grid>
        </Grid>
      )}
      {createUser ? (
        <Grid container className={style.fieldRow}>
          <Grid item xs={8}>
            <BaselineTextInput
              id="email"
              externalLabel="Email Address"
              name="email"
              requiredErrorMessage="An email address is required"
              errorlabel="Email"
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid container className={style.lifespanFields}>
        <Grid item xs>
          <AccountLifespanFields
            className={style.fieldRow}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            minimumEndDate={minimumEndDate}
            maximumStartDate={maximumStartDate}
            status={status}
            initialValues={initialValues}
          />
        </Grid>
      </Grid>
    </div>
  );
};

/**
 * UserDetails provides the common arrangement of the requested user details
 *  wrapped in a card for ease of use and styling.
 */
export const UserDetails = props => {
  return (
    <>
      {props.creating ? (
        <CardDetails
          className={style.createUserCard}
          primaryHeader="User Details"
          secondaryHeader="All fields are required unless marked otherwise. The user will receive an invitation on their designated start date."
        />
      ) : null}
      <UserFields {...props} />
    </>
  );
};

export default UserDetails;
