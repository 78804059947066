import React from 'react';
import style from './sectionHeader.module.scss';

/**
 * @param title The title of the section
 * @param secondary section description
 */
export const SectionHeader = props => {
  const { title, secondary, third } = props;

  return (
    <div className={style.container}>
      {title ? <h2 className={style.title}>{title}</h2> : null}
      <div>
        {secondary ? (
          <span className={style.secondary}>{secondary}</span>
        ) : null}
      </div>
      <div>{third ? <span className={style.third}>{third}</span> : null}</div>
    </div>
  );
};

export default SectionHeader;
