import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  PROVISIONING_MANAGER_ROLE,
  TENANT_ADMINISTRATOR_ROLE,
} from 'utils/dictionary/overview';
import { LOGOUT_PATH } from 'utils/configuration/links';
import {
  PROVISIONING_ORIG_APP_ID,
  REACT_APP_FRONTDOOR_ISSUER,
} from 'utils/dictionary/env';
import { Auth0State, Auth0Jwt, Auth0IdTokenClaims } from 'components/auth';
import { CallbackConfig } from 'utils/configuration/auth0';

export const AuthContext = React.createContext();

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  //console.log(jsonPayload);
  return JSON.parse(jsonPayload);
}

const getSubscriptionIdFromToken = token => {
  let subscriptionId = parseJwt(token).subscriptionId;
  return subscriptionId;
};

const getOrgIdFromToken = token => {
  let organizationId = parseJwt(token).organizationId;
  return organizationId;
};

const getProperRole = roles => {
  // if a null roles array is supplied will fall through to return null.
  // set provisioning manager role at highest level.
  if (roles.includes(PROVISIONING_MANAGER_ROLE)) {
    return PROVISIONING_MANAGER_ROLE;
  }
  // user does not have a provisioning manager role as part of roles.
  // but has tenant admin.
  if (roles.includes(TENANT_ADMINISTRATOR_ROLE)) {
    return TENANT_ADMINISTRATOR_ROLE;
  }
  return null;
};

// TODO: limit refine role distribution so this method can contain less logic.
const getOrgRoleFromToken = token => {
  if (token) {
    // declare role array
    let rolesArray = [];
    try {
      // split roles into array
      let roles = parseJwt(token).hwRoles;
      let org = getOrgIdFromToken(token);
      // iterate through array and parse out role names
      for (let i = 0; i < roles.length; i++) {
        let parts = roles[i].split('::');
        if (parts.length !== 4) continue;
        if (parts[0] === org && parts[2] === PROVISIONING_ORIG_APP_ID) {
          rolesArray.push(parts[3]);
        }
      }
      // check if provisioning manager role exists otherwise return tenant admin or null
      return getProperRole(rolesArray);
    } catch (error) {
      // TODO  Render error to user
      //console.log('role err: ' + error);
    }
  }
  return null;
};

const getAdminOrgsFromToken = token => {
  let adminOrgs = [];
  if (token) {
    try {
      let roles = parseJwt(token).hwRoles;
      // iterate through roles array and parse out role names
      for (let i = 0; i < roles.length; i++) {
        let parts = roles[i].split('::');
        if (parts.length !== 4) continue;
        if (parts[3] === TENANT_ADMINISTRATOR_ROLE) {
          const adminOrgsSub = {
            organizationId: parts[0],
            subscriptionId: parts[1],
          };
          adminOrgs.push(adminOrgsSub);
        }
      }
    } catch (error) {
      // Error parsing the jwt, bail.
      return [];
    }
  }
  return adminOrgs;
};

const getOrganizationAlias = token => {
  let organizationAias = '';

  if (token) {
    try {
      // split roles into array
      organizationAias = parseJwt(token).tempOrganizationAlias;
    } catch (error) {
      // Error parsing the jwt, bail.
      return [];
    }
  }

  return organizationAias;
};

/**
 * Retrieve only the management roles from the JWT
 */
const getManagementRolesFromToken = token => {
  let managementRoles = [];

  if (token) {
    try {
      // split roles into array
      let roles = parseJwt(token).hwRoles;
      let org = getOrgIdFromToken(token);
      // iterate through array and parse out role names
      for (let i = 0; i < roles.length; i++) {
        let parts = roles[i].split('::');
        if (parts.length !== 4) continue;
        // TODO: Removed Application ID lookup here. Add it back in.
        if (parts[0] === org) {
          managementRoles.push(parts[3]);
        }
      }
    } catch (error) {
      // Error parsing the jwt, bail.
      return [];
    }
  }

  return managementRoles;
};

const customAuthHandler = async (state, locationSearch) => {
  await state.loginWithRedirect({
    authorizationParams: {
      redirect_uri: CallbackConfig.frontDoorUri,
    },
  });
};

export const AuthProvider = ({ children }) => {
  const [state, setState] = React.useReducer((oldState, newState) => newState, {
    loading: true,
    token: undefined,
    permissions: null,
    user: null,
    managementRoles: [],
    auth0token: null,
    getOrganizationAlias: null,
    customAuthHandler,
  });

  const updateAuth = async (authState, jwt, idTokenClaims) => {
    const token = idTokenClaims.__raw;
    const accessToken = jwt;

    if (token && token !== state.token && accessToken !== state.accessToken) {
      try {
        setState({
          token,
          organizationId: getOrgIdFromToken(accessToken),
          subscriptionId: getSubscriptionIdFromToken(accessToken),
          accessToken: accessToken, // Added, previously when destructuring returned null
          permissions: parseJwt(accessToken),
          loading: false,
          user: authState.user,
          managementRoles: getManagementRolesFromToken(accessToken),
          orgRole: getOrgRoleFromToken(accessToken),
          isProvisioningManager:
            getOrgRoleFromToken(accessToken) === PROVISIONING_MANAGER_ROLE,
          adminOrgs: getAdminOrgsFromToken(accessToken),
          organizationAlias: getOrganizationAlias(accessToken),
          customAuthHandler,
        });
      } catch (error) {
        // TODO Render error to user
        // console.log('Issue parsing token', error);
      }
    }
  };

  return (
    <AuthContext.Provider value={{ ...state, updateAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthHandler = () => {
  //context is global state. It is a singleton, the state is the same wherever used
  const { updateAuth, customAuthHandler, organizationAlias } =
    React.useContext(AuthContext);
  const location = useLocation();
  const auth0State = Auth0State();
  const jwt = Auth0Jwt();
  const idTokenClaims = Auth0IdTokenClaims();

  // if there is no variable list, it executes once and that's it
  React.useEffect(() => {
    updateAuth(auth0State, jwt, idTokenClaims);
  });

  // whenever any variable from line 266 to 271 change, fire this chunk of code
  // in this chunk of code, anything I'm changing needs to be included in the list
  // this a method waiting to execute, with these variables being triggers
  React.useEffect(() => {
    // everything in this use Effect, is only concerned with the login path, or the logout path
    // doesn't care about our token state
    // if (location.pathname === LOGIN_PATH) {
    //   customAuthHandler(auth0State, location.search);
    // }

    const logout = async () => {
      window.location.replace(
        `${REACT_APP_FRONTDOOR_ISSUER}/${organizationAlias}/signout`,
      );
    };

    // Direct comparison with === while maintaining org alias doesn't work here
    if (location.pathname.includes(LOGOUT_PATH)) {
      logout();
      sessionStorage.removeItem('features');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth0State,
    //anytime location.pathname changes, we run useEffect
    location.pathname,
    customAuthHandler,
    // location.search,
  ]);

  return null;
};

export const AuthConsumer = AuthContext.Consumer;
