import { Card, CardContent, Divider, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import style from './tenantDetailPanel.module.scss';
import DebugValueWithClipboard from 'components/debugValueWithClipboard/debugValueWithClipboard';
import EditableTenantDetailPanel from './editableTenantDetailPanel';
import Notification from 'components/notificationV2/notification';
import { EditIcon } from 'components/icons/icons';

const RenameAccountButtonLink = props => {
  const { setEditable } = props;
  return (
    <IconButton
      className={style.editBtn}
      color="primary"
      aria-label="rename tenant"
      onClick={() => {
        setEditable(true);
      }}
    >
      <EditIcon />
    </IconButton>
  );
};

const TenantDetailPanel = props => {
  const { tenantData = {}, organizationId } = props ?? {};
  const [editableHeader, setEditableHeader] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState(tenantData.subscriptionName);

  useEffect(() => {
    setName(tenantData.subscriptionName);
  }, [tenantData.subscriptionName]);

  return (
    <>
      {error ? <Notification error message={error} /> : null}
      {success ? <Notification message={success} /> : null}
      <Card className={style.card}>
        <CardContent className={style.cardContent}>
          <div className={style.cardContentItem}>
            <div className={style.title}>
              {editableHeader ? (
                <EditableTenantDetailPanel
                  title={name}
                  tenantId={tenantData.subscriptionId}
                  organizationId={organizationId}
                  setEditable={setEditableHeader}
                  setTenantName={setName}
                  setError={setError}
                  setSuccess={setSuccess}
                />
              ) : (
                <>
                  <span className={style.header}>Tenant Name: </span>
                  <span className={style.name}>{name}</span>

                  <RenameAccountButtonLink
                    id={tenantData.subscriptionId}
                    name={'Rename Tenant'}
                    setEditable={setEditableHeader}
                  />
                </>
              )}
            </div>
          </div>
          <div className={`${style.cardContentItem} ${style.itemGap}`}>
            {tenantData.active ? (
              <span className={`${style.status} ${style.active}`}>ACTIVE</span>
            ) : (
              <span className={`${style.status} ${style.inactive}`}>
                INACTIVE
              </span>
            )}
            <span className={`${style.data} ${style.slash}`}>/</span>
            <span className={style.data}>
              {tenantData.subscriptionType} Environment
            </span>
          </div>
          <Divider className={style.divider} />
          <div className={`${style.cardContentItem}`}>
            <span className={style.rowTitle}>Content Identifier</span>
            <span className={style.data}>{tenantData.friendlyId}</span>
          </div>
          <div className={`${style.cardContentItem} ${style.itemGap}`}>
            <span className={style.rowTitle}>Alias</span>
            <span className={style.data}>{tenantData.alias}</span>
          </div>
          <DebugValueWithClipboard
            className={`${style.cardContentItem} ${style.itemGap}`}
            titleClassName={style.rowTitle}
            valueClassName={style.data}
            title="Tenant ID"
            value={tenantData.subscriptionId}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default TenantDetailPanel;
