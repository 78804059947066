import React from 'react';
import style from './footer.module.scss';
import { Link as MaterialLink } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

export const Footer = () => {
  const bulletSymbol = '\u2022';
  const switchOverDate = new Date('2023-01-01T00:00:00-06:00');
  const currentYear = new Date();
  let displayYear =
    currentYear >= switchOverDate
      ? `2022-${currentYear.getFullYear()}`
      : '2022';

  return (
    <>
      <div className={style.footer}>
        <span className={style.copyright}>
          © {displayYear} Healthwise, Incorporated. Healthwise, Healthwise for
          every health decision, and the Healthwise logo are trademarks of
          Healthwise, Incorporated. |{' '}
          <Tooltip
            title="You are leaving this website for information purposes only."
            aria-describedby="You are leaving this website for information purposes only."
            arrow
          >
            <MaterialLink
              aria-label="Privacy Notice, you are leaving this website for information purposes only."
              href={
                'https://www.healthwise.org/specialpages/legal/privacy.aspx'
              }
              target="_blank"
              rel="noopener"
              className={style.terms}
            >
              Privacy Notice
            </MaterialLink>
          </Tooltip>
          <p className={style.bullet} aria-hidden="true">{`${bulletSymbol}`}</p>
          <Tooltip
            title="You are leaving this website for information purposes only."
            arrow
          >
            <MaterialLink
              aria-label="Terms of Use, you are leaving this website for information purposes only."
              href={'https://www.healthwise.org/specialpages/legal/terms.aspx'}
              target="_blank"
              rel="noopener"
              className={style.terms}
            >
              Terms of Use
            </MaterialLink>
          </Tooltip>
        </span>
      </div>
    </>
  );
};

export default Footer;
