import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts/authContext';
import { getTenantApplications } from 'utils/api/tenant';

// TODO: Evaluate if it's safe to remove this "empty" subscription object so we're not accidentally thinking
// we've loaded subscriptions when it's really just this one
const emptyReferenceSubscriptions = [
  {
    id: '',
    name: '',
    value: '',
    applications: [
      {
        id: '',
        name: '',
        value: '',
        roles: [
          {
            id: '',
            name: '',
            value: '',
            description: '',
          },
        ],
      },
    ],
  },
];

const parseTenantAppRoles = function (subscriptionId, applications) {
  const refrenceApplications = applications
    .map(application => {
      const roles = application.roles.map(role => {
        return {
          id: `${subscriptionId}::${application.id}::${role.name}`,
          name: role.name,
          value: role.name,
          description: role.description,
        };
      });

      if (roles.length > 0) {
        const referenceApplication = applications.find(rawApp => {
          return rawApp.id === application.id;
        });

        return {
          id: application.id,
          name: referenceApplication.name,
          value: referenceApplication.name,
          sourceId: referenceApplication.sourceId,
          fullyQualifiedName: referenceApplication.fullyQualifiedName,
          roles: roles,
        };
      }
      return undefined;
    })
    .filter(Boolean);
  if (refrenceApplications.length > 0) {
    return {
      applications: refrenceApplications,
      subscriptionId: subscriptionId,
    };
  }
};

//TODO: this needs to change to get apps by tenant not org
export const ReferenceSubscriptionsProvider = ({ children }) => {
  const { accessToken, subscriptionId, loading } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [didMount, setDidMount] = useState(false);

  // The consolidated reference subscription state
  const [referenceSubscriptions, setReferenceSubscriptions] = useState(
    emptyReferenceSubscriptions,
  );

  const store = {
    referenceSubscriptions,
    errorMessage,
  };

  useEffect(() => {
    setDidMount(true);
    const tenantApplications = async () => {
      const { response, data, error } = await getTenantApplications(
        subscriptionId,
        accessToken,
      );

      if (response.ok) {
        const applicationList = Object.values(data?.applications);

        setReferenceSubscriptions(
          parseTenantAppRoles(subscriptionId, applicationList),
        );
      }

      setErrorMessage(error);
    };

    if (!loading) {
      tenantApplications();
    }
    return () => setDidMount(false);
  }, [accessToken, subscriptionId, loading]);

  if (!didMount) {
    return null;
  }

  return (
    <ReferenceSubscriptionsContext.Provider value={store}>
      {children}
    </ReferenceSubscriptionsContext.Provider>
  );
};

export const ReferenceSubscriptionsContext = createContext();

/**
 * Helper function so you don't have to import useContext.
 *
 * @return {object} The Reference Subscriptions object and it's state.
 */
export const useReferenceSubscriptions = () => {
  const context = useContext(ReferenceSubscriptionsContext);
  if (context === undefined) {
    throw new Error(
      'Reference Subscription Context must be used within a Provider',
    );
  }
  return context;
};
