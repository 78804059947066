import React from 'react';
import ApplicationSelector from 'components/applicationSelector/applicationSelector';
import style from './roleAssignment.module.scss';

const RoleAssignment = props => {

  return (
    <>
      <ApplicationSelector
        compact={false}
        className={style.subscriptionSelector}
        {...props}
      />
    </>
  );
};

export default RoleAssignment;
