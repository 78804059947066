export const getDeleteUserProperties = (userInfo, handleConfirm) => {
  return {
    title: 'Delete this user?',
    text: "Are you sure you want to delete this user? This action cannot be undone. Type 'confirm' below.",
    hideConfirmInstructions: true,
    confirmText: 'Delete User',
    warningText: (
      <>
        <strong>
          {userInfo.firstName} {userInfo.lastName}
        </strong>{' '}
        at {userInfo.email} will lose the ability to access their account and
        all permissions will be revoked.
      </>
    ),
    handleConfirm: handleConfirm,
  };
};
