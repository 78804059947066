import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import OrganizationManagementList from 'pages/organizations/components/organizationManagementList/organizationManagementList';
import CreateOrganization from 'containers/organizationManagement/createOrganization';
import { useGetOrganizations } from 'utils/hooks/organization/useGetOrganizations';
import { useGetOrganizationCategories } from 'utils/hooks/useToCallApiEndpoints';
import { useLocation } from 'react-router-dom';
import Notification from 'components/notificationV2/notification';

const OrganizationList = () => {
  //TODO: Implement pagination and displaying error to user

  // `data` will always be a valid array, athough it _could_ be empty...
  const response = useGetOrganizations();
  const organizations = response.data;
  const finished = response.finished;
  const { data: categories } = useGetOrganizationCategories();
  const [successMessage, setSuccessMessage] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.fromDelete) {
      setSuccessMessage('Organizaton has been successfully deleted.');
    }
  }, [location.state]);

  return (
    <>
      <Box>
        <OrganizationManagementList
          bannerButton={
            <CreateOrganization
              modalButtonText="Create New Organization"
              organizationCategories={categories}
            />
          }
          listOfOrganizations={organizations}
          organizationsRetrieved={finished}
          organizationCategories={categories}
        />
      </Box>
      {successMessage ? (
        <Notification message={successMessage} duration={5000} />
      ) : null}
    </>
  );
};
export default OrganizationList;
