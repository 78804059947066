import React from 'react';
import { FeatureToggle } from 'react-feature-toggles';

import { AuthContext } from 'contexts/authContext';
import { HEALTHWISE_ORGANIZATION_ID } from 'utils/dictionary/overview';

const FeatureToggleHealthwiseOnly = props => {
  const { organizationId } =  React.useContext(AuthContext);
  
  return (
    organizationId === HEALTHWISE_ORGANIZATION_ID
    ? <FeatureToggle {...props} />
    : null
  )
}

export default FeatureToggleHealthwiseOnly;