import React, {
  useRef,
  useContext,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import {
  editSubscriptionSchema,
  editSubscriptionFormInitialValues,
} from 'components/organizationManagement/createOrganizationSubscriptionModal/formSchemaAndInitialValues';
import EditOrganizationSubscriptionModal from 'components/organizationManagement/editOrganizationSubscriptionModal/editOrganizationSubscriptionModal';
import { Formik } from 'formik';
import { updateSubscription, getSubscription } from 'utils/api/subscriptions';
import { AuthContext } from 'contexts/authContext';
import { Redirect } from 'react-router-dom';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { NotificationContext } from 'contexts/notificationContext';

const EditOrganizationSubscription = forwardRef((props, ref) => {
  const closeModal = useRef({ close: false }); //Need to think about better way to handle this, this is the best I can think of at this time
  const { accessToken } = useContext(AuthContext);
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { showNotification } = React.useContext(NotificationContext);
  const [status, setStatus] = useState(null);

  const {
    subscription,
    identityProviders,
    environments,
    organizationId,
    productionSubscriptionsLimitInfo,
    closeMenu,
  } = props ?? {};

  const { subscriptionId, subscriptionName = 'unknown' } = subscription ?? {};

  const editChildRef = useRef();

  useImperativeHandle(ref, () => ({
    handleOpen() {
      editChildRef.current.handleOpen();
    },
  }));

  const { isError, message = null, date } = status ?? {};

  useEffect(() => {
    if (message) {
      showNotification(message, isError, 5000, date);
      status.message = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, message, date]);

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          search: window.location.search,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const editOrganizationSubscription = async (
    values,
    { resetForm, setFieldError },
  ) => {
    const { etag: subscriptionEtag, data: subscription } =
      await getSubscription(organizationId, subscriptionId, accessToken);

    const updateSubscriptionDto = {
      subscriptionName: values.subscriptionName,
      referenceId: subscription?.data?.referenceId,
    };

    const { response } = await updateSubscription(
      organizationId,
      subscriptionId,
      updateSubscriptionDto,
      subscriptionEtag,
      accessToken,
    );

    switch (response?.status) {
      case 200:
        setStatus({
          isError: false,
          message: 'Tenant renamed successfully.',
          date: Date.now(),
        });
        closeModal.current = { close: true }; //Conditional to close modal in the component
        resetForm(editSubscriptionFormInitialValues(subscriptionName));
        setRedirect({ isRedirect: true, data: { organizationId } });
        break;
      case 400:
        setFieldError(
          'subscriptionName',
          'A subscription with that name already exist.',
        );
        break;
      case 412:
        setStatus({
          isError: true,
          message:
            'Another user modified this record. Please refresh page and try again.',
          date: Date.now(),
        });
        break;
      default:
        setStatus({
          isError: true,
          message:
            'Error renaming tenant. Please try again or contact your Healthwise developers.',
          date: Date.now(),
        });
        break;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={editSubscriptionFormInitialValues(subscriptionName)}
      validationSchema={editSubscriptionSchema}
      onSubmit={editOrganizationSubscription}
      children={props => (
        <EditOrganizationSubscriptionModal
          {...props}
          closeModal={closeModal}
          identityProviderOptions={identityProviders}
          environmentOptions={environments}
          productionSubscriptionsLimitInfo={productionSubscriptionsLimitInfo}
          closeMenu={closeMenu}
          ref={editChildRef}
        />
      )}
    />
  );
});

export default EditOrganizationSubscription;
