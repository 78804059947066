import React, {
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Formik } from 'formik';
import MenuItemModalAndFeedbackModal from 'components/organizationManagement/organizationManagementEditOrganization/menuItemModalAndFeedbackModal/menuItemModalAndFeedbackModal';

import { removeTenantAdministrator } from 'utils/api/tenantAdmin';

import { AuthContext } from 'contexts/authContext';

const RemoveTenantAdministrator = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { accessToken } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    rowData = {},
    closeMenu,
    tenantId = {},
    removeAdministratorFromState,
  } = props ?? {};

  const { firstName, lastName, userId } = rowData;

  const content = (
    <>
      Removing{' '}
      <b>
        {firstName} {lastName}
      </b>{' '}
      as an Tenant Adminstrator means{' \n'}
      they will no longer be able to manage users at the organization. {' \n'}
      Any application level permissions will remain intact.
    </>
  );

  useImperativeHandle(ref, () => ({
    handleOpen() {
      handleOpen();
    },
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = evt => {
    setOpen(false);
    closeMenu();
    setIsSubmitting(false);
  };

  const removeAdministrator = async (values, { setStatus }) => {
    setIsSubmitting(true);
    const { response } = await removeTenantAdministrator(
      tenantId,
      userId,
      accessToken,
    );

    if (response?.status === 204) {
      removeAdministratorFromState(userId);
      setStatus({
        isError: false,
        date: Date.now(),
        message:
          'Successfully removed Administrator role from ' +
          firstName +
          ' ' +
          lastName,
      });
    } else {
      setStatus({
        isError: true,
        date: Date.now(),
        message:
          'Error removing Administrator role. Please try again or contact your Healthwise developer.',
      });
    }

    handleClose();
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={removeAdministrator}
      children={props => (
        <MenuItemModalAndFeedbackModal
          {...props}
          open={open}
          handleClose={handleClose}
          confirmationButtonText="Remove Administrator Role"
          cancelButtonText="Cancel"
          labelledBy="removeAdministratorModalTitle"
          describedBy="removeAdministratorModalDescription"
          modalTitle="Remove Administrator Role from this user?"
          isDelete={true}
          isSubmitting={isSubmitting}
          content={content}
        />
      )}
    />
  );
});

export default RemoveTenantAdministrator;
