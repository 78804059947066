import React, { useContext, useState, useEffect } from 'react';

import { AuthContext } from 'contexts/authContext';

import { getOrganization } from 'utils/api/organizations';

export const OrganizationContext = React.createContext();

export const OrganizationProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  const [organization, setOrganization] = useState({});

  useEffect(() => {
    async function fetchOrganization(organizationId, accessToken) {
      let { response, data, error } = await getOrganization(
        organizationId,
        accessToken,
      );
      if (response.ok) {
        setOrganization(data.data);
      } else {
        throw new Error(`${response.status} - ${error.message}`);
      }
    }

    if (authContext && authContext.accessToken && authContext.organizationId) {
      fetchOrganization(authContext.organizationId, authContext.accessToken);
    }
  }, [authContext, authContext.accessToken]);

  return (
    <OrganizationContext.Provider value={{ organization }}>
      {children}
    </OrganizationContext.Provider>
  );
};

/**
 * Return the profile as loaded by the context. Helper function so you don't have to import useContext.
 */
export const useOrganizationContext = () => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(
      'Organization Profile Context must be used within a Provider',
    );
  }
  return context;
};
