import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import {
  DialogSubSectionText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/dialog';
import { TextButton, PrimaryButton } from 'components/buttons/button';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import Tooltip from '@mui/material/Tooltip';
import style from './editOrganizationSubscriptionModal.module.scss';

const EditOrganizationSubscriptionModal = forwardRef((props, ref) => {
  const { closeModal, handleReset, closeMenu, touched, values } = props;

  const [open, setOpen] = useState(false);

  const inputRef = useRef(null);
  useAddFocus(inputRef, open);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      handleOpen();
    },
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (handleReset) handleReset();
    setOpen(false);
    closeMenu();
  };

  //Close modal when form is submitted successfully
  //Couldn't think of a better way to implement this
  if (closeModal.current.close) {
    closeModal.current = { close: false };
    setOpen(false);
  }

  return (
    <>
      <Dialog
        role="dialog"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle id="dialog-title">Rename Tenant</DialogTitle>
          <DialogContent>
            <DialogSubSectionText id="dialog-description">
              The tenant name is a unique client-friendly name for managing and
              isolating sets {'\n'}
              of resources in a given environment.
            </DialogSubSectionText>
            <div className={style.name}>
              <BaselineTextInput
                id="subscriptionName"
                name="subscriptionName"
                errorlabel="Tenant Name"
                margin="none"
                size="small"
                inputRef={inputRef}
                requiredErrorMessage="An organization name is required"
                className={style.input}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <TextButton
              buttonText={'Cancel'}
              handleClick={() => {
                handleClose();
              }}
            />

            {touched.subscriptionName &&
            values?.subscriptionName?.length === 0 ? (
              <Tooltip
                title="Please resolve errors before continuing"
                aria-describedby="Please resolve errors before continuing"
                placement="top"
                arrow
              >
                <span>
                  <PrimaryButton
                    type="submit"
                    variant="contained"
                    buttonText="Rename Tenant"
                  ></PrimaryButton>
                </span>
              </Tooltip>
            ) : (
              <>
                <PrimaryButton
                  type="submit"
                  variant="contained"
                  buttonText="Rename Tenant"
                ></PrimaryButton>
              </>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
});

EditOrganizationSubscriptionModal.propTypes = {
  identityProviderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      identityProviderId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  environmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      subscriptionTypeId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  closeModal: PropTypes.shape({
    current: PropTypes.shape({
      close: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EditOrganizationSubscriptionModal;
