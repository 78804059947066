import React, { useContext, useState, useEffect } from 'react';

import { AuthContext } from 'contexts/authContext';

import { getSubscription } from 'utils/api/subscriptions';

export const TenantContext = React.createContext();

export const TenantProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  const [tenant, setTenant] = useState({});

  useEffect(() => {
    async function fetchTenant(organizationId, subscriptionId, accessToken) {
      let { response, data, error } = await getSubscription(
        organizationId,
        subscriptionId,
        accessToken,
      );
      if (response.ok) {
        setTenant(data.data);
      } else {
        throw new Error(`${response.status} - ${error.message}`);
      }
    }

    if (authContext && authContext.accessToken && authContext.subscriptionId) {
      fetchTenant(
        authContext.organizationId,
        authContext.subscriptionId,
        authContext.accessToken,
      );
    }
  }, [authContext, authContext.accessToken]);

  return (
    <TenantContext.Provider value={{ tenant: tenant }}>
      {children}
    </TenantContext.Provider>
  );
};

/**
 * Return the profile as loaded by the context. Helper function so you don't have to import useContext.
 */
export const useTenantContext = () => {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error('Tenant Profile Context must be used within a Provider');
  }
  return context;
};
