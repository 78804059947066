import { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'contexts/authContext';
import {
  ERROR_MESSAGE_UNAUTHORIZED,
  ERROR_MESSAGE_INTERNAL_SERVER_ERROR,
  ERROR_MESSAGE_NETWORK_ERROR,
  ERROR_MESSAGE_NOT_FOUND,
} from 'utils/dictionary';
import { getOrganizations } from 'utils/api/organizations';

// TODO: Pull out into common util
const setDataOrErrorMessage = (
  response,
  data,
  error,
  setData,
  setErrorMessage,
  setFinished,
) => {
  switch (response?.status) {
    case 200:
      setData(data);
      break;
    case 404:
      setErrorMessage(ERROR_MESSAGE_NOT_FOUND);
      break;
    case 401:
      setErrorMessage(ERROR_MESSAGE_UNAUTHORIZED);
      break;
    case 500:
      setErrorMessage(ERROR_MESSAGE_INTERNAL_SERVER_ERROR);
      break;
    default:
      error
        ? setErrorMessage(ERROR_MESSAGE_NETWORK_ERROR)
        : setErrorMessage(null);
      break;
  }
  setFinished(true);
};

/**
 * Retrieve the organizations in a paginated manner.
 */
export const useGetOrganizations = (top = 100, skip = 0) => {
  const { accessToken, loading } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const response = async () => {
      const { response, data, pagination, error } = await getOrganizations(
        skip,
        top,
        accessToken,
      );

      setPagination(pagination);
      setDataOrErrorMessage(
        response,
        data,
        error,
        setData,
        setErrorMessage,
        setFinished,
      );
    };

    if (!loading) {
      response();
    }
  }, [top, skip, accessToken, loading]);

  return {
    data,
    top,
    skip,
    pagination,
    errorMessage,
    finished,
  };
};

export default useGetOrganizations;
