import React, { useContext, useState, useEffect } from 'react';
import { useGetSubscription } from 'utils/hooks/useToCallApiEndpoints';
import { AuthContext } from 'contexts/authContext';
import { useRetrieveSubscriptionEntitlementPacket } from 'utils/hooks/useToCallApiEndpoints';
import SubscriptionDetailPanelV2 from 'components/organizationManagement/organizationManagementEditOrganization/subscriptionDetailPanel/subscriptionDetailPanelV2';
import { getSubscriptionSettings } from 'utils/api/settings';
import { SettingsIcon } from 'components/icons/icons';
import { PageLink } from 'components/pageLink/pageLink';
import { EDIT_SUBSCRIPTION_PATH } from 'utils/configuration/links';

const OrganizationSubscriptionDetailPanel = props => {
  const { readOnly = false, rowData, organization, current = false } = props;
  const { accessToken } = useContext(AuthContext);

  const organizationId = current
    ? 'current'
    : organization?.organizationId ?? '';
  const { subscriptionId } = rowData ?? {};

  const { data } = useRetrieveSubscriptionEntitlementPacket(
    organizationId,
    subscriptionId,
  );

  const { etag: subscriptionEtag, data: subscriptionData } = useGetSubscription(
    organization?.organizationId,
    subscriptionId,
  );

  const [settingList, setSettingList] = useState([]);

  async function getData() {
    const settingsResult = await getSubscriptionSettings(
      subscriptionId,
      accessToken,
    );
    setSettingList(settingsResult?.data);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionId, accessToken]);

  const { subscriptionName, subscriptionTypeId } = rowData;
  const organizationName = organization.organizationName;

  return (
    <>
      <SubscriptionDetailPanelV2
        readOnly={readOnly}
        entitlementPacketData={data ?? {}}
        rowData={rowData}
        organizationId={organizationId}
        settingList={settingList}
        {...props}
        subscriptionEtag={subscriptionEtag}
        subscriptionData={subscriptionData}
        renderSubscriptionEditConfigureProducts={props => {
          return (
            <>
              <PageLink
                addMargin={false}
                icon={<SettingsIcon />}
                text="Modify Tenant"
                to={{
                  pathname: EDIT_SUBSCRIPTION_PATH,
                  state: {
                    rowData: {
                      subscriptionId,
                      subscriptionName,
                      subscriptionTypeId,
                      organizationName,
                    },
                    organizationId,
                  },
                }}
                sx={{
                  color: '#0d8484 !important',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  border: '1px solid #0d8484',
                  ':hover': {
                    backgroundColor: '#fff !important',
                  },
                }}
              />
            </>
          );
        }}
      />
    </>
  );
};

export default OrganizationSubscriptionDetailPanel;
