import React, { forwardRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import VerifyModal from 'components/verifyModal/verifyModal';
import Notification from 'components/notificationV2/notification';
import { DestructiveSecondaryButton } from 'components/buttons/button';
import { deleteOrganization } from 'utils/api/organizations';
import { FEATURE_TOGGLE_DELETE_ORG } from 'utils/dictionary/featureToggles';

const DeleteOrganization = forwardRef((props, ref) => {
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const { accessToken } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [deleteModalProperties, setDeleteModalProperties] = useState({});

  const { organizationEtag, organization } = props;

  let history = useHistory();

  const handleClose = () => {
    setDeleteModelOpen(false);
  };

  const deleteFlag =
    sessionStorage.getItem('features')?.includes(FEATURE_TOGGLE_DELETE_ORG) ===
    true;

  const configureDeleteModal = () => {
    let modalProperties = {
      title: 'Delete this organization?',
      text: 'Are you sure you want to delete this organization?',
      confirmText: 'Delete Organization',
      warningText: (
        <>
          <strong>{organization.organizationName}</strong> and all associated
          subscriptions, administrators, and users will no longer be available.
          <br />
          <br />
          This is an immediate and descructive action that cannot be undone.
        </>
      ),
      hideConfirmInstructions: false,
      alarmStyle: true,
      handleConfirm: async () => {
        const organizationId = organization.organizationId;
        const { response } = await deleteOrganization(
          organizationId,
          organizationEtag,
          accessToken,
        );

        if (response?.status === 200) {
          handleClose();
          history.replace('organizations', { fromDelete: true });
        } else {
          setError(
            'Error deleting organization. Please try again or contact your Healthwise developers.',
          );
        }
      },
    };
    setDeleteModalProperties(modalProperties);
    setDeleteModelOpen(true);
  };

  return (
    <>
      {deleteFlag ? (
        <DestructiveSecondaryButton
          buttonText="Delete Organization"
          handleClick={() => configureDeleteModal()}
        />
      ) : null}
      {deleteModelOpen ? (
        <VerifyModal
          open={deleteModelOpen}
          handleClose={handleClose}
          handleCancel={handleClose}
          {...deleteModalProperties}
        />
      ) : null}
      {error ? <Notification error message={error} duration={5000} /> : null}
    </>
  );
});

export default DeleteOrganization;
