import React from 'react';
import { Form } from 'formik';
import { Button as MaterialButton } from '@mui/material';

import { NotificationContext } from 'contexts/notificationContext';
import RoleAssignment from './components/roleAssignment/roleAssignment';
import { UserDetails } from './components/userDetails/userDetails';
import { HorizontalRule } from 'components/horizontalRule/horizontalRule';

import {
  UserCardv2 as UserCard,
  UserCardContentv2 as UserCardContent,
} from 'components/card/card';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { BackToPrevPageLink } from 'components/users/backToPrevPageLink';
import { USER_MANAGEMENT_PATH } from 'utils/configuration/links';
import { DateFormatReadable } from 'utils/timeAndDate';

import style from './createUserForm.module.scss';
import UserPermissionCheckbox from './components/userPermissionCheckbox/userPermissionCheckbox';
import { useIsContentFeedbackEnabled } from 'utils/hooks/common/useIsContentFeedbackEnabled.js';
import CardDetails from './components/cardDetails/cardDetails';

const InviteUser = props => {
  const { isSubmitting, isValid, dirty, values, actions, handleSubmit } = props;

  return (
    <>
      {isSubmitting ? (
        <ProgressIndicator />
      ) : (
        <MaterialButton
          className={`${style.submitFormButton} ${
            !isValid || !dirty ? style.disabled : null
          }`}
          aria-disabled={!isValid || !dirty}
          type="submit"
          variant="contained"
          onClick={() => handleSubmit(values, actions)}
        >
          Send Invitation
        </MaterialButton>
      )}
    </>
  );
};

const ActionResponse = props => {
  const { showNotification } = React.useContext(NotificationContext);
  const { status, values } = props;

  React.useEffect(() => {
    if (status) {
      let message = '';
      let isError = false;
      if (status.success) {
        const date = Date.now();
        if (
          values.startDate &&
          !isNaN(values.startDate) &&
          values.startDate > date
        ) {
          message = `Invitation will be emailed to ${values.firstName} ${
            values.lastName
          } on ${DateFormatReadable(
            Date.parse(values.startDate),
          )}. This user will have 30 days to activate their account.`;
        } else {
          message = `Invitation emailed to ${values.firstName} ${values.lastName}. This user has 30 days to activate their account.`;
        }
      } else {
        const errorMessage = status?.error?.error?.message;
        message = errorMessage
          ? `Error creating user: ${errorMessage}. Please try again or contact your Healthwise administrator.`
          : 'Error creating user. Please try again or contact your Healthwise administrator.';
        isError = true;
      }

      showNotification(message, isError, 10000, status.lastSetDate);
    }
  }, [
    status,
    showNotification,
    values.firstName,
    values.lastName,
    values.startDate,
  ]);

  return null;
};

const CreateUserForm = props => {
  const {
    values: { selectedRoles, startDate, endDate },
    validateForm,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    getMinimumEndDate,
  } = props;
  const { contentFeedbackEnabled } = useIsContentFeedbackEnabled();

  const setDateField = fieldName => {
    return newValue => {
      if (!newValue || (newValue instanceof Date && !isNaN(newValue))) {
        setFieldValue(fieldName, newValue);
      } else {
        setFieldError(fieldName, 'Invalid Date');
      }
      setFieldTouched(fieldName);
    };
  };
  const setStartDate = setDateField('startDate');
  const setEndDate = setDateField('endDate');

  // Sometimes Formik's "isValid" prop is still incorrectly true after a role field
  // is no longer valid. This will force a reevaluation of the form fields
  // if the "selectedRoles" value changes.
  React.useEffect(() => {
    validateForm();
  }, [selectedRoles, validateForm, startDate, endDate]);

  return (
    <>
      <ActionResponse {...props} />
      <Form>
        <BackToPrevPageLink
          text="Back to Manage Users"
          path={USER_MANAGEMENT_PATH}
        />
        <UserCard id="createUserFormUserCard">
          <UserCardContent>
            <UserDetails
              {...props}
              creating={true}
              createUser={true}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              minimumEndDate={
                getMinimumEndDate ? getMinimumEndDate(startDate).date : null
              }
            />

            <HorizontalRule />
            <div className={style.cardHeader}>
              <CardDetails
                primaryHeader="Assign Roles"
                secondaryHeader="Roles control access to applications and should be granted with caution."
              />
            </div>
            <RoleAssignment {...props} />
            <HorizontalRule />
            <CardDetails
              primaryHeader="Assign Permissions"
              secondaryHeader="Permissions control access to organization-level features."
            />
            {contentFeedbackEnabled && (
              <UserPermissionCheckbox
                name="contentFeedback"
                text="This user can submit content feedback on behalf of my organization"
              />
            )}
            <UserPermissionCheckbox
              name="logService"
              text="This user can log a service request on behalf of my organization"
            />

            <div className={style.submitFormButtonContainer}>
              <InviteUser {...props} />
            </div>
          </UserCardContent>
        </UserCard>
      </Form>
    </>
  );
};

export default CreateUserForm;
