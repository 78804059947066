import React from 'react';
import { FeatureToggle } from 'react-feature-toggles';
import { FEATURE_TOGGLE_DEBUG } from 'utils/dictionary/featureToggles';
import { NotificationContext } from 'contexts/notificationContext';

import style from './debugValueWithClipboard.module.scss';

const DebugValueWithClipboard = props => {
  const { showNotification } = React.useContext(NotificationContext);

  const { title, className, titleClassName, valueClassName, value } = props;

  return (<FeatureToggle featureName={FEATURE_TOGGLE_DEBUG}>
    <div className={className}>
      {title
        ? (<span className={titleClassName ?? style.title}>{title}</span>)
        : (<></>)}
      <span className={valueClassName}>
        <div>
          <input type="text" value={value} size={value?.length} readonly onFocus={e => e.target.select()}/>
          <button style={{marginLeft: "10px"}} onClick={() => { navigator.clipboard.writeText(value); showNotification(`${title ?? 'Value'} copied!`); }}>Copy to clipboard</button>
        </div>
      </span>
    </div>
  </FeatureToggle>);
}

export default DebugValueWithClipboard