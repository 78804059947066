import * as configuration from 'utils/configuration/applications';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;

const applicationsUrl = baseUrl + configuration.APPLICATIONS_ROOT;

export async function getApplications(skip = 0, top = 10, accessToken = null) {
  return await utils.getPaginatedResult(
    applicationsUrl,
    skip,
    top,
    accessToken,
  );
}

export async function createApplication(application, accessToken) {
  return await utils.createResource(applicationsUrl, application, accessToken);
}

// Given a valid application id and an access token with the correct permissions, retrieve the
// application from the backend service.
// Note: For some reason, the json payload is being returned in data.data instead of just data.
export async function getApplication(applicationId, accessToken) {
  return await utils.getResource(
    `${applicationsUrl}/${applicationId}`,
    accessToken,
  );
}

// Updates the provided applicationId with the application object. The update will fail if the etag
// does not match, indicating that a newer version of the application object exists on the backend.
export async function updateApplication(
  applicationId,
  application,
  etag,
  accessToken,
) {
  return await utils.updateResource(
    `${applicationsUrl}/${applicationId}`,
    application,
    etag,
    accessToken,
  );
}

export async function deleteApplication(applicationId, etag, accessToken) {
  return await utils.deleteResource(
    `${applicationsUrl}/${applicationId}`,
    etag,
    accessToken,
  );
}
