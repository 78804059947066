import MaterialTable from '@material-table/core';
import {
  createAsset,
  deleteAsset,
  getAssets,
  getUnassignedAssets,
} from 'utils/api/applicationAssets';
import { AuthContext } from 'contexts/authContext';
import React, { useContext, useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const EditApplicationAssets = ({
  applicationId,
  assetsList,
  setAssetsMethod,
  setStatus,
}) => {
  const authContext = useContext(AuthContext);

  const [unassignedAssets, setUnassignedAssets] = useState([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (applicationId && authContext && authContext.accessToken) {
      getUnassignedApplicationAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, authContext, authContext.accessToken]);

  function reset() {
    getApplicationAssets();
    getUnassignedApplicationAssets();
  }

  function handleDeleteAssetClick(rowData) {
    if (rowData.applicationAssetsId) {
      deleteAsset(rowData.applicationAssetsId, authContext.accessToken).then(
        (response, data, error) => {
          if (response.error || response.response.status !== 200) {
            setStatus({
              success: false,
              message: 'Failed to delete Asset',
            });
          } else {
            setStatus({
              success: true,
              message: 'Asset deleted successfully',
            });
            reset();
          }
        },
      );
    }
  }

  async function handleNewAssetClick() {
    let assetToAdd = {
      applicationId: applicationId,
      assetId: selected,
    };
    setSelected('');
    return await createAsset(assetToAdd, authContext.accessToken);
  }

  function handleError(errorCode, requestType) {
    setStatus({
      success: false,
      message: `Failed to ${requestType} Asset.`,
    });
    reset();
  }

  function handleSuccess(request) {
    setStatus({
      success: true,
      message: `Asset ${request} successfully`,
    });
    reset();
  }

  function getApplicationAssets() {
    getAssets(applicationId, authContext.accessToken).then(
      (response, data, error) => {
        if (response.error || response.response.status !== 200) {
          setStatus({
            success: false,
            message: 'Failed to load asset',
          });
        }
        setAssetsMethod(response.data);
      },
    );
  }

  function getUnassignedApplicationAssets() {
    getUnassignedAssets(applicationId, authContext.accessToken).then(
      (response, data, error) => {
        if (response.error || response.response.status !== 200) {
          setStatus({
            success: false,
            message: 'Failed to load unassigned assets',
          });
        }
        setUnassignedAssets(response.data);
      },
    );
  }

  return (
    <>
      <MaterialTable
        columns={[
          {
            title: 'applicationAssetsId',
            field: 'applicationAssetsId',
            hidden: true,
          },
          { title: 'applicationId', field: 'applicationId', hidden: true },
          {
            title: 'Name',
            field: 'assetName',
            editComponent: props => (
              <Select
                value={selected}
                onChange={event => {
                  setSelected(event.target.value);
                }}
                style={{ width: '50%' }}
              >
                {unassignedAssets?.map((obj, index) => (
                  <MenuItem key={index} value={obj.assetId}>
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
            ),
          },
        ]}
        data={assetsList ?? []}
        title="Content Assets"
        options={{
          sorting: true,
          paging: true,
          search: false,
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [10, 20, 50],
        }}
        editable={{
          onRowAdd: () =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                handleNewAssetClick().then(res => {
                  if (res.error || res.response.status !== 201) {
                    handleError(res.response.status, 'create');
                    reject();
                  } else {
                    handleSuccess('created');
                    resolve();
                  }
                });
              }, 1000);
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                handleDeleteAssetClick(oldData);
                resolve();
              }, 1000);
            }),
        }}
      />
    </>
  );
};

export default EditApplicationAssets;
