import { useEffect } from 'react';
import {
  APPLICATION_MGMT_TABLE_ID,
  MANAGE_USERS_TABLE_CAPTION,
  MANAGE_USERS_FOOTER_TABLE_CAPTION,
  MANAGE_USERS_TABLE_ID,
  ORG_ADMIN_TABLE_CAPTION,
  ORG_ADMIN_TABLE_ID,
  ORG_SUBSCRIPTIONS_TABLE_CAPTION,
  SUBSCRIPTIONS_TABLE_ID,
  APPLICATION_MANAGEMENT_TABLE_CAPTION,
  APPLICATION_MANAGEMENT_FOOTER_TABLE_CAPTION,
  ORG_ADMIN_FOOTER_TABLE_CAPTION,
  ORG_SUBSCRIPTIONS_FOOTER_TABLE_CAPTION,
} from 'utils/dictionary/overview';

/**
 * Set the table caption for accessibility screen reader
 */
export const useSetTableCaption = tableID => {
  useEffect(() => {
    const getTableElement = document.getElementsByTagName('table');

    setTimeout(() => {
      if (getTableElement.length > 0) {
        if (
          tableID === MANAGE_USERS_TABLE_ID ||
          tableID === APPLICATION_MGMT_TABLE_ID
        ) {
          if (tableID === MANAGE_USERS_TABLE_ID) {
            if (getTableElement[0] !== undefined) {
              getTableElement[0].setAttribute(
                'aria-label',
                MANAGE_USERS_TABLE_CAPTION,
              );
            }
            if (getTableElement[1] !== undefined) {
              getTableElement[1].setAttribute(
                'aria-label',
                MANAGE_USERS_FOOTER_TABLE_CAPTION,
              );
            }
          } else if (tableID === APPLICATION_MGMT_TABLE_ID) {
            if (getTableElement[0] !== undefined) {
              getTableElement[0].setAttribute(
                'aria-label',
                APPLICATION_MANAGEMENT_TABLE_CAPTION,
              );
            }
            if (getTableElement[1] !== undefined) {
              getTableElement[1].setAttribute(
                'aria-label',
                APPLICATION_MANAGEMENT_FOOTER_TABLE_CAPTION,
              );
            }
          }
        } else {
          if (tableID === ORG_ADMIN_TABLE_ID) {
            if (getTableElement[0] !== undefined) {
              getTableElement[0].setAttribute(
                'aria-label',
                ORG_ADMIN_TABLE_CAPTION,
              );
            }
            if (getTableElement[1] !== undefined) {
              getTableElement[1].setAttribute(
                'aria-label',
                ORG_ADMIN_FOOTER_TABLE_CAPTION,
              );
            }
          } else if (tableID === SUBSCRIPTIONS_TABLE_ID) {
            if (getTableElement[2] !== undefined) {
              getTableElement[2].setAttribute(
                'aria-label',
                ORG_SUBSCRIPTIONS_TABLE_CAPTION,
              );
              if (getTableElement[3] !== undefined) {
                getTableElement[3].setAttribute(
                  'aria-label',
                  ORG_SUBSCRIPTIONS_FOOTER_TABLE_CAPTION,
                );
              }
            }
          }
        }
      }
    }, 1000);
  }, [tableID]);
};

export default useSetTableCaption;
