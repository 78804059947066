import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;

const settingUrl = baseUrl + 'v1/setting';

export async function getSubscriptionOptions(
  type,
  accessToken
) {
  return await utils.getResource(
    `${settingUrl}/subscription/type/${type}`,
    accessToken,
  );
}

export async function getSubscriptionSettings(
  subscriptionId,
  accessToken
) {
  return await utils.getResource(
    `${settingUrl}/subscription/${subscriptionId}`,
    accessToken,
  );
}

export async function saveSubscriptionOption(
  subscriptionId,
  body,
  accessToken,
) {
  return await utils.createResource(
    `${settingUrl}/subscription/${subscriptionId}/option`,
    body,
    accessToken,
  );
}
