import React, {
  useContext,
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Formik } from 'formik';
import { AuthContext } from 'contexts/authContext';
import { dateFormatter, DateFormatReadable } from 'utils/timeAndDate';
import { NotificationContext } from 'contexts/notificationContext';
import EditTenantAdministratorDatesModal from 'components/tenantManagement/editTenantAdministratorDatesModal/editTenantAdministratorDatesModal.js';
import {
  ERROR_MESSAGE_BAD_REQUEST,
  ERROR_MESSAGE_INTERNAL_SERVER_ERROR,
  ERROR_MESSAGE_NETWORK_ERROR,
  ERROR_MESSAGE_UNKNOWN_ERROR,
} from 'utils/dictionary';
import {
  FormInitialValues,
  TenantAdminSchemaWithDatesDefinition,
  getCustomUserValidationSchema,
} from 'components/tenantManagement/createTenantAdministrator/formSchemaAndInitialValues';
import { EDIT_SUBSCRIPTION_PATH } from 'utils/configuration/links';
import { updateTenantAdministratorDates } from 'utils/api/tenantAdmin';
import { Redirect } from 'react-router-dom';

const TenantAdministratorEditDates = forwardRef((props, ref) => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const [open, setOpen] = useState(false);
  const closeModal = useRef({ close: false });
  const { accessToken } = useContext(AuthContext);
  const { showNotification } = useContext(NotificationContext);
  const { rowData = {}, organization, closeMenu, tenantId = {} } = props ?? {};
  const { userId, startDate, endDate } = rowData;
  const organizationId = organization?.organizationId;

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const handleClose = evt => {
    setOpen(false);
    closeMenu();
  };

  // Given a status, figure out the default message for the response
  const getDefaultMessage = response => {
    switch (response?.status) {
      case 400:
        return ERROR_MESSAGE_BAD_REQUEST;
      case 500:
        return ERROR_MESSAGE_INTERNAL_SERVER_ERROR;
      default:
        if (response === null) {
          return ERROR_MESSAGE_NETWORK_ERROR;
        } else {
          return ERROR_MESSAGE_UNKNOWN_ERROR;
        }
    }
  };

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_SUBSCRIPTION_PATH,
          search: window.location.search,
          state: {
            rowData: {
              subscriptionId: redirect?.data?.tenantId,
            },
            organizationId: redirect?.data?.organization,
          },
        }}
      />
    );
  }

  const editDatesForTenantAdministrator = async (values, { resetForm }) => {
    const { startDate, endDate } = values;

    const payload = {
      userId: userId,
      startDate: startDate,
      endDate: endDate,
      organizationId: organizationId,
      tenantId: tenantId,
    };

    const { response, error } = await updateTenantAdministratorDates(
      tenantId,
      payload,
      accessToken,
    );

    if (response?.ok) {
      closeModal.current = { close: true }; //Conditional to close modal in the component
      resetForm(FormInitialValues);

      const today = dateFormatter.format(new Date());
      const newStartDate = dateFormatter.format(new Date(payload.startDate));
      const prevStartDate = dateFormatter.format(new Date(rowData.startDate));

      if (today === newStartDate && prevStartDate !== newStartDate) {
        showNotification(
          `Invitation emailed to ${rowData.firstName} ${rowData.lastName}. This user will have 30 days to activate their account.`,
          false,
          10000,
        );
      } else if (prevStartDate !== newStartDate) {
        showNotification(
          `Invitation will be emailed to ${rowData.firstName} ${
            rowData.lastName
          } on ${DateFormatReadable(
            payload.startDate,
          )}. This user will have 30 days to activate their account.`,
          false,
          10000,
        );
      }
      setRedirect({ isRedirect: true, data: { organization, tenantId } });
    } else {
      if (response?.status === 400) {
        showNotification(ERROR_MESSAGE_BAD_REQUEST, true, 10000);
      } else if (response?.status === 500) {
        showNotification(ERROR_MESSAGE_INTERNAL_SERVER_ERROR, true, 10000);
      } else {
        showNotification(
          error?.details?.error?.message ?? getDefaultMessage(response),
          true,
          10000,
        );
      }
    }
  };

  const dateValidationSchema = getCustomUserValidationSchema({
    startDate: TenantAdminSchemaWithDatesDefinition.startDate,
    endDate: TenantAdminSchemaWithDatesDefinition.endDate,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        startDate: startDate,
        endDate: endDate ? endDate : null,
      }}
      onSubmit={editDatesForTenantAdministrator}
      validationSchema={dateValidationSchema}
      handleClose={handleClose}
      children={props => (
        <EditTenantAdministratorDatesModal
          open={open}
          handleClose={handleClose}
          handleCancel={handleClose}
          userInfo={rowData}
          {...props}
        />
      )}
    />
  );
});

export default TenantAdministratorEditDates;
