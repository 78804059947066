import React, { Fragment } from 'react';
import InternalTenantNotesPanel from 'components/tenantManagement/internalTenantNotesPanel/internalTenantNotesPanel';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import style from './subscriptionDetailPanel.module.scss';
import { getRedoxIdV2 } from 'containers/organizationManagement/editSubscriptionConfigureProducts/utils';
import { ADVISE } from 'utils/dictionary';
import { ADVISEINTERNAL, SUBSCRIPTION } from 'utils/configuration/settings';

const GetApplicationNames = (applications, entApplications) => {
  return entApplications.map(app => {
    let provisioned = applications.find(x => x.sourceId === app.id)
      ? 'Yes'
      : 'No';

    return { sourceId: app.id, name: app.name, provisioned: provisioned };
  }, {});
};

const GetConfigureProductAndContents = (
  entitlementPacket,
  licensedApplications,
) => {
  const { licensing = {} } = entitlementPacket ?? {};
  const { applications: entApplications = [] } = licensing;

  const foundRedoxId = getRedoxIdV2(licensedApplications);

  const appData = GetApplicationNames(licensedApplications, entApplications);

  return {
    applicationsData: appData,
    foundRedoxId: foundRedoxId,
  };
};

const GetSubscriptionConfigurationValues = rowData => {
  const values = [];

  if (rowData.friendlyId) {
    values.push({
      name: 'Content Identifier',
      value: rowData.friendlyId,
    });
  }

  if (rowData.alias) {
    values.push({
      name: 'Auth0 Alias',
      value: rowData.alias,
    });
  }

  return values;
};

const SubscriptionDetailPanelV2 = props => {
  const {
    renderSubscriptionEditConfigureProducts,
    entitlementPacketData,
    rowData,
    organizationId,
    settingList,
    readOnly = false,
  } = props;

  const { entitlementPacket, subscriptionLicense, licensedApplications } =
    entitlementPacketData ?? {};

  const subSettings = settingList
    ?.filter(x => x.type === SUBSCRIPTION)
    .sort((a, b) => a.name.localeCompare(b.name));

  const adviseInternalSettings = settingList?.filter(
    x => x.type === ADVISEINTERNAL,
  );

  function getValue(value) {
    if (value.toLowerCase() === 'true') {
      return 'On';
    } else if (value.toLowerCase() === 'false') {
      return 'Off';
    } else {
      return value;
    }
  }

  const NoProductsConfigured = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={8}>
          <div className={style.noProduct}>
            No products configured.
            {!readOnly && ' Select Modify Subscription to add products.'}
          </div>
        </Grid>
        <Grid item xs={4}>
          {!readOnly && (
            <div style={{ float: 'right' }}>
              {renderSubscriptionEditConfigureProducts({
                rowData,
                organizationId,
              })}
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  const ProductsAndContentsList = props => {
    const { entitlementPacket, licensedApplications } = props;

    const { applicationsData, foundRedoxId } = GetConfigureProductAndContents(
      entitlementPacket,
      licensedApplications,
    );

    applicationsData.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs container direction="column">
          {applicationsData.map((item, index) => {
            return (
              <Grid
                item
                xs
                container
                direction="row"
                key={index}
                className={style.sectionContainer}
              >
                <Grid item xs={12}>
                  <div className={style.title}>{item.name}</div>
                </Grid>

                <div className={style.row}>
                  <div className={`${style.colTitle}  ${style.alignRight}`}>
                    Provisioned
                  </div>
                  <div className={`${style.data} ${style.column} `}>
                    {item.provisioned}
                  </div>
                </div>
                <Fragment>
                  {item.sourceId === ADVISE &&
                    foundRedoxId.found &&
                    foundRedoxId.redoxId && (
                      <div className={style.row}>
                        <div
                          className={`${style.colTitle}  ${style.alignRight}`}
                        >
                          Redox ID
                        </div>
                        <div className={`${style.data} ${style.column} `}>
                          {foundRedoxId.redoxId}
                        </div>
                      </div>
                    )}

                  {item.sourceId === ADVISE &&
                    !readOnly &&
                    adviseInternalSettings.length > 0 &&
                    adviseInternalSettings?.map((item, i) => (
                      <div className={style.row} key={i}>
                        <div
                          className={`${style.colTitle}  ${style.alignRight}`}
                        >
                          {item.name}
                        </div>
                        <div className={`${style.data} ${style.column} `}>
                          {getValue(item.option.optionValue)}
                        </div>
                      </div>
                    ))}
                </Fragment>
              </Grid>
            );
          })}
        </Grid>
        {readOnly ? (
          <></>
        ) : (
          <>
            {renderSubscriptionEditConfigureProducts({
              rowData,
              organizationId,
            })}
          </>
        )}
      </Grid>
    );
  };

  const subscriptionConfiguration = GetSubscriptionConfigurationValues(rowData);

  return (
    <div className={style.subscriptionContainer}>
      {Object.keys(entitlementPacketData).length ? (
        <>
          {subscriptionLicense ? (
            <div>
              <ProductsAndContentsList
                entitlementPacket={entitlementPacket}
                licensedApplications={licensedApplications}
              />
            </div>
          ) : (
            <NoProductsConfigured />
          )}
          {!readOnly &
          (!!subscriptionConfiguration.length || !!subSettings?.length) ? (
            <Grid
              item
              xs
              container
              direction="row"
              className={style.sectionContainer}
            >
              <div className={style.title}>Tenant Configuration</div>

              {subscriptionConfiguration.map((item, i) => (
                <div className={style.row} key={i}>
                  <div className={`${style.colTitle}  ${style.alignRight}`}>
                    {item.name}
                  </div>
                  <div className={`${style.data} ${style.column} `}>
                    {item.value}
                  </div>
                </div>
              ))}

              {subSettings?.map((item, i) => {
                return (
                  <div className={style.row} key={i}>
                    <div className={`${style.colTitle}  ${style.alignRight}`}>
                      {item.name}
                    </div>
                    <div className={`${style.data} ${style.column} `}>
                      {getValue(item.option.optionValue)}
                    </div>
                  </div>
                );
              })}
            </Grid>
          ) : null}
          <InternalTenantNotesPanel {...props} />
        </>
      ) : (
        <Skeleton variant="rect" height={250} />
      )}
    </div>
  );
};

SubscriptionDetailPanelV2.propTypes = {
  renderSubscriptionEditConfigureProducts: PropTypes.func.isRequired,
  entitlementPacketData: PropTypes.shape({}).isRequired,
  rowData: PropTypes.shape({}).isRequired,
};

export default SubscriptionDetailPanelV2;
