import React from 'react';
import UserPermissionCheckbox from 'pages/users/createUser/components/userPermissionCheckbox/userPermissionCheckbox';
//import style from './permissionsAssignment.module.scss';

const PermissionsAssignment = props => {
  const {
    contentFeedbackEnabled,
    userHasContentFeedback,
    userHasLogService,
    readOnly,
  } = props;

  return (
    <>
      {readOnly ? (
        <>
          {contentFeedbackEnabled && userHasContentFeedback ? (
            <span>
              This user can submit content feedback on behalf of my organization
            </span>
          ) : userHasLogService ? (
            <span>
              This user can log a service request on behalf of my organization
            </span>
          ) : (
            <span>This user has not been assigned any permissions</span>
          )}
        </>
      ) : (
        <>
          {contentFeedbackEnabled && (
            <UserPermissionCheckbox
              name="contentFeedback"
              text="This user can submit content feedback on behalf of my organization"
            />
          )}
          <UserPermissionCheckbox
            name="logService"
            text="This user can log a service request on behalf of my organization"
          />
        </>
      )}
    </>
  );
};

export default PermissionsAssignment;
