import { Skeleton, Grid } from '@mui/material';

export const TableSkeleton = () => {
  return (
    <Grid container direction="column" alignItems="stretch" spacing={6}>
      <Grid xs item>
        <Skeleton height={120} width={'100%'} />
      </Grid>
      <Grid xs item>
        <Skeleton height={40} width={'100%'} />
        <Skeleton height={40} width={'100%'} />
        <Skeleton height={40} width={'100%'} />
        <Skeleton height={40} width={'100%'} />
        <Skeleton height={40} width={'100%'} />
      </Grid>
    </Grid>
  );
};

export const CardSkeleton = () => {
  return (
    <>
      <Grid container direction="row" alignItems="center" spacing={8}>
        <Skeleton variant="circle" height={30} width={30} />
        <Grid xs item>
          <Skeleton height={40} width={260} />
          <Skeleton height={20} width={180} />
        </Grid>
      </Grid>
    </>
  );
};

export const ListSkeleton = () => {
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Skeleton height={80} width={420} />
        <Skeleton height={80} width={100} />
      </Grid>
    </>
  );
};

export const HeaderWithItemListSkeleton = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Grid xs item>
        <Skeleton variant="rect" height={80} width={'100%'} />
      </Grid>
      <Grid
        xs
        item
        container
        direction="row"
        alignItems="stretch"
        justifyContent="flex-start"
      >
        <Grid xs item>
          <Skeleton height={40} width={260} />
          <Skeleton height={40} width={260} />
          <Skeleton height={40} width={260} />
        </Grid>
        <Grid xs item>
          <Skeleton height={40} width={260} />
          <Skeleton height={40} width={260} />
        </Grid>
        <Grid xs item>
          <Skeleton height={40} width={260} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const NoteSkeleton = () => {
  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        justifyContent="flex-start"
      >
        <Grid xs item>
          <Skeleton variant="rect" height={160} width={'100%'} />
        </Grid>
        <Grid
          xs
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Skeleton height={80} width={110} />
        </Grid>
      </Grid>
    </>
  );
};
