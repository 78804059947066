import { useState, useEffect, Fragment, useContext } from 'react';
import { saveSubscriptionOption } from 'utils/api/settings';
import { GreenSwitch } from 'components/buttons/button';
import PropTypes from 'prop-types';
import { INPUT_TYPE_SWITCH } from 'utils/configuration/settings';
import { FormControlLabel, Typography } from '@mui/material';
import { AuthContext } from 'contexts/authContext';
import { NotificationContext } from 'contexts/notificationContext';
import style from './singleSetting.module.scss';

const SingleSetting = props => {
  const {
    setFieldValue,
    field,
    option,
    setting,
    errors,
    setFieldTouched,
    subscriptionId,
  } = props;

  const { accessToken } = useContext(AuthContext);
  const { showNotification } = useContext(NotificationContext);

  const [currentSetting, setCurrentSetting] = useState({});

  useEffect(() => {
    setCurrentSetting(setting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting]);

  useEffect(() => {
    Object.keys(errors).forEach(key => setFieldTouched(key));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  function getBoolValue() {
    const val = currentSetting?.option?.optionValue;
    return val?.toLowerCase() === 'true' ? true : false;
  }

  const handleSingleSelection = async value => {
    const val = value.toString().toLowerCase();

    const optItem = option?.optionList.filter(
      y => y.optionValue.toLowerCase() === val,
    )[0];

    const updateSetting = { ...setting };
    updateSetting.option = optItem;

    await saveSetting(
      subscriptionId,
      updateSetting,
      accessToken,
      setFieldValue,
      field,
      setCurrentSetting,
      showNotification,
    );
  };

  return (
    option !== null && (
      <Fragment>
        {option.inputType === INPUT_TYPE_SWITCH ? (
          <FormControlLabel
            control={
              <GreenSwitch
                checked={getBoolValue()}
                onChange={e => handleSingleSelection(e.target.checked)}
                className={style.switch}
              />
            }
            aria-label={option.name}
            label={
              <Typography className={style.switchLabel}>
                {option.name}
                <br />
                <span className={style.description}>{option.description}</span>
              </Typography>
            }
            labelPlacement="start"
            className={style.labelContainer}
          />
        ) : null}
      </Fragment>
    )
  );
};

SingleSetting.prototype = {
  option: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  field: PropTypes.string,
};

export default SingleSetting;

async function saveSetting(
  subscriptionId,
  updateSetting,
  accessToken,
  setFieldValue,
  field,
  setCurrentSetting,
  showNotification,
) {
  const response = await saveSubscriptionOption(
    subscriptionId,
    updateSetting,
    accessToken,
  );

  if (response.response.ok) {
    setFieldValue(field, updateSetting);
    setCurrentSetting(updateSetting);
    showNotification('Setting updated successfully.', false, 3000);
  } else {
    showNotification(
      'There was an error saving the setting. Please try again or contact your Healthwise developers if the issue persists.',
      true,
      5000,
    );
  }
}
