import { BUILD_ID, BUILD_NUMBER, COMMIT_HASH } from 'utils/dictionary/env';

const Health = () => {
  const data = {
    CommitHash: `${COMMIT_HASH}`,
    BuildNumber: `${BUILD_NUMBER}`,
    BuildId: `${BUILD_ID}`,
  };
  return (
    <div style={{ margin: '10px', color: 'black', fontSize: '1rem' }}>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

export default Health;
