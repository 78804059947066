import React, {
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { AuthContext } from 'contexts/authContext';
import { reactivateTenantAdministrator } from 'utils/api/tenantAdmin';
import ReactivationModal from 'components/modal/reactivationModal';
import {
  TenantAdminSchemaWithDatesDefinition,
  getCustomUserValidationSchema,
} from 'components/tenantManagement/createTenantAdministrator/formSchemaAndInitialValues';
import { DateFormatReadable, dateFormatter } from 'utils/timeAndDate';
import { isEqual } from 'date-fns';
import { NotificationContext } from 'contexts/notificationContext';
import { EDIT_SUBSCRIPTION_PATH } from 'utils/configuration/links';
import { Redirect } from 'react-router-dom';

const TenantAdministratorActivate = forwardRef((props, ref) => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const [open, setOpen] = useState(false);
  const { accessToken } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    organization = {},
    rowData = {},
    closeMenu,
    tenantId = {},
  } = props ?? {};
  const { firstName, lastName, email, userId } = rowData;
  const { showNotification } = useContext(NotificationContext);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const handleClose = evt => {
    setOpen(false);
    setIsSubmitting(false);
    closeMenu();
  };

  const handleConfirm = async values => {
    var messageKey = Date.now();
    await activate(values, messageKey);
  };

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_SUBSCRIPTION_PATH,
          search: window.location.search,
          state: {
            rowData: {
              subscriptionId: redirect?.data?.tenantId,
            },
            organizationId: redirect?.data?.organization,
          },
        }}
      />
    );
  }

  const activate = async (values, messageKey) => {
    setIsSubmitting(true);

    const activateUserDto = {
      startDate: values.startDate,
      endDate: values.endDate,
    };
    const { response } = await reactivateTenantAdministrator(
      tenantId,
      userId,
      activateUserDto,
      accessToken,
    );

    const today = dateFormatter.format(new Date());
    const newStartDate = dateFormatter.format(new Date(values.startDate));

    if (response?.status === 204) {
      if (isEqual(new Date(newStartDate), new Date(today))) {
        showNotification(
          `Invitation emailed to ${firstName} ${lastName}. This user will have 30 days to activate their account.`,
          false,
          10000,
          messageKey,
        );
      } else {
        showNotification(
          `Invitation will be emailed to ${firstName} ${lastName} on ${DateFormatReadable(
            Date.parse(newStartDate),
          )}. This user will have 30 days date to activate their account.`,
          false,
          10000,
          messageKey,
        );
      }
      handleClose();
      setRedirect({ isRedirect: true, data: { organization, tenantId } });
    } else {
      showNotification(
        'Error adding new Administrator. Please try again or contact your Healthwise developers.',
        true,
        10000,
        messageKey,
      );
    }
  };

  const dateValidationSchema = getCustomUserValidationSchema({
    startDate: TenantAdminSchemaWithDatesDefinition.startDate,
    endDate: TenantAdminSchemaWithDatesDefinition.endDate,
  });

  const reactivateTextProps = {
    dateValidationSchema,
    title: 'Activate this user?',
    confirmText: 'Activate user',
    contentText: `Account activation instructions will be sent to `,
    altText: ' on the start date selected below.',
    dateText: 'Please select a start date for this user to continue.',
    email: email,
    personFullName: firstName + ' ' + lastName,
  };

  return (
    <>
      {open ? (
        <ReactivationModal
          open={open}
          handleClose={handleClose}
          handleCancel={handleClose}
          handleConfirm={handleConfirm}
          labelledBy="administratorResendInvitationModalTitle"
          describedBy="administratorResendInvitationModalDescription"
          isSubmitting={isSubmitting}
          {...reactivateTextProps}
          {...props}
        />
      ) : null}
    </>
  );
});

export default TenantAdministratorActivate;
