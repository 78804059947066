import React, { useEffect, useState } from 'react';
import OrganizationManagementEditOrganization from 'components/organizationManagement/organizationManagementEditOrganization/organizationManagementEditOrganization';
import {
  useGetOrganization,
  useRetrieveEntitlementPacket,
  useGetSubscriptionTypes,
} from 'utils/hooks/useToCallApiEndpoints';
import PageHeader from 'components/pageHeader/pageHeader';
import OrganizationSubscriptionDetailPanel from 'containers/organizationManagement/editOrganization/organizationSubscriptionDetailPanel/organizationSubscriptionDetailPanel';
import CreateOrganizationNotes from 'containers/organizationManagement/createOrganizationNotes/createOrganizationNotes';
import DeleteOrganization from 'containers/organizationManagement/deleteOrganization';
import DeleteOrganizationNotes from 'containers/organizationManagement/deleteOrganizationNotes';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import { ORGANIZATION_LIST } from 'utils/configuration/links';
import { Container } from '@mui/material';

const setCompleteOrganizationState = (
  organization,
  organizationToEditLoading,
) => ({
  organization,
  organizationToEditLoading,
});

const EditOrganization = props => {
  const [completeOrganization, setCompleteOrganization] = useState(
    setCompleteOrganizationState({}, true),
  );

  // orgToEdit is the orgId.
  const { organizationToEdit } = props;

  //TODO: Display error to user
  // TODO BUG - Need to restrict the subscriptions returned by useGetOrganization to only non-management if user isn't a provisioning manager. See useGetPaginatedSubscriptions for more details.
  const {
    data: organization,
    etag: organizationEtag,
    isLoading: organizationLoading,
  } = useGetOrganization(organizationToEdit);

  const { data: entitlementPacket, isLoading: entitlementPacketLoading } =
    useRetrieveEntitlementPacket(organizationToEdit);

  //TODO: Display error to user
  const { data: subscriptionTypes, isLoading: subscriptionTypesLoading } =
    useGetSubscriptionTypes();

  // Inject the subscription type name into the organization object
  useEffect(() => {
    const setupCompleteOrganization = async () => {
      var subscriptions = organization?.data?.subscriptions;
      subscriptions?.forEach(subscription => {
        var particularSubscriptionType = subscriptionTypes.find(
          subscriptionType =>
            subscriptionType.subscriptionTypeId ===
            subscription.subscriptionTypeId,
        );
        if (particularSubscriptionType) {
          subscription['subscriptionType'] = particularSubscriptionType['name'];
        } else {
          // Just in case particularSubscriptionType isn't found
          subscription['subscriptionType'] = '';
        }
      });
      setCompleteOrganization(
        setCompleteOrganizationState(organization.data, false),
      );
    };

    if (!organizationLoading && !subscriptionTypesLoading) {
      setupCompleteOrganization();
    }
    // The organization object is being modified, so that causes an infinite loop. Adding
    // the subscriptionType to the return organization on the service side will solve this problem

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationLoading, subscriptionTypesLoading]);

  const orgName = completeOrganization?.organization?.organizationName;

  const breadcrumbItems = [
    { text: 'Organization Dashboard', to: { pathname: ORGANIZATION_LIST } },
    { text: `${orgName}` },
  ];

  return (
    <>
      <PageHeader title={orgName} />
      <Container maxWidth="lg">
        <Breadcrumb items={breadcrumbItems}></Breadcrumb>
        <OrganizationManagementEditOrganization
          organizationToEdit={completeOrganization}
          organizationEtag={organizationEtag}
          entitlementData={{ entitlementPacket, entitlementPacketLoading }}
          subscriptionTypesData={{
            subscriptionTypes,
            subscriptionTypesLoading,
          }}
          renderSubscriptionDetailPanel={props => {
            return <OrganizationSubscriptionDetailPanel {...props} />;
          }}
          renderOrganizationNotes={props => {
            return <CreateOrganizationNotes {...props} />;
          }}
          renderDeleteOrganization={props => {
            return (
              <DeleteOrganization
                {...props}
                organizationEtag={organizationEtag}
              />
            );
          }}
          renderDeleteSaveOrganizationNotes={props => {
            return (
              <DeleteOrganizationNotes
                {...props}
                organizationEtag={organizationEtag}
              />
            );
          }}
        />
      </Container>
    </>
  );
};

export default EditOrganization;
