import React, {
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { AuthContext } from 'contexts/authContext';
import { deleteSubscription, getSubscription } from 'utils/api/subscriptions';
import VerifyModal from 'components/verifyModal/verifyModal';
import { Redirect } from 'react-router-dom';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { NotificationContext } from 'contexts/notificationContext';

const DeleteOrganizationSubscription = forwardRef((props, ref) => {
  const { accessToken } = useContext(AuthContext);
  const { rowData, organization, closeMenu } = props;
  const { showNotification } = React.useContext(NotificationContext);

  const [deleteModalProperties, setDeleteModalProperties] = useState({});
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [redirect, setRedirect] = useState({ isRedirect: false });

  useImperativeHandle(ref, () => ({
    configureDeleteModal() {
      configureDeleteModal();
    },
  }));

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          search: window.location.search,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const handleClose = () => {
    setDeleteModelOpen(false);
    closeMenu();
  };

  const configureDeleteModal = () => {
    let modalProperties = {
      title: 'Delete this tenant?',
      text: (
        <>
          Are you sure you want to delete{' '}
          <strong>{rowData.subscriptionName}</strong>?
        </>
      ),
      confirmText: 'Delete Tenant',
      warningText: (
        <>
          Deleting a subscription will immediately remove any applications and
          content within that subscription. This action cannot be undone.
        </>
      ),
      handleConfirm: async () => {
        const { etag: subscriptionEtag } = await getSubscription(
          organization.organizationId,
          rowData.subscriptionId,
          accessToken,
        );
        const { response } = await deleteSubscription(
          organization.organizationId,
          rowData.subscriptionId,
          accessToken,
          subscriptionEtag,
        );
        if (response?.status === 200) {
          showNotification(
            rowData.subscriptionName + ' has been successfully deleted.',
            false,
            5000,
            Date.now(),
          );
          const organizationId = organization.organizationId;
          setRedirect({
            isRedirect: true,
            data: { organizationId },
          });
        } else {
          showNotification(
            'Error deleting tenant. Please try again or contact your Healthwise developers.',
            true,
            5000,
            Date.now(),
          );
        }
        handleClose();
      },
    };
    setDeleteModalProperties(modalProperties);
    setDeleteModelOpen(true);
  };

  return (
    <>
      {deleteModelOpen ? (
        <VerifyModal
          open={deleteModelOpen}
          handleClose={handleClose}
          handleCancel={handleClose}
          {...deleteModalProperties}
        />
      ) : null}
    </>
  );
});

export default DeleteOrganizationSubscription;
