import React from 'react';

const Padding = props => {
  const { children, left = 30, right = 30, top = 10, bottom = 50 } = props;
  return (
    <div
      style={{
        paddingLeft: left,
        paddingRight: right,
        paddingTop: top,
        paddingBottom: bottom,
      }}
    >
      {children}
    </div>
  );
};

export default Padding;
