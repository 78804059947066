import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import { AuthContext } from 'contexts/authContext';
import * as Yup from 'yup';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import Tooltip from '@mui/material/Tooltip';
import { TextButton, PrimaryButton } from 'components/buttons/button';
import { getSubscription, updateSubscription } from 'utils/api/subscriptions';
import { Grid } from '@mui/material';
import style from './editableTenantDetailPanel.module.scss';

async function UpdateTenantName(values, accessToken) {
  let { id, name, organizationId } = values;

  const {
    etag: subscriptionEtag,
    data: subscription,
    response,
  } = await getSubscription(organizationId, id, accessToken);

  if (response.ok) {
    let body = {
      subscriptionName: name,
      referenceId: subscription?.data?.referenceId,
    };

    let updatedResponse = await updateSubscription(
      organizationId,
      id,
      body,
      subscriptionEtag,
      accessToken,
    );

    // TODO: Adding in the ? while the appservice isn't updated yet
    if (!updatedResponse?.response?.ok) {
      throw new Error(`Unable to rename the organization to ${name}`);
    }
  } else {
    throw new Error(`Unable to retrieve the organization ${id}`);
  }
}

const EditableTenantDetailPanel = props => {
  const {
    title,
    tenantId,
    setError,
    setSuccess,
    setEditable,
    setTenantName,
    organizationId,
  } = props;
  const { accessToken } = useContext(AuthContext);

  const RenameTenant = async values => {
    if (values.name !== '' && values.name !== title) {
      try {
        await UpdateTenantName(values, accessToken);
        setTenantName(values.name);
        setEditable(false);
        setSuccess('Tenant renamed sucessfully');
      } catch (error) {
        setTenantName(title);
        setError(error.message);
      }
    }
  };

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    name: Yup.string().required('A tenant name is required'),
  });

  return (
    <Formik
      initialValues={{
        id: tenantId,
        name: title,
        organizationId: organizationId,
      }}
      onSubmit={RenameTenant}
      validationSchema={DisplayingErrorMessagesSchema}
    >
      {formikProps => {
        return (
          <Form>
            <Grid container style={{ marginTop: '16px' }}>
              <div className={style.header}>
                <p style={{ margin: '0' }}>Tenant Name:</p>
              </div>
              <div className={style.wrapper}>
                <BaselineTextInput
                  type="text"
                  name="name"
                  className={style.textField}
                  isRequiredErrorMessage={false}
                  hideValidationAdornment={true}
                />
                {formikProps.isSubmitting ? (
                  <ProgressIndicator />
                ) : (
                  <>
                    <div className={style.btnContainer}>
                      {!formikProps.dirty ? (
                        <Tooltip
                          title="There are no changes to save"
                          aria-describedby="There are no changes to save"
                          placement="top"
                          arrow
                        >
                          <span>
                            <PrimaryButton buttonText="Rename Tenant" />
                          </span>
                        </Tooltip>
                      ) : (
                        <PrimaryButton
                          buttonText="Rename Tenant"
                          type="submit"
                        />
                      )}
                      <div className={style.btnWrapper}>
                        <TextButton
                          buttonText="Cancel"
                          handleClick={() => {
                            setEditable(false);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditableTenantDetailPanel;
