import React from 'react';
import { Grid, Container } from '@mui/material';

import { TenantAdministratorPanel } from 'components/tenantManagement/tenantAdministratorPanel/tenantAdministratorPanel';
import ModifySubscriptionProducts from 'containers/organizationManagement/modifySubscriptionProducts/modifySubscriptionProducts';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import PageHeader from 'components/pageHeader/pageHeader';

import {
  useGetSubscription,
  useGetTenantAdministrators,
} from 'utils/hooks/useToCallApiEndpoints';
import {
  EDIT_ORGANIZATION_PATH,
  ORGANIZATION_LIST,
} from 'utils/configuration/links';
import TenantDetailPanel from 'components/tenantManagement/tenantDetailPanel/tenantDetailPanel';

const ModifyTenant = props => {
  const { rowData, organizationId } = props;

  const { data: tenantData } = useGetSubscription(
    organizationId,
    rowData.subscriptionId,
  );

  const { data: administrators, isLoading: organizationAdministratorsLoading } =
    useGetTenantAdministrators(rowData.subscriptionId);

  const tenantName = tenantData?.data?.subscriptionName;

  const breadcrumbItems = [
    { text: 'Organization Dashboard', to: { pathname: ORGANIZATION_LIST } },
    {
      text: `${rowData.organizationName}`,
      to: {
        pathname: EDIT_ORGANIZATION_PATH,
        state: {
          organizationId,
        },
      },
    },
    { text: `${tenantName}` },
  ];

  return (
    <>
      <PageHeader title={tenantName} />
      <Container maxWidth="lg">
        <Breadcrumb items={breadcrumbItems}></Breadcrumb>

        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs={12}>
                <>
                  <TenantDetailPanel
                    tenantData={tenantData.data}
                    organizationId={organizationId}
                  />

                  <ModifySubscriptionProducts
                    rowData={rowData}
                    organizationId={organizationId}
                  />
                  <div style={{ paddingTop: '30px' }}></div>
                  <TenantAdministratorPanel
                    administratorsData={{
                      administrators,
                      organizationAdministratorsLoading,
                    }}
                    organization={organizationId}
                    organizationName={rowData.organizationName}
                    tenant={rowData.subscriptionId}
                    readOnly={false}
                  />
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ModifyTenant;
