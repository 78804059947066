import * as configuration from 'utils/configuration/applications';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;

const applicationsUrl = baseUrl + configuration.APPLICATIONS_ROOT;

export async function createAsset(asset, accessToken) {
  let createUrl = `${applicationsUrl}/assets`;
  return await utils.createResource(createUrl, asset, accessToken);
}

export async function deleteAsset(applicationAssetId, accessToken) {
  let deleteUrl = `${applicationsUrl}/assets/${applicationAssetId}`;
  return await utils.deleteResource(deleteUrl, {}, accessToken);
}

export async function getAssets(applicationId, accessToken) {
  let getUrl = `${applicationsUrl}/${applicationId}/assets`;
  return await utils.getResource(getUrl, accessToken);
}

export async function getUnassignedAssets(applicationId, accessToken) {
  let getUrl = `${applicationsUrl}/${applicationId}/assets/unassigned`;
  return await utils.getResource(getUrl, accessToken);
}
