import * as configuration from 'utils/configuration/organizations';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;

const organizationsUrl = baseUrl + configuration.ORGANIZATIONS_ROOT;

export async function getOrganizations(skip = 0, top = 10, accessToken = null) {
  return await utils.getPaginatedResult(
    organizationsUrl,
    skip,
    top,
    accessToken,
  );
}

export async function createOrganization(organization, accessToken) {
  return await utils.createResource(
    organizationsUrl,
    organization,
    accessToken,
  );
}

export async function getOrganization(organizationId, accessToken) {
  return await utils.getResource(
    `${organizationsUrl}/${organizationId}`,
    accessToken,
  );
}

export async function getOrganizationName(organizationId, accessToken) {
  return await utils.getResource(
    `${organizationsUrl}/${organizationId}/name`,
    accessToken,
  );
}

export async function updateOrganization(
  organizationId,
  organization,
  etag,
  accessToken,
) {
  return await utils.updateResource(
    `${organizationsUrl}/${organizationId}`,
    organization,
    etag,
    accessToken,
  );
}

export async function deleteOrganization(organizationId, etag, accessToken) {
  return await utils.deleteResource(
    `${organizationsUrl}/${organizationId}`,
    etag,
    accessToken,
  );
}

export async function disableOrganization(organizationId, etag, accessToken) {
  return await utils.updateResource(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATION_DISABLE}`,
    {},
    etag,
    accessToken,
  );
}

export async function getOrganizationApplications(organizationId, accessToken) {
  return await utils.getResource(
    `${organizationsUrl}/${organizationId}/${configuration.ORGANIZATIONS_AVAILABLE_APPLICATIONS}`,
    accessToken,
  );
}

/**
 * Endpoints for an oganization's notes.
 * @param {guid} organizationId The ID of the organization to lookup
 * @param {long} organizationNoteId The ID of the organization to lookup
 * @param {string} accessToken The access token of the user attempting the lookup
 * @param {string} skip Number of records to skip
 * @param {string} top Number of records to return
 */

export async function getOrganizationNotes(
  skip = 0,
  top = 100,
  organizationId,
  accessToken = null,
) {
  return await utils.getPaginatedResult(
    `${organizationsUrl}/${organizationId}/notes`,
    skip,
    top,
    accessToken,
    '',
    '',
    'asc'
  );
}

export async function createOrganizationNote(
  organizationId,
  payload, 
  accessToken
) {
  return await utils.createResource(
    `${organizationsUrl}/${organizationId}/notes`,
    payload,
    accessToken,
  );
}

export async function updateOrganizationNote(
  organizationId,
  organizationNotesId,
  payload,
  etag,
  accessToken,
) {
  return await utils.putResource(
    `${organizationsUrl}/${organizationId}/notes/${organizationNotesId}`,
    payload,
    accessToken,
    etag,
  );
}

export async function deleteOrganizationNote(
  organizationId, 
  organizationNotesId,
  etag,
  accessToken
) {
  return await utils.deleteResource(
    `${organizationsUrl}/${organizationId}/notes/${organizationNotesId}`,
    etag,
    accessToken,
  );
}