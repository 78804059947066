import React, { forwardRef } from 'react';
import {
  Button as MaterialButton,
  IconButton,
  SvgIcon,
  Radio,
  Switch,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AddIcon, CloseIcon, TrashCanIcon } from 'components/icons/icons';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Edit } from '@mui/icons-material';
import { DEFAULT_BTN_COLOR_CONFIRM_GREEN } from 'utils/dictionary/overview';
import { styled } from '@mui/material/styles';
import style from './button.module.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const PrimaryButton = ({
  buttonText,
  handleClick,
  className,
  disable = false,
  ...props
}) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.primaryButton} ${style.button} ${className}`}
      aria-disabled={disable}
      {...props}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const PrimaryFormSubmitButton = props => {
  const { isValid, dirty, values, actions, handleSubmit, buttonText } = props;

  return (
    <>
      <MaterialButton
        className={`${style.button} ${style.primaryButton}`}
        type="submit"
        variant="contained"
        onClick={() => handleSubmit(values, actions)}
        disabled={!(isValid && dirty)}
      >
        {buttonText}
      </MaterialButton>
    </>
  );
};

export const PrimaryButtonLink = ({ children, ...props }) => {
  return (
    <MaterialButton
      className={`${style.button} ${style.primaryButton}`}
      component={RouterLink}
      {...props}
    >
      {children}
    </MaterialButton>
  );
};

export const SecondaryButton = ({
  ariaLabel,
  buttonText,
  children,
  component,
  handleClick,
  handleChange,
}) => {
  return (
    <MaterialButton
      onClick={handleClick}
      onChange={handleChange}
      className={`${style.button} ${style.secondaryButton}`}
      variant="outlined"
      aria-label={ariaLabel}
      component={component}
    >
      {buttonText}
      {children}
    </MaterialButton>
  );
};

export const SecondaryDarkButton = ({
  ariaLabel,
  buttonText,
  children,
  component,
  handleClick,
  handleChange,
}) => {
  return (
    <MaterialButton
      onClick={handleClick}
      onChange={handleChange}
      className={`${style.button} ${style.secondaryDarkButton}`}
      variant="outlined"
      aria-label={ariaLabel}
      component={component}
    >
      {buttonText}
      {children}
    </MaterialButton>
  );
};

export const DestructivePrimaryButton = ({
  buttonText,
  handleClick,
  ...props
}) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.button} ${style.destructivePrimary} `}
      {...props}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const DestructiveSecondaryButton = ({
  buttonText,
  handleClick,
  disabled,
  ariaLabel,
}) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.button} ${style.destructiveSecondary}`}
      variant="outlined"
      disabled={disabled}
      aria-label={ariaLabel}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const TextButton = ({
  buttonText,
  handleClick,
  className,
  ...props
}) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.button} ${style.textButton} ${className}`}
      {...props}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const AddIconButton = props => {
  return (
    <GenericIconButton aria-label="Add" {...props}>
      <AddIcon />
    </GenericIconButton>
  );
};

export const EditIconButton = props => {
  return (
    <GenericIconButton aria-label="Edit" {...props}>
      <Edit />
    </GenericIconButton>
  );
};

export const TrashIconButton = props => {
  return (
    <GenericIconButton title="Trash" {...props}>
      <TrashCanIcon />
    </GenericIconButton>
  );
};

export const ArrowForwardIconButton = props => {
  return (
    <GenericIconButton title="Edit" {...props}>
      <ArrowForwardIcon />
    </GenericIconButton>
  );
};

export const CloseIconButton = forwardRef((props, ref) => (
  <IconButton
    aria-label="close"
    color="primary"
    {...props}
    size="large"
    ref={ref}
  >
    <SvgIcon>
      <CloseIcon />
    </SvgIcon>
  </IconButton>
));

export const ExpandButton = props => {
  return (
    <GenericIconButton
      className={style.expandButton}
      aria-label={props.ariaLabel}
      {...props}
    >
      {props.expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </GenericIconButton>
  );
};

// TODO: SvgIcon is redundant and should be removed. widths would need to be applied to the HW svgs
const GenericIconButton = ({ children, ...props }) => {
  return (
    <IconButton color="primary" {...props} size="large">
      <SvgIcon>{children}</SvgIcon>
    </IconButton>
  );
};

export const GeneralCancelButton = forwardRef((props, ref) => {
  let text = props.text === undefined ? 'Cancel' : props.text;
  return (
    <MaterialButton
      aria-label={text}
      className={`${style.button} ${style.generalCancelButton} `}
      variant="outlined"
      {...props}
      ref={ref}
    >
      {text}
    </MaterialButton>
  );
});

export const ConfirmationModalButton = ({
  handleClick,
  buttonText,
  backgroundColor,
  className,
  ...props
}) => {
  let bgColor =
    backgroundColor === DEFAULT_BTN_COLOR_CONFIRM_GREEN
      ? style.backgroundGreen
      : style.backgroundRed;
  return (
    <MaterialButton
      className={`${style.button} ${
        style.confirmationModalButton
      } ${bgColor} ${className} ${props.disabled ? style.disabled : null}`}
      variant="outlined"
      onClick={handleClick}
      {...props}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const GeneralIconButton = ({
  buttonText,
  icon,
  className,
  handleClick,
}) => {
  return (
    <MaterialButton
      className={`${style.button} ${className}`}
      variant="contained"
      startIcon={icon}
      onClick={handleClick}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: '#0d8484',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

export const RoundedToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: '10px',
      border: 'none',
      '&:not(:first-of-type)': {
        border: '1px solid #595959',
        borderRadius: '16px',
      },
      '&:first-of-type': {
        border: '1px solid #595959',
        borderRadius: '16px',
      },
    },
  }),
);

export const GreenSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 2,
    margin: 2,
    color: '#727272',
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#006272' : '#006272',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#006272',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    height: 30,
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#D8D8D8' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    border: '1px solid #676767',
  },
}));
