import React, { useEffect, useRef } from 'react';
import { Button } from '@mui/material';

import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from 'components/dialog/dialog';

import { GeneralCancelButton } from 'components/buttons/button';
import { NotificationContext } from 'contexts/notificationContext';

import PropTypes from 'prop-types';
import { Form } from 'formik';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import { BackdropLoader } from 'components/backdropLoader/backdropLoader';
import style from './menuItemModalAndFeedbackModal.module.scss';
import buttonStyle from 'components/buttons/button.module.scss';

const MenuItemModalAndFeedbackModal = props => {
  const {
    open,
    handleClose,
    confirmationButtonText,
    cancelButtonText,
    labelledBy,
    describedBy,
    modalTitle,
    isSubmitting,
    status,
    isDelete,
    content,
  } = props;

  const { isError, message = null, date } = status ?? {};
  const { showNotification } = React.useContext(NotificationContext);

  const buttonColor = isDelete
    ? buttonStyle.buttonRed
    : buttonStyle.greenButton;

  const cancelRef = useRef(null);
  useAddFocus(cancelRef, open);

  useEffect(() => {
    if (message) {
      showNotification(message, isError, 5000, date);
      status.message = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, message, date]);

  return (
    <>
      {isSubmitting ? (
        <BackdropLoader open={isSubmitting} />
      ) : (
        open && (
          <Dialog
            role="dialog"
            disableEnforceFocus
            aria-labelledby={labelledBy}
            aria-describedby={describedBy}
            open={open}
            onClose={handleClose}
            closeAfterTransition
          >
            <DialogTitle className={style.title}>{modalTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText className={style.bodyText}>
                {content}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={style.rightAlignedButtonGroup}>
              <GeneralCancelButton
                onClick={handleClose}
                disableRipple
                ref={cancelRef}
              >
                {cancelButtonText}
              </GeneralCancelButton>
              <Form key="confirm">
                <Button
                  type="submit"
                  className={`${buttonStyle.button} ${buttonColor} ${style.submitButton}`}
                >
                  {confirmationButtonText}
                </Button>
              </Form>
            </DialogActions>
          </Dialog>
        )
      )}
    </>
  );
};

MenuItemModalAndFeedbackModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  confirmationButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  labelledBy: PropTypes.string.isRequired,
  describedBy: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  isDelete: PropTypes.bool,
  content: PropTypes.node.isRequired,
};

MenuItemModalAndFeedbackModal.defaultProps = {
  open: false,
  handleClose: () => {},
  cancelButtonText: '',
  labelledBy: '',
  describedBy: '',
  modalTitle: '',
  modalContent: '',
  isSubmitting: false,
  isDelete: false,
  content: <></>,
};

export default MenuItemModalAndFeedbackModal;
