import React from 'react';
import { Link } from 'react-router-dom';
import UserManagementList from 'pages/users/userManagementList';
import { CreateUser, ModifyUser } from 'containers/users';
import OrganizationList from 'pages/organizations/organizationList';
import AddNewDelegatedUser from 'containers/delegateManagement/addNewDelegatedUser';
import EditOrganization from 'containers/organizationManagement/editOrganization/editOrganization';
import ApplicationManagement from 'pages/applicationManagement/applicationManagement';
import EditApplication from 'pages/applicationManagement/editapplication';
import { ReferenceSubscriptionsProvider } from 'contexts/referenceSubscriptionsContext';
import { Typography } from 'components/typography/typography';
import ModifyTenant from 'containers/organizationManagement/modifyTenant/modifyTenant';
import { DelegatedAccessProvider } from 'contexts/delegatedAccessContext';

import {
  CREATE_USER_PATH,
  EDIT_ORGANIZATION_PATH,
  EDIT_USER_PATH,
  ORGANIZATION_LIST,
  DELEGATED_EDIT_PATH,
  // DELEGATED_ACCESS_PATH,
  USER_MANAGEMENT_PATH,
  APPLICATIONS_PATH,
  EDIT_APPLICATION_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  EDIT_SUBSCRIPTION_PATH,
} from 'utils/configuration/links';

const routes = [
  {
    path: `/${USER_MANAGEMENT_PATH}`,
    sidebar: () => <Link to="/list">List</Link>,
    main: () => <UserManagementList />,
    name: 'User Management List',
  },
  {
    path: `/${CREATE_USER_PATH}`,
    main: () => (
      <ReferenceSubscriptionsProvider>
        <CreateUser />
      </ReferenceSubscriptionsProvider>
    ),
  },
  {
    path: `/${EDIT_USER_PATH}`,
    main: ({ location }) => (
      <ReferenceSubscriptionsProvider>
        <ModifyUser userToEdit={location?.state?.userToEdit ?? ''} />
      </ReferenceSubscriptionsProvider>
    ),
  },
  {
    path: `/${DELEGATED_EDIT_PATH}`,
    main: ({ location }) => (
      <ReferenceSubscriptionsProvider>
        <DelegatedAccessProvider>
          <AddNewDelegatedUser userId={location?.state?.userId ?? ''} />
        </DelegatedAccessProvider>
      </ReferenceSubscriptionsProvider>
    ),
  },
  {
    path: `/${ORGANIZATION_LIST}`,
    main: () => <OrganizationList />,
  },
  {
    path: `/${EDIT_ORGANIZATION_PATH}`,
    main: ({ location }) => (
      <EditOrganization
        organizationToEdit={location?.state?.organizationId ?? ''}
      />
    ),
  },
  {
    path: `/${EDIT_SUBSCRIPTION_PATH}`,
    main: ({ location }) => (
      <ModifyTenant
        rowData={location?.state?.rowData}
        organizationId={location?.state?.organizationId}
      />
    ),
  },
  {
    path: `/${APPLICATIONS_PATH}`,
    main: ({ location }) => <ApplicationManagement />,
  },
  {
    path: `/${EDIT_APPLICATION_PATH}`,
    main: ({ location }) => (
      <EditApplication applicationId={location?.state?.applicationId ?? ''} />
    ),
  },
  {
    path: `/${LOGIN_PATH}`,
    main: () => (
      <Typography variant="h2" style={{ paddingTop: '2rem' }}>
        Redirecting to sign in page...
      </Typography>
    ),
  },
  {
    path: `/${LOGOUT_PATH}`,
    main: () => (
      <Typography variant="h2" style={{ paddingTop: '2rem' }}>
        Signing out...
      </Typography>
    ),
  },
];

export default routes;
