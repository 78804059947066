export const ORGANIZATIONS_ROOT = 'v1/organizations';
export const ORGANIZATIONS_ROOT_V2 = 'v2/organizations';
export const ORGANIZATIONS_AVAILABLE_APPLICATIONS = 'applications';

// The rolename for organization admins
export const ORGANIZATION_ADMIN_ROLE = 'OrganizationAdministrator';

export const TENANT_ADMINISTRATOR = 'administrators';
export const TENANT_ADMINISTRATOR_REACTIVATE = 'reactivate';
export const TENANT_ADMINISTRATOR_DEACTIVATE = 'deactivate';
export const TENANT_ADMINISTRATOR_CREATE = 'createTenantAdmin';
export const TENANT_ADMINISTRATOR_RESEND_INVITE = 'resendInvite';
export const TENANT_ADMINISTRATOR_DATES_UPDATE = 'updateDates';

export const TENANT_USERS = 'users';

export const ORGANIZATION_ROLES = 'roles';
export const ORGANIZATION_DISABLE = 'disable';

export const RESET_PASSWORD = 'resetPassword';

export const ORGANIZATION_CATEGORY_INTERNAL_VALUE = 1;
export const ORGANIZATION_CATEGORY_CLIENT_VALUE = 3;
export const ORGANIZATION_CATEGORY_INTERNAL = 'Internal';

// Tenants
export const TENANT_ADMINISTRATOR_ROOT = 'v1/tenant';
export const TENANTS_ROOT = 'v1/tenants';
export const TENANT_ROOT_V2 = 'v2/tenant';
export const TENANT_AVAILABLE_APPLICATIONS = 'applications';
