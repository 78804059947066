import React, { useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'formik';
import { Container, Divider } from '@mui/material';
import { DELEGATED_ACCESS_PATH } from 'utils/configuration/links';
import { TextButton, PrimaryButton } from 'components/buttons/button';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import { PageLink } from 'components/pageLink/pageLink';
import { ArrowBackIcon } from 'components/icons/icons';
import WarningIcon from '@mui/icons-material/Warning';
import { PageHeader } from 'components/pageHeader/pageHeader';
import { ApplicationSelector } from 'components/applicationSelector/applicationSelector';
import { SingleSelectInput } from 'components/singleSelectInputV2/singleSelectInput';
import { Typography } from 'components/typography/typography';
import style from './delegatedAccessUser.module.scss';
import { AuthContext } from 'contexts/authContext';
import { Panel } from 'components/card/card';

const DelegatedAccessAddNewUser = props => {
  const {
    isValid,
    dirty,
    isSubmitting,
    setFieldValue,
    touched,
    errors,
    initialValues,
    values,
    setFieldTouched,
    delegatedUserOptions,
  } = props;
  let history = useHistory();

  const { organizationAlias } = useContext(AuthContext);

  const handleClose = () => {
    // if the form is dirty, lets prompt the user that they will lose changes if they click discard changes
    history.push({
      pathname: `/${organizationAlias}/delegates`,
      search: window.location.search,
    });
  };

  const closeRef = useRef(null);
  useAddFocus(closeRef, true);

  const modifyingUser = initialValues?.selectUser?.id ?? false;

  const title = modifyingUser
    ? `Edit Access for ${initialValues?.selectUser?.name}`
    : 'Add New Delegate';

  const formText =
    'By completing this form, you are granting a Healthwise employee access to your organization’s data. You can revoke this user’s access at any time.';

  return (
    <>
      <PageHeader title={title} />
      <Container maxWidth="md">
        <PageLink
          icon={<ArrowBackIcon />}
          text="Back to Delegate Access"
          to={{
            pathname: DELEGATED_ACCESS_PATH,
          }}
        />
        <Panel className={style.panel}>
          <Form noValidate>
            <div className={style.warningBanner}>
              <WarningIcon className={style.icon} />
              <Typography className={style.warningTextTypography}>
                {formText}
              </Typography>
            </div>
            {!modifyingUser ? (
              <>
                <div className={style.section}>
                  <div className={style.sectionTitle}>
                    Select a Healthwise Employee
                  </div>

                  <div
                    className={`${style.itemGrid} ${style.rowField} ${
                      values?.selectUser?.id
                        ? style.itemGridRowFieldPopulated
                        : ''
                    }`}
                  >
                    <SingleSelectInput
                      name="selectUser"
                      placeholder="Select one"
                      variant="outlined"
                      optionsList={delegatedUserOptions}
                      disabled={
                        !delegatedUserOptions || !delegatedUserOptions.length
                      }
                      touched={touched['selectUser']}
                      handleChange={setFieldValue}
                      handleBlur={setFieldTouched}
                      error={errors['selectUser']}
                      errorMsg={errors['selectUser']?.id}
                      helpMsg=""
                      selectedValue={values?.selectUser}
                      disableCloseOnSelect={false}
                      disableClearable={true}
                    />
                  </div>
                  <Divider className={style.divider} />
                </div>
              </>
            ) : (
              ''
            )}
            <>
              <div className={style.section}>
                <div className={style.sectionTitle}>Assign Roles</div>
                <div className={style.sectionDescription}>
                  Roles control access to applications and should be granted
                  with caution.
                </div>
                <div className={style.itemGrid}>
                  <ApplicationSelector allowMultiple={true} {...props} />
                </div>
              </div>
            </>
            <div className={style.buttonContainer}>
              <TextButton
                onClick={handleClose}
                disabled={isSubmitting}
                buttonText="Cancel"
              ></TextButton>
              <PrimaryButton
                variant="contained"
                type="submit"
                disabled={!(isValid && dirty) || isSubmitting}
                buttonText={modifyingUser ? 'Save Changes' : 'Grant Access'}
                className={style.submitButton}
              ></PrimaryButton>
            </div>
          </Form>
        </Panel>
      </Container>
    </>
  );
};

export default DelegatedAccessAddNewUser;
