import React from 'react';
import { Field } from 'formik';
import { Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';

import { BaselineTextField } from 'components/textInputV2/textInput';

export const SingleSelectInput = React.forwardRef((props, ref) => {
  const {
    handleBlur,
    handleChange,
    handleOptionSelected = (option, value) => {
      return option?.id === value?.id;
    },
    name,
    optionsList,
    label,
    placeholder,
    selectedValue = null,
    arialabelledby,
    ariadescribedby,
    renderInputCard = params => {
      return (
        <BaselineTextField
          {...params}
          ref={ref}
          externalLabel={props.externalLabel}
          label={label}
          placeholder={placeholder}
          fullWidth={fullWidth}
          variant={props.variant}
          arialabelledby={arialabelledby}
          ariadescribedby={ariadescribedby}
        />
      );
    },
    renderOptionLabel = option => {
      return option?.name ? option.name : '';
    },
    disableClearable = false,
    disableCloseOnSelect = true,
    disabled = false,
    freeSolo = false,
    filterSelectedOptions = true,
    fullWidth = true,
    selectBlur = false,
    size = 'small',
  } = props;

  const wrapBlur = () => {
    handleBlur(name, true);
  };

  const wrapChange = (event, value, eventName) => {
    handleChange(name, value);
  };

  //Verify that the selected value actually contains a value
  const value =
    selectedValue &&
    Object.keys(selectedValue).length !== 0 &&
    selectedValue.id !== ''
      ? selectedValue
      : null;

  return (
    <>
      <Field
        blurOnSelect={selectBlur}
        component={Autocomplete}
        name={name}
        options={optionsList}
        value={value}
        onBlur={wrapBlur}
        onChange={wrapChange}
        getOptionLabel={renderOptionLabel}
        isOptionEqualToValue={handleOptionSelected}
        disableClearable={disableClearable}
        disableCloseOnSelect={disableCloseOnSelect}
        placement="bottom"
        disabled={disabled}
        filterSelectedOptions={filterSelectedOptions}
        fullWidth={fullWidth}
        freeSolo={freeSolo}
        renderInput={renderInputCard}
        size={size}
        data-testid={name}
      />
    </>
  );
});

SingleSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  touch: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  errorMsg: PropTypes.string,
  helpMsg: PropTypes.string.isRequired,
};
