import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '@mui/material';
import { TextButton, PrimaryButton } from 'components/buttons/button';

import style from './delegatedUserCard.module.scss';

const DelegatedUserCard = ({
  delegate,
  returnAllCounts,
  handleModalOpen,
  path,
}) => {
  let history = useHistory();

  const totalCounts = returnAllCounts(delegate.subscriptions);
  const { apps, roles } = totalCounts;

  const openEditAccess = e =>
    history.push({
      pathname: `${path}/edit`,
      search: window.location.search,
      state: {
        userId: delegate.userId,
      },
    });
  const openRevokeAccessModal = e => handleModalOpen(delegate);
  const appText = apps === 1 ? 'application' : 'applications';
  const roleText = roles === 1 ? 'role' : 'roles';

  return (
    <>
      <Card
        role="region"
        aria-labelledby={`region-heading${delegate.userId}`}
        className={style.userCard}
      >
        <div className={`${style.cardHeaderContainer}`}>
          <div>
            <h2
              id={`region-heading${delegate.userId}`}
              className={style.cardHeader}
            >
              {delegate.fullName}
            </h2>
            <div
              className={style.cardSubHeader}
            >{`${apps} ${appText}, ${roles} ${roleText}`}</div>
          </div>
          <div className={style.btnContainer}>
            <TextButton
              onClick={() => openRevokeAccessModal()}
              aria-label={`Revoke delegated access for ${delegate.fullName}`}
              buttonText="Revoke Access"
            />
            <div className={style.btnWrapper}>
              <PrimaryButton
                handleClick={openEditAccess}
                buttonText={`Edit Access`}
                aria-label={`Edit delegated access for ${delegate.fullName}`}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default DelegatedUserCard;
