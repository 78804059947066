import * as configuration from 'utils/configuration/organizations';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;

const tenantUrlV2 = baseUrl + configuration.TENANT_ROOT_V2;
const tenantsUrl = baseUrl + configuration.TENANTS_ROOT;

export async function getTenantApplications(subscriptionId, accessToken) {
  return await utils.getResource(
    `${tenantUrlV2}/${subscriptionId}/${configuration.TENANT_AVAILABLE_APPLICATIONS}`,
    accessToken,
  );
}

/**
 * Endpoints for an oganization's notes.
 * @param {guid} tenantId The ID of the tenant to lookup
 * @param {long} tenantNotesId The ID of the tenant to lookup
 * @param {string} accessToken The access token of the user attempting the lookup
 * @param {string} skip Number of records to skip
 * @param {string} top Number of records to return
 */

export async function getTenantNotes(
  skip = 0,
  top = 100,
  tenantId,
  accessToken = null,
) {
  return await utils.getPaginatedResult(
    `${tenantsUrl}/${tenantId}/notes`,
    skip,
    top,
    accessToken,
    '',
    '',
    'asc',
  );
}

export async function createTenantNote(tenantId, payload, accessToken) {
  return await utils.createResource(
    `${tenantsUrl}/${tenantId}/notes`,
    payload,
    accessToken,
  );
}

export async function updateTenantNote(
  tenantId,
  tenantNotesId,
  payload,
  etag,
  accessToken,
) {
  return await utils.putResource(
    `${tenantsUrl}/${tenantId}/notes/${tenantNotesId}`,
    payload,
    accessToken,
    etag,
  );
}

export async function deleteTenantNote(
  tenantId,
  tenantNotesId,
  etag,
  accessToken,
) {
  return await utils.deleteResource(
    `${tenantsUrl}/${tenantId}/notes/${tenantNotesId}`,
    etag,
    accessToken,
  );
}
