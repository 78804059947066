import React from 'react';
import { CircularProgress } from '@mui/material';

/**
 * Renders a styled circular progress indicator.
 * @param {number} size The size of the indicator, defaults to '24'
 */
export const ProgressIndicator = props => {
  const { size = 24 } = props;
  return (
    <CircularProgress
      size={size}
      style={{ color: '#003a47', padding: '12px' }}
    />
  );
};
