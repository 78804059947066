import React from 'react';

import { AuthContext } from 'contexts/authContext';
import { NotificationContext } from 'contexts/notificationContext';
import FeatureList from 'components/featureList/featureList';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/dialog/dialog';
import { HorizontalRule } from 'components/horizontalRule/horizontalRule';
import { CloseIconButton } from 'components/buttons/button';

import style from './featuresModal.module.scss';

const FeaturesModal = ({ open, setOpen }) => {
  const { accessToken } = React.useContext(AuthContext);
  const { showNotification } = React.useContext(NotificationContext);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      role="dialog"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      open={open}
      hidden={!open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      scroll={'body'}
      transitionDuration={500}
      data-testid="featuresDialog"
    >
      <CloseIconButton onClick={handleClose} className={style.closeButton} />
      <DialogTitle id="dialog-title">Feature Toggles</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          Enable or disable feature toggles
        </DialogContentText>
        <div className={style.list}>
          <FeatureList />
        </div>
        <HorizontalRule />
        <div>Access Token:
          <ul>
            <li><a href={`https://jwt.ms/#access_token=${accessToken}`} target="_blank" rel="noreferrer">Decode on jwt.ms</a></li>
            <li><button onClick={() => { navigator.clipboard.writeText(accessToken); showNotification('Access token copied!'); }}>Copy to clipboard</button></li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FeaturesModal;
