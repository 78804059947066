import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from 'components/dialog/dialog';

import { PrimaryButton, GeneralCancelButton } from 'components/buttons/button';
import {
  BaselineTextField,
  BaselineTextInput,
} from 'components/textInputV2/textInput';

import { useAddFocus } from 'utils/hooks/useAddFocus';
import useAddAriaLiveRegion from 'utils/hooks/common/useAddAriaLiveRegion';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { searchUsers } from 'utils/api/tenantAdmin';
import { AuthContext } from 'contexts/authContext';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';
import style from './createTenantAdministratorModal.module.scss';
import AccountLifespanFields from 'components/users/accountLifespanFields/accountLifespanFields';
import { getMinimumEndDate } from 'containers/users/user';
import { isBefore, startOfToday } from 'date-fns';
import isEmail from 'validator/lib/isEmail';

const CreateTenantAdministratorModal = props => {
  const [open, setOpen] = useState(false);

  const {
    values: { startDate, endDate },
    initialValues,
    handleReset,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    tenantId,
    modalbuttontext,
    closeModal,
  } = props;

  const [searchEmail, setSearchEmail] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [email, setEmail] = React.useState('');
  const { accessToken } = useContext(AuthContext);
  const [submitting, setSubmitting] = useState(false);
  const [displayWarningText, setDisplayWarningText] = useState(false);
  const [inactiveUserDisplayWarningText, setInactiveUserDisplayWarningText] =
    useState(false);
  const [displayErrorText, setDisplayErrorText] = useState(false);
  const [userStatus, setUserStatus] = React.useState([]);
  const [isValidEmail, setValidEmail] = useState(true);
  const [emailError, setEmailError] = useState('');

  useAddAriaLiveRegion('createOrgAdminModalEmailError', emailError);

  const resetDisplayMessages = () => {
    setDisplayErrorText(false);
    setDisplayWarningText(false);
    setInactiveUserDisplayWarningText(false);
    setValidEmail(true);
    setEmailError('');
  };

  const internalConfirmationHandler = () => {
    setSubmitting(true);
  };

  const inputRef = useRef(null);
  useAddFocus(inputRef, open);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (handleReset) {
      handleReset();
    }
    setOpen(false);
    setSearchResults([]);
    setSearchEmail('');
    setUsers([]);
    setEmail('');
    resetDisplayMessages();
  };

  const fetchData = useMemo(
    () =>
      debounce(async value => {
        let { response, data } = await searchUsers(
          tenantId,
          0,
          20,
          accessToken,
          value,
        );
        if (response?.ok && data) {
          let userInfo = data.reduce(
            (a, x) => ({
              ...a,
              [x.email + ' - ' + x.firstName + ' ' + x.lastName]: {
                email: x.email,
                firstName: x.firstName,
                lastName: x.lastName,
                startDate: x.startDate,
                endDate: x.endDate,
                status: x.status,
                isAdminForOrg:
                  x.roles.filter(role => {
                    return (
                      role.referenceId.includes(tenantId) &&
                      role.referenceId.includes('TenantAdministrator')
                    );
                  }).length > 0
                    ? true
                    : false,
              },
            }),
            {},
          );
          setUsers(userInfo);

          let emails = Object.keys(userInfo);
          setSearchResults(emails);
        }
      }, 200),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (searchEmail.length > 0) {
      fetchData(searchEmail);
    } else {
      resetDisplayMessages();
      setSearchResults([]);
    }
  }, [searchEmail, fetchData]);

  useEffect(() => {
    closeModal.current = { close: false };
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal]);

  const setDateField = fieldName => {
    return newValue => {
      if (!newValue || (newValue instanceof Date && !isNaN(newValue))) {
        setFieldValue(fieldName, newValue, true);
      } else {
        setFieldError(fieldName, 'Invalid Date');
      }

      setFieldTouched(fieldName, true, false);
    };
  };

  const isValidDates = (startDate, endDate) => {
    if (
      startDate !== null &&
      startDate !== '' &&
      endDate !== null &&
      endDate !== ''
    ) {
      if (isBefore(Date.parse(startDate), Date.parse(endDate))) {
        return true;
      } else {
        return false;
      }
    } else if (endDate === null || endDate === '') {
      return true;
    }
  };

  const setAddAdminBtnDisabled = (values, displayErrorText) => {
    if (
      values.email !== '' &&
      values.firstName !== '' &&
      values.lastName !== '' &&
      values.startDate !== null &&
      values.startDate !== '' &&
      isValidDates(values.startDate, values.endDate) &&
      !displayErrorText
    ) {
      return false;
    }
    return true;
  };

  const setStartDate = setDateField('startDate');
  const setEndDate = setDateField('endDate');
  const addAdminBtnDisabled = setAddAdminBtnDisabled(
    props.values,
    displayErrorText,
  );

  const handleBlur = () => {
    resetDisplayMessages();
    if (users) {
      let user = Object.values(users);
      const email = document.getElementById('emailAddress');
      if (user.length > 0 && isEmail(email.value)) {
        setEmail(user[0].email);
        setFieldValue('email', user[0].email);
        setFieldValue('firstName', user[0].firstName);
        setFieldValue('lastName', user[0].lastName);
        setFieldValue(
          'startDate',
          user[0].status !== 'SUSPENDED' ? user[0].startDate : startOfToday(),
          true,
        );
        setFieldValue(
          'endDate',
          user[0].status !== 'SUSPENDED' && user[0].endDate
            ? user[0].endDate
            : null,
          true,
        );
        setUserStatus(user[0].status);

        if (user[0].status === 'SUSPENDED' && !user[0].isAdminForOrg) {
          setInactiveUserDisplayWarningText(true);
          setEmailError(
            "This user's account is Inactive in this organization's database. Changes made will update this user's account status and permissions.",
          );
        } else if (!user[0].isAdminForOrg) {
          setDisplayWarningText(true);
          setEmailError(
            "This user already exists in this organization's database. Changes made will update this user's permissions.",
          );
        } else {
          setDisplayErrorText(true);
          setEmailError(
            'This user is already an Administrator. Please make permission updates in their profile.',
          );
        }

        setTimeout(() => {
          setFieldTouched('email');
          setFieldTouched('firstName');
          setFieldTouched('lastName');
        }, 100);
      } else {
        setDisplayErrorText(false);
        setDisplayWarningText(false);
        setInactiveUserDisplayWarningText(false);
        const validEmail = email.value.length > 0 ? isEmail(email.value) : true;
        setValidEmail(validEmail);
        !validEmail && setEmailError('Invalid Email Address');
      }
    }
  };

  const handleOnChange = newValue => {
    setDisplayErrorText(false);
    setDisplayWarningText(false);
    setInactiveUserDisplayWarningText(false);
    const user = users[newValue];
    if (user) {
      setEmail(user.email);
      setFieldValue('email', user.email);
      setFieldValue('firstName', user.firstName);
      setFieldValue('lastName', user.lastName);
      setFieldValue(
        'startDate',
        user.status !== 'SUSPENDED' ? user.startDate : startOfToday(),
        true,
      );
      setFieldValue(
        'endDate',
        user.status !== 'SUSPENDED' && user.endDate ? user.endDate : null,
        true,
      );
      setUserStatus(user.status);

      setTimeout(() => {
        setFieldTouched('email');
        setFieldTouched('firstName');
        setFieldTouched('lastName');
      }, 100);

      if (user.status === 'SUSPENDED' && !user.isAdminForOrg) {
        setInactiveUserDisplayWarningText(true);
        setEmailError(
          "This user's account is Inactive in this organization's database. Changes made will update this user's account status and permissions.",
        );
      } else if (!user.isAdminForOrg) {
        setDisplayWarningText(true);
        setEmailError(
          "This user already exists in this organization's database. Changes made will update this user's permissions.",
        );
      } else {
        setDisplayErrorText(true);
        setEmailError(
          'This user is already an Administrator. Please make permission updates in their profile.',
        );
      }
    }
  };
  return (
    <>
      <PrimaryButton
        buttonText={modalbuttontext}
        key="add"
        handleClick={handleOpen}
        className={style.createButton}
      />

      <Dialog
        role="dialog"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        open={open}
        hidden={!open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
        data-testid="addAdminDialog"
      >
        <Form>
          <DialogTitle id="dialog-title">Add New Administrator</DialogTitle>

          <DialogContent className={style.dialogContent}>
            <DialogContentText id="dialog-description">
              Organization Administrators have access to all applications,
              subscriptions and have the ability to manage users and
              permissions. All fields are required unless marked as optional.
            </DialogContentText>
            <Autocomplete
              freeSolo
              value={email}
              filterOptions={x => x}
              inputValue={searchEmail}
              onChange={(event, newValue) => {
                handleOnChange(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue !== email || newInputValue === '') {
                  //check if something changed and reset the fields and error messages
                  resetDisplayMessages();
                  setFieldValue('firstName', '');
                  setFieldValue('lastName', '');
                  setUserStatus(null);
                }
                setFieldValue('email', newInputValue);
                setSearchEmail(newInputValue);
              }}
              onBlur={handleBlur}
              id="emailAddress"
              options={searchResults}
              renderInput={params => (
                <BaselineTextField
                  {...params}
                  externalLabel="Email Address"
                  name="emailAddress"
                  id="emailAddress"
                  className={style.textField}
                  inputRef={inputRef}
                />
              )}
            />
            {!isValidEmail ||
            inactiveUserDisplayWarningText ||
            displayWarningText ||
            displayErrorText ? (
              <span
                className={
                  !isValidEmail
                    ? style.invalidEmailBanner
                    : displayErrorText
                    ? style.errorBanner
                    : style.warningBanner
                }
              >
                {emailError}
              </span>
            ) : null}
            <div className={style.nameInputs}>
              <span>
                <BaselineTextInput
                  id="firstName"
                  externalLabel="First Name"
                  errorlabel="First Name"
                  name="firstName"
                  className={style.firstNameInput}
                />
              </span>
              <span>
                <BaselineTextInput
                  id="lastName"
                  externalLabel="Last Name"
                  errorlabel="Last Name"
                  name="lastName"
                  className={style.lastNameInput}
                />
              </span>
            </div>
            <div className={style.lifespan}>
              <AccountLifespanFields
                className={style.fieldRow}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                status={userStatus}
                minimumEndDate={
                  getMinimumEndDate ? getMinimumEndDate(startDate).date : null
                }
                initialValues={initialValues}
                textProps={{
                  variant: 'standard',
                }}
                modalType="orgAdmin"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <GeneralCancelButton onClick={handleClose} disabled={isSubmitting}>
              Cancel
            </GeneralCancelButton>
            <PrimaryButton
              variant="contained"
              type="submit"
              disabled={addAdminBtnDisabled}
              buttonText="Add New Administrator"
              handleClick={() => {
                internalConfirmationHandler();
              }}
            >
              {submitting ? <ProgressIndicator /> : null}
            </PrimaryButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

CreateTenantAdministratorModal.propTypes = {
  secondary: PropTypes.bool,
  modalbuttontext: PropTypes.string.isRequired,
};

export default CreateTenantAdministratorModal;
