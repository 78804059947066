import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Padding from 'components/padding/padding';
import SalesforceDetailPanel from './salesforceDetailPanel';
import EditableOrganizationHeader from './editableOrganizationHeader';
import { OrgCard, StyledCardContent } from 'components/card/card';
import { SubscriptionPanel } from 'components/organizationManagement/subscriptionPanel/subscriptionPanel';
import InternalOrganizationNotesPanel from 'components/organizationManagement/internalOrganizationNotesPanel/internalOrganizationNotesPanel.js';
import { HeaderWithItemListSkeleton } from 'components/skeletons/skeletons';

// TODO THIS RENDERS _WAY_ TO MANY TIMES
const OrganizationManagementEditOrganization = props => {
  const {
    organizationToEdit = {},
    organizationEtag,
    entitlementData = {},
    subscriptionTypesData,
    renderSubscriptionDetailPanel,
    renderOrganizationNotes,
    renderDeleteOrganization,
    renderDeleteSaveOrganizationNotes,
  } = props;

  const { entitlementPacketLoading = true, entitlementPacket = {} } =
    entitlementData;

  const { organizationToEditLoading = true, organization = {} } =
    organizationToEdit;

  const productionSubscriptionsLimit = entitlementPacket?.tenantLimit ?? 0;

  const {
    canProvision,
    organizationId,
    organizationName,
    friendlyId,
    alias,
    organizationCategoryId,
  } = organization;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EditableOrganizationHeader
        organizationId={organizationId}
        organizationName={organizationName}
        friendlyId={friendlyId}
        alias={alias}
        organizationCategoryId={organizationCategoryId}
        renderDeleteOrganization={() =>
          renderDeleteOrganization({ organizationId })
        }
      />
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={12}>
              {entitlementPacketLoading || organizationToEditLoading ? (
                <Padding>
                  <HeaderWithItemListSkeleton />
                </Padding>
              ) : (
                <SalesforceDetailPanel
                  organizationToEdit={organization}
                  organizationEtag={organizationEtag}
                  entitlementData={entitlementPacket}
                />
              )}
              {organizationToEditLoading || canProvision ? (
                <>
                  <SubscriptionPanel
                    organization={organizationToEdit}
                    subscriptionTypesData={subscriptionTypesData}
                    productionSubscriptionsLimit={productionSubscriptionsLimit}
                    readOnly={false}
                    renderSubscriptionDetailPanel={
                      renderSubscriptionDetailPanel
                    }
                  />
                </>
              ) : (
                <OrgCard>
                  <StyledCardContent>
                    Entitlement Packet is incomplete, some functionality is
                    unavailable
                  </StyledCardContent>
                </OrgCard>
              )}
              <InternalOrganizationNotesPanel
                organizationToEditLoading={organizationToEditLoading}
                organization={organization}
                organizationEtag={organizationEtag}
                props={props}
                renderDeleteSaveOrganizationNotes={
                  renderDeleteSaveOrganizationNotes
                }
                renderOrganizationNotes={renderOrganizationNotes}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

OrganizationManagementEditOrganization.propTypes = {
  organizationToEdit: PropTypes.object,
  subscriptionTypesData: PropTypes.object.isRequired,
  renderSubscriptionDetailPanel: PropTypes.func.isRequired,
  renderDeleteOrganization: PropTypes.func.isRequired,
  renderDeleteSaveOrganizationNotes: PropTypes.func.isRequired,
};

export default OrganizationManagementEditOrganization;
