import {
  SUBSCRIPTIONS,
  AUTH_SUBSCRIPTIONS,
  SUBSCRIPTIONS_API_VERSION,
} from 'utils/configuration/subscriptions';
import { ORGANIZATIONS_ROOT } from 'utils/configuration/organizations';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;
const baseQueryString = `?${SUBSCRIPTIONS_API_VERSION}`;

const organizationsUrl = baseUrl + ORGANIZATIONS_ROOT;

export async function createSubscription(
  organizationId,
  subscription,
  accessToken,
) {
  return await utils.createResource(
    `${organizationsUrl}/${organizationId}/${SUBSCRIPTIONS}`,
    subscription,
    accessToken,
  );
}

export async function updateSubscription(
  organizationId,
  subscriptionId,
  subscription,
  subEtag,
  accessToken,
) {
  const { response, etag, data } = await utils.updateResource(
    `${organizationsUrl}/${organizationId}/${SUBSCRIPTIONS}/${subscriptionId}`,
    subscription,
    subEtag,
    accessToken,
  );

  return { response, etag, data };
}

export async function getSubscription(
  organizationId,
  subscriptionId,
  accessToken = null,
) {
  return await utils.getResource(
    `${organizationsUrl}/${organizationId}/${SUBSCRIPTIONS}/${subscriptionId}`,
    accessToken,
  );
}

//TODO: Returning all subscriptions, since pagination hasn't been implement in the IU
export async function getSubscriptions(
  skip = 0,
  top = 50,
  organizationId,
  accessToken = null,
  includeManagementSubscriptions = false,
) {
  return await utils.getPaginatedResult(
    `${organizationsUrl}/${organizationId}/${SUBSCRIPTIONS}`,
    skip,
    top,
    accessToken,
    '',
    '',
    'asc',
    { includeManagementSubscriptions: includeManagementSubscriptions },
  );
}

/**
 * Retrieve a mapping of an organization's subscriptions and applications
 */
export async function getAllUserSubscriptions(accessToken) {
  return await utils.getResource(
    `${baseUrl}${AUTH_SUBSCRIPTIONS}/all${baseQueryString}`,
    accessToken,
  );
}

export async function deleteSubscription(
  organizationId,
  subscriptionId,
  accessToken,
  etag,
) {
  return await utils.deleteResource(
    `${organizationsUrl}/${organizationId}/${SUBSCRIPTIONS}/${subscriptionId}`,
    etag,
    accessToken,
  );
}
