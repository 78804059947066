import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { FieldArray } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { TextButton } from 'components/buttons/button';
import { ApplicationSelectorRow } from 'components/applicationSelectorRow/applicationSelectorRow';
import { useReferenceSubscriptions } from 'contexts/referenceSubscriptionsContext';
import { AddIcon } from 'components/icons/icons';

import style from './applicationSelector.module.scss';

// Get a set of callbacks to pass to individual ApplicationSelectorRow components
// without needing to pass the Formik FieldArray arrayHelper all the way down
const getRowHandlers = (arrayHelper, handleRowChange, handleRowRemoved) => {
  // Handle changes in the selected roles
  const roleChangeHandler = (value, subscriptionId, applicationId, row) => {
    let selectedRoles = arrayHelper.form.values.selectedRoles;

    // Save off all of the roles that don't belong to our app.
    let keptRoles = selectedRoles.filter(role => {
      return (
        subscriptionId !== role.subscriptionId &&
        applicationId !== role.applicationId
      );
    });

    // Expand the roles for our app.
    keptRoles = [...keptRoles, ...value];

    arrayHelper.form.setFieldValue('selectedRoles', keptRoles, true);
    row.roles = value;

    handleRowChange(row);
  };

  // Handle changes in the selected application
  const applicationChangeHandler = (value, row, subscriptionId) => {
    // If the application changes, we need to deselect any selected roles
    roleChangeHandler([], subscriptionId, row.application?.id, row);

    row.application = value;
    row.applicationId = value?.id;
    row.roles = [];
    handleRowChange(row);
  };

  const rowRemovedHandler = (row, subscriptionId) => {
    if (row.application?.id) {
      roleChangeHandler([], subscriptionId, row.application.id, row);
    }

    handleRowRemoved(row);
  };

  return {
    roleChangeHandler,
    applicationChangeHandler,
    rowRemovedHandler,
  };
};

// Take the raw application/role objects and
// nest the application ids through the
// object tree. This will make handling them easier.
const getApplicationOptions = (subscriptionId, applications) => {
  if (!applications) {
    return [];
  }

  return applications?.map(app => {
    var { roles, ...appFields } = app;

    return {
      ...appFields,
      subscriptionId: subscriptionId,
      roles: roles?.map(role => {
        const { id, name, value, description } = role;
        return {
          id,
          name,
          value,
          subscriptionId: subscriptionId,
          applicationId: appFields.id,
          description,
        };
      }),
    };
  });
};

export const ApplicationSelector = props => {
  const { referenceSubscriptions } = useReferenceSubscriptions();

  const { subscriptionId, applications } = referenceSubscriptions;
  const applicationOptions = React.useMemo(
    () => getApplicationOptions(subscriptionId, applications),
    [subscriptionId, applications],
  );
  const [rows, setRows] = React.useState([]);
  const [allRows, setAllRows] = React.useState(false);
  const [hasAppliedFormikValues, setHasAppliedFormikValues] =
    React.useState(false);

  const {
    allowMultiple = true,
    values: { selectedRoles },
    className,
    setAllRowsComplete,
    dirty,
    readOnly = false,
  } = props;

  useEffect(() => {
    if (readOnly) {
      const tempRows = buildRows(
        selectedRoles,
        applicationOptions,
        subscriptionId,
      );
      setRows(tempRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly]);

  // Prepopulate the "rows" value if "selectedRoles" is populated
  useEffect(() => {
    if (
      hasAppliedFormikValues ||
      dirty ||
      !selectedRoles ||
      selectedRoles.length < 1 ||
      !applicationOptions ||
      applicationOptions.length < 1
    ) {
      return;
    }

    // Group the roles by subscription and application
    const tempRows = buildRows(
      selectedRoles,
      applicationOptions,
      subscriptionId,
    );

    setRows(tempRows);
    setHasAppliedFormikValues(true);
  }, [
    selectedRoles,
    dirty,
    applicationOptions,
    hasAppliedFormikValues,
    subscriptionId,
  ]);

  useEffect(() => {
    // Only allow a new row to be added if all existing rows have at least 1 role selected
    const allRowsComplete = rows.reduce(
      (previous, current) => previous && current.roles?.length > 0,
      true,
    );

    setAllRows(allRowsComplete);

    if (setAllRowsComplete) {
      setAllRowsComplete(allRowsComplete);
    }
  }, [rows, setAllRowsComplete]);

  const handleRowChange = row => {
    if (!row?.id) {
      // new row
      row.id = uuidv4();
      rows.push(row);
    } else {
      const index = rows.findIndex(r => r.id === row.id);
      if (index >= 0) {
        rows[index] = row;
      } else {
        rows.push(row);
      }
    }

    setRows([...rows]);
  };

  const handleRowRemoved = row => {
    setRows(rows.filter(i => i.id !== row.id) || []);
  };

  // Don't allow a new row to be added if there are no unused subscription + application combinations
  const canAddRow = applicationOptions.reduce((previousApp, app) => {
    // Loop through the usedRows and check if one matches the current sub + app
    for (let i = 0; i < rows.length; i++) {
      const currentRow = rows[i];

      if (currentRow.applicationId === app.id) {
        // Found a match
        return previousApp || false;
      }
    }

    // If we're returning true here, it means we didn't find this sub + app in the usedRows,
    // so we should be able to add a new row to the list
    return true;
  }, false);

  if (rows.length < 1) {
    handleRowChange({});
    return null;
  }

  return (
    <>
      <Grid className={`${style.addApplicationSection} ${className}`} container>
        <FieldArray
          name="subscriptions"
          render={arrayHelper => {
            const callbacks = {
              ...getRowHandlers(arrayHelper, handleRowChange, handleRowRemoved),
            };

            if (arrayHelper?.form?.values) {
              return (
                <>
                  {rows.map((row, index, { length: arrayLength }) => {
                    // Filter this row's options to remove subscriptions + application combinations already used by other rows
                    const subscriptionFilteredOptions =
                      applicationOptions.filter(app => {
                        // Loop through all the rows and check if they already have used this sub + app combination
                        for (let i = 0; i < rows.length; i++) {
                          const currentRow = rows[i];

                          // If this subscription and application have been used by another row,
                          // filter the application from this subscription's application array.
                          if (
                            currentRow.id !== row.id &&
                            currentRow.applicationId === app.id
                          ) {
                            return false;
                          }
                        }
                        return true;
                      });

                    return (
                      <React.Fragment key={row.id}>
                        {readOnly && index === 0 ? (
                          <>
                            <Grid
                              item
                              xs={6}
                              className={`${style.columnFirst} ${style.columnLabel}`}
                            >
                              Application
                            </Grid>
                            <Grid item className={style.columnLabel}>
                              Application Roles
                            </Grid>
                          </>
                        ) : (
                          ''
                        )}

                        <ApplicationSelectorRow
                          applicationOptions={subscriptionFilteredOptions}
                          row={row}
                          index={row.id}
                          selectedApplication={row.application}
                          selectedRoles={row.roles}
                          {...callbacks}
                          {...props}
                        />
                      </React.Fragment>
                    );
                  })}
                  {!readOnly && rows.length > 0 ? (
                    allowMultiple && allRows && canAddRow ? (
                      <>
                        <TextButton
                          onClick={() => {
                            handleRowChange({});
                          }}
                          buttonText=" Add another application"
                          startIcon={<AddIcon />}
                        />
                      </>
                    ) : null
                  ) : null}
                </>
              );
            } else {
              return null;
            }
          }}
        />
      </Grid>
    </>
  );
};

export default ApplicationSelector;

function buildRows(selectedRoles, applicationOptions, subscriptionId) {
  const groups = selectedRoles.reduce((groups, item) => {
    const groupKey = `${item.subscriptionId}::${item.applicationId}`;
    const group = groups[groupKey] || {
      subscriptionId: item.subscriptionId,
      applicationId: item.applicationId,
      roles: [],
    };
    group.roles.push(item);
    groups[groupKey] = group;
    return groups;
  }, {});

  // Assemble row objects based on the groups above
  const tempRows = Object.keys(groups)
    .map(groupName => {
      const group = groups[groupName];

      // Get a matching application from the valid options
      const application = applicationOptions.find(
        app => app.id === group.applicationId,
      );

      // If no matching application is found, exit
      if (!application) {
        return undefined;
      }

      // Get the matching roles from the valid options
      const roles = application.roles?.filter(appRole => {
        return group.roles.findIndex(gr => gr.id === appRole.id) >= 0;
      });

      // If no matching roles are found, exit
      if (roles.length < 1) {
        return undefined;
      }

      // Create a row object
      return {
        id: uuidv4(),
        subscriptionId: subscriptionId,
        application,
        applicationId: application?.id,
        roles,
      };
    })
    .filter(item => !!item);
  return tempRows;
}
