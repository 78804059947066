import * as Yup from 'yup';

// TODO: https://healthwise.atlassian.net/browse/P40-670 - identity and only
//  populate the minimum viable _required_ payload for the create organization
//  endpoint.
const createOrganizationSchemaDefinition = {
  organizationName: Yup.string()
    .max(120, 'Must be 120 characters or less')
    .required('Required'),
  primarySalesforceAccount: Yup.object()
    .shape({
      id: Yup.string().required('Missing Salesforce Account ID'),
      name: Yup.string().required('Missing Salesforce Account Name'),
      organizationTypeId: Yup.number().required('Missing organizationTypeId'),
      archetypeId: Yup.number().required('Missing archetypeId'),
      channel: Yup.string(),
      organizationCategoryId: Yup.number().required(
        'Missing Organization Category',
      ),
    })
    .required('Missing Salesforce Account Information'),
  organizationCategoryId: Yup.number().required(
    'Missing Organization Category',
  ),
  friendlyId: Yup.string()
    .strict()
    .required()
    .min(1)
    .max(15)
    .matches(
      /^[a-z]/,
      'Unique Content Identifier must start with a lowercase letter',
    )
    .matches(
      /^[a-z0-9_]+$/,
      'Unique Content Identifier must only contain lowercase letters, numbers, and underscores',
    )
    .matches(
      /^[a-z][a-z0-9_]{0,14}$/g,
      'Unique Content Identifier is not in the correct format',
    )
    .label('Unique Content Identifier'),
};

export const getCustomValidationSchema = schemaObject => {
  return Yup.object().shape(schemaObject);
};

// TODO: https://healthwise.atlassian.net/browse/P40-670 - identity and only
//  populate the minimum viable _required_ payload for the create organization
//  endpoint.
export const CreateOrganizationSchema = getCustomValidationSchema(
  createOrganizationSchemaDefinition,
);

export const FormInitialValues = {
  organizationName: '',
  primarySalesforceAccount: {},
  organizationCategoryId: '',
  friendlyId: '',
};
