import React from 'react';
import { FieldArray } from 'formik';
import { List, ListItem } from '@mui/material';

import { AddIconButton, TrashIconButton } from 'components/buttons/button';
import { BaselineTextInput } from 'components/textInputV2/textInput';

/*
 * Display a list of redirect url's that the application will accept.
 */
const EditRedirects = props => {
  const { setFieldValue, values } = props;

  // Before adding, we should probably validate that the value is a valid URL.
  const addRedirect = (event, redirect, arrayHelpers) => {
    arrayHelpers.push(redirect);
    setFieldValue('newRedirectUrl', '');
  };

  return (
    <FieldArray
      name="redirectUrls"
      render={arrayHelpers => (
        <List>
          {values.redirectUrls.map((redirectUrl, index) => (
            <ListItem key={'list' + index}>
              <BaselineTextInput
                key={'input' + index}
                name={redirectUrl}
                value={redirectUrl}
              />
              <TrashIconButton
                key={'remove' + index}
                onClick={() => arrayHelpers.remove(index)}
                aria-label="Remove Redirect URI"
              />
            </ListItem>
          ))}
          <ListItem key="newRedirectItemUrl">
            <BaselineTextInput
              key="newRedirectItem"
              name="newRedirectUrl"
              value={values.newRedirectUrl}
            />
            <AddIconButton
              title="Add Redirect"
              onClick={event => {
                addRedirect(event, values.newRedirectUrl, arrayHelpers);
              }}
            />
          </ListItem>
        </List>
      )}
    />
  );
};

export default EditRedirects;
