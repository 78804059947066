import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, useLocation, matchPath } from 'react-router-dom';
import { useAuth0Data, PostLoginRedirector } from "contexts/auth0Context";  

import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { AuthProvider } from 'contexts/authContext';
import Health from 'pages/health/health';
import { HEALTH_PATH } from 'utils/configuration/links';
import { REACT_APP_DEV_TOOLS } from 'utils/dictionary/env';
import { Auth0Provider, useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import queryString from 'query-string';
import * as FeatureToggleDictionary from 'utils/dictionary/featureToggles';
import { FeatureToggleProvider } from 'react-feature-toggles';
import App from 'containers/app';
import { ReactQueryDevtools } from 'react-query/devtools';
import ErrorBoundaryFallback from 'components/errors/errorBoundaryFallback/errorBoundaryFallback';

const ShowDevTools = REACT_APP_DEV_TOOLS ?? false;

export const ProtectedRoute = ({
    component,
  }) => {
    const Component = React.useMemo(() => withAuthenticationRequired(component), [component]);
  
    return <Component />;
};

const SecurityBoundary = () => {
    const location = useLocation();
    const queryClient = new QueryClient();
    const [toggles, setToggles] = useState();
    // this is saying anytime App is accessed, we make sure we're authenticated
    const { error, isLoading } = useAuth0();

    useEffect(() => {
      let parsedFeatures =
        queryString.parse(location.search)?.features?.split(',') ?? [];
  
      if (parsedFeatures.length > 0) {
        if (parsedFeatures.includes('off')) {
          sessionStorage.removeItem('features');
        } else {
          sessionStorage.setItem('features', JSON.stringify(parsedFeatures));
        }
      }
      parsedFeatures = JSON.parse(sessionStorage.getItem('features')) ?? [];
  
      setToggles(
        Object.values(FeatureToggleDictionary).reduce((toggles, value) => {
          // Only set the toggle if the parsedFeature value is found in the
          // FeatureToggleDictionary
          toggles[value] = toggles[value] || parsedFeatures.includes(value);
          return toggles;
        }, {}),
      );
    }, [location.search, setToggles]);
  
    useEffect(() => {
      //scroll to id if there is A hash
      if (location.hash !== '') {
        setTimeout(() => {
          const id = location.hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 0);
      }
    }, [location.pathname, location.hash, location.key]); // do this on route change
  
    // Without this if statement, the setToggles() call in the useEffect() method
    // will cause the application to rerender multiple times.
    // This caused a duplicate authentication error, before we switched to Auth0
    // I'm leaving it in because I'm scared
    // Please leave this in place until that issue is resolved.
    if (!toggles) {
      return null;
    }
    
    if (isLoading) {
      return (
        <>
          Loading Provisioning Manager...
        </>
      );
    }
  
    if (error) {
      console.log(error.message);
      throw new Error(error.message);
    }
  
    return (
      <QueryClientProvider client={queryClient}>
        <FeatureToggleProvider featureToggleList={toggles}>
          <App />
          {ShowDevTools ? <ReactQueryDevtools initialIsOpen={false} /> : null}
        </FeatureToggleProvider>
      </QueryClientProvider>
    );
};

export const Auth0App = () => {
    const auth0Data = useAuth0Data();
    const [ organizationAlias, setOrganizationAlias ] = useState();
    
    if (!auth0Data || auth0Data.isLoading) return <ProgressIndicator />

    return (
      <Auth0Provider {...auth0Data.auth0Config}>
        <BrowserRouter basename={ organizationAlias }>
          <Switch>
            <Route exact path={HEALTH_PATH} component={Health} />
            <AuthProvider>
                <PostLoginRedirector setOrgAlias={setOrganizationAlias}/>
                <Route path="*" render={ props => {
                    const errorMatch = matchPath(props.location.pathname + props.location.search, {
                      path: "/?error=:error&error_description=:error_description&:others"
                    });

                    if(errorMatch) {
                      const errorMessage = decodeURI(errorMatch.params.error);
                      const errorDescription = decodeURI(errorMatch.params.error_description);

                      if(errorMessage.toLowerCase() === "unauthorized" || errorDescription.toLowerCase() === "user is blocked") {
                        return (<ErrorBoundaryFallback error={new Error("Unable to sign in")} />);
                      }

                      return (<ErrorBoundaryFallback error={new Error(`${errorMessage}, ${errorDescription}`)} />);
                    }

                    return (<ProtectedRoute component={SecurityBoundary} />)
                  }}>
                </Route>
            </AuthProvider>
          </Switch>
        </BrowserRouter>
      </Auth0Provider>
    );
};

export default Auth0App;