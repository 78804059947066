import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import {
  Container,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { RoundedToggleButtonGroup } from 'components/buttons/button';
import { SearchIcon } from 'components/icons/icons';
import OrganizationsListPanel from 'pages/organizations/components/organizationListPanel/organizationListPanel';
import { ListSkeleton } from 'components/skeletons/skeletons';
import PageHeader from 'components/pageHeader/pageHeader';
import { useUpdateDocumentTitle } from 'utils/hooks/common/useUpdateDocumentTitle';
import style from './organizationManagementList.module.scss';
import { DOC_TITLE_ORG_DASHBOARD } from 'utils/dictionary/overview';
import { BaselineTextField } from 'components/textInputV2/textInput';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

// AJC TODO: Need pagination (or display all orgs for now)
const OrganizationManagementList = ({
  bannerButton,
  listOfOrganizations,
  organizationsRetrieved,
  organizationCategories,
}) => {
  // TODO:
  // A) Insert Text Field into the header to reflect UI design
  // Z) Implement pagination and on the fly filtering

  const [toggleCategories, setToggleCategories] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [orgSearch, setOrgSearch] = useState('');

  useEffect(() => {
    let filteredOrgsArray = listOfOrganizations.filter(org => {
      for (let category of toggleCategories) {
        if (org.organizationCategory === category) {
          return true;
        }
      }
      return false;
    });

    if (orgSearch !== '') {
      const list =
        toggleCategories.length > 0 ? filteredOrgsArray : listOfOrganizations;
      filteredOrgsArray = list.filter(org =>
        org.organizationName.toLowerCase().includes(orgSearch),
      );
    }

    setFilteredOrganizations(filteredOrgsArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCategories, orgSearch, listOfOrganizations]);

  const handleCategoryChange = (event, newCategories) => {
    setToggleCategories(newCategories);
  };

  const handleSearchQuery = event => {
    const search = event.target.value.toLowerCase();
    setOrgSearch(search);
  };

  useUpdateDocumentTitle(DOC_TITLE_ORG_DASHBOARD);

  return (
    <>
      <PageHeader
        title="Organization Dashboard"
        secondary={
          'Choose an existing organization to manage or create a new organization.'
        }
      />
      <Container maxWidth="lg">
        <div className={style.filterContainer}>
          <div className={style.filterBy}>
            <Typography className={style.filterLabel}>Filter by</Typography>

            <RoundedToggleButtonGroup
              value={toggleCategories}
              onChange={handleCategoryChange}
              aria-label="Filter organizations by category"
            >
              {organizationCategories.map(category => (
                <ToggleButton
                  className={style.orgCategoryButton}
                  key={category.organizationCategoryId}
                  value={category.name}
                  aria-label={category.name}
                >
                  {toggleCategories.includes(category.name) ? (
                    <CheckIcon fontSize="small" className={style.check} />
                  ) : (
                    ''
                  )}
                  {category.name}
                </ToggleButton>
              ))}
            </RoundedToggleButtonGroup>
          </div>
          <div className={style.searchContainer}>
            <BaselineTextField
              InputProps={{
                type: 'text',
                startAdornment: (
                  <InputAdornment
                    className={style.inputAdornment}
                    position="end"
                  >
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: orgSearch && (
                  <IconButton onClick={() => setOrgSearch('')}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              value={orgSearch}
              placeholder="Search"
              onChange={handleSearchQuery}
              className={style.searchField}
            />
          </div>
          {bannerButton}
        </div>

        {organizationsRetrieved ? (
          <OrganizationsListPanel
            organizations={
              toggleCategories.length === 0 && orgSearch === ''
                ? listOfOrganizations
                : filteredOrganizations
            }
          />
        ) : (
          <>
            <ListSkeleton />
            <ListSkeleton />
            <ListSkeleton />
          </>
        )}
      </Container>
    </>
  );
};

OrganizationManagementList.propTypes = {
  bannerButton: PropTypes.node.isRequired,
  listOfOrganizations: PropTypes.array.isRequired,
};

export default OrganizationManagementList;
