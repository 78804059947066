import React, { useState, useEffect, Fragment } from 'react';
import { Typography } from '@mui/material';
import { Form } from 'formik';
import { PrimaryButton } from 'components/buttons/button';
import ApplicationSelection from 'components/organizationManagement/modifySubscriptionProductsModal/applicationSelection';
import style from './modifySubscription.module.scss';
import { StyledCardV2, StyledCardContentV2 } from 'components/card/card';
import SingleSetting from 'components/singleSetting/singleSetting';

const ApplicationSection = props => {
  const [checked, setChecked] = useState({});

  const {
    selectedApplications,
    filteredApplications,
    setFieldValue,
    setFieldTouched,
    setRedoxError,
  } = props;

  useEffect(() => {
    Object.keys(selectedApplications).forEach(id => {
      setChecked(currentState => {
        return {
          ...currentState,
          [id]: { checked: true },
        };
      });
    });
  }, [setChecked, selectedApplications]);

  return (
    <>
      {filteredApplications.map((app, index) => {
        return (
          <Fragment key={index}>
            {
              <ApplicationSelection
                {...props}
                selectedApplications={selectedApplications}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                application={app}
                setChecked={setChecked}
                checked={checked}
                name="applications"
                setRedoxError={setRedoxError}
              />
            }
          </Fragment>
        );
      })}
    </>
  );
};

const ModifySubscription = props => {
  const {
    isValid,
    isSubmitting,
    subscription,
    errors,
    touched,
    subscriptionOptions,
    settingList,
    setRedoxError,
  } = props;

  return (
    <>
      <Form noValidate>
        <StyledCardV2>
          <StyledCardContentV2>
            <div>
              <div className={style.sectionTitle}>Tenant-Level Settings</div>
              <div className={style.subSectionText}>
                The following settings affect all applications within a tenant
              </div>
              <div className={style.settingContainer}>
                {subscriptionOptions?.map((opt, index) => {
                  const setting = settingList.filter(
                    x => x.settingId === opt.settingId,
                  )[0];
                  return (
                    <SingleSetting
                      {...props}
                      subscriptionId={subscription.subscriptionId}
                      field="setting"
                      option={opt}
                      setting={setting}
                      key={index}
                    ></SingleSetting>
                  );
                })}
              </div>
            </div>
          </StyledCardContentV2>
        </StyledCardV2>
        <StyledCardV2>
          <StyledCardContentV2>
            <div>
              <div className={style.sectionTitle}>
                Applications and Settings
              </div>

              <div className={style.subSectionText}>
                A tenant must include at least 1 application. Select an
                application to make it available to the client. Expand an
                application to include identifiers.
              </div>
              {
                <ApplicationSection
                  {...props}
                  setRedoxError={setRedoxError}
                  filteredApplications={props.availableApplications}
                />
              }
            </div>
            <div className={style.overrideActions}>
              {errors.applicationCredentialId ? (
                <div className={style.errorMessage}>
                  Please enter Redox ID to continue.
                </div>
              ) : null}
              {errors.settings ? (
                <div className={style.errorMessage}>{errors.settings}</div>
              ) : null}
              {errors.applications && touched.applications ? (
                <Typography className={style.errorMessage}>
                  {errors.applications.application}
                </Typography>
              ) : null}
              <PrimaryButton
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting}
                buttonText="Save Changes"
              ></PrimaryButton>
            </div>
          </StyledCardContentV2>
        </StyledCardV2>
      </Form>
    </>
  );
};

export default ModifySubscription;
