import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { IconButton, Card, CardContent } from '@mui/material';
import * as Yup from 'yup';
import { AuthContext } from 'contexts/authContext';
import { EditIcon } from 'components/icons/icons';
import {
  TextButton,
  PrimaryButton,
  PrimaryButtonLink,
} from 'components/buttons/button';
import Notification from 'components/notificationV2/notification';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import Tooltip from '@mui/material/Tooltip';
import * as OVERVIEW from 'utils/dictionary/overview';
import { getOrganization, updateOrganization } from 'utils/api/organizations';
import style from './editableOrganizationHeader.module.scss';
import btnStyle from 'components/buttons/button.module.scss';
import DebugValueWithClipboard from 'components/debugValueWithClipboard/debugValueWithClipboard';

const RenameAccountButtonLink = props => {
  const { setEditable } = props;
  return (
    <IconButton
      color="primary"
      aria-label="rename organization"
      onClick={() => {
        setEditable(true);
      }}
    >
      <EditIcon />
    </IconButton>
  );
};

async function UpdateOrganizationName(values, accessToken) {
  let { id, name } = values;

  let { etag, response } = await getOrganization(id, accessToken);

  if (response.ok) {
    let body = {
      OrganizationName: name,
    };

    let updatedResponse = await updateOrganization(id, body, etag, accessToken);
    // TODO: Adding in the ? while the appservice isn't updated yet
    if (!updatedResponse?.response?.ok) {
      throw new Error(`Unable to rename the organization to ${name}`);
    }
  } else {
    throw new Error(`Unable to retrieve the organization ${id}`);
  }
}

const EditableHeader = props => {
  const { title, id, setError, setSuccess, setEditable, setOrganizationName } =
    props;
  const { accessToken } = useContext(AuthContext);

  const SubmitNameChange = async values => {
    if (values.name !== '' && values.name !== title) {
      try {
        await UpdateOrganizationName(values, accessToken);
        setOrganizationName(values.name);
        setEditable(false);
        setSuccess('Saved organization name');
      } catch (error) {
        setOrganizationName(title);
        setError(error.message);
      }
    }
  };

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    name: Yup.string().required('An organization name is Required'),
  });

  return (
    <Formik
      initialValues={{ id: id, name: title }}
      onSubmit={SubmitNameChange}
      validationSchema={DisplayingErrorMessagesSchema}
    >
      {formikProps => {
        return (
          <Form>
            <div className={style.wrapper}>
              <BaselineTextInput
                type="text"
                name="name"
                className={style.textField}
                isRequiredErrorMessage={false}
                hideValidationAdornment={true}
              />
              {formikProps.isSubmitting ? (
                <ProgressIndicator />
              ) : (
                <>
                  <div className={style.btnContainer}>
                    {!formikProps.dirty ? (
                      <Tooltip
                        title="There are no changes to save"
                        aria-describedby="There are no changes to save"
                        placement="top"
                        arrow
                      >
                        <span>
                          <PrimaryButton buttonText="Rename Organization" />
                        </span>
                      </Tooltip>
                    ) : (
                      <PrimaryButton
                        buttonText="Rename Organization"
                        type="submit"
                      />
                    )}
                    <div className={style.btnWrapper}>
                      <TextButton
                        buttonText="Cancel"
                        handleClick={() => {
                          setEditable(false);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const OrganizationHeader = props => {
  const { title, id, setEditable } = props;
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [subId, setSubId] = useState('');
  const { adminOrgs } = useContext(AuthContext);

  useEffect(() => {
    if (adminOrgs) {
      const orgAdmin = adminOrgs.filter(org => org.organizationId === id);
      if (orgAdmin.length === 1) {
        setIsOrgAdmin(true);
        setSubId(orgAdmin[0].subscriptionId);
      }
    }
  }, [adminOrgs, id]);

  return (
    <>
      <div className={style.cardContentItem}>
        <h1 className={style.title}>{title}</h1>
        {OVERVIEW.HEALTHWISE_ORG !== title && (
          <RenameAccountButtonLink
            id={id}
            name={title}
            setEditable={setEditable}
          />
        )}
        {isOrgAdmin && (
          <PrimaryButtonLink
            to={`${OVERVIEW.EXTERNAL_RELOAD_LOGIN_PATH}?subscription_id=${subId}`}
            className={`${btnStyle.button} ${btnStyle.outlineButtonGreen} ${style.orgAdminBtn}`}
          >
            View as Organization Administrator
          </PrimaryButtonLink>
        )}
      </div>
    </>
  );
};

const EditableOrganizationHeader = props => {
  const {
    organizationId,
    organizationName,
    friendlyId,
    alias,
    organizationCategoryId,
  } = props;
  const [editableHeader, setEditableHeader] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState(organizationName);

  useEffect(() => {
    setName(organizationName);
  }, [organizationName]);

  return (
    <>
      {error ? <Notification error message={error} /> : null}
      {success ? <Notification message={success} /> : null}
      <Card className={style.card}>
        <CardContent className={style.cardContent}>
          {editableHeader ? (
            <EditableHeader
              title={name}
              id={organizationId}
              setEditable={setEditableHeader}
              setOrganizationName={setName}
              setError={setError}
              setSuccess={setSuccess}
            />
          ) : (
            <OrganizationHeader
              title={name}
              id={organizationId}
              setEditable={setEditableHeader}
            />
          )}
          <div className={`${style.cardContentItem} ${style.itemGap}`}>
            {organizationCategoryId === 2 ? (
              <span className={`${style.status} ${style.demo}`}>DEMO</span>
            ) : organizationCategoryId === 1 ? (
              <span className={`${style.status} ${style.internal}`}>
                INTERNAL
              </span>
            ) : organizationCategoryId === 3 ? (
              <span className={`${style.status} ${style.client}`}>CLIENT</span>
            ) : (
              ''
            )}
          </div>
          {friendlyId ? (
            <div
              className={`${style.cardContentItem} ${
                alias ? style.itemGapSmall : style.itemGap
              }`}
            >
              <span className={style.rowTitle}>Content Identifier</span>
              <span className={style.data}>{friendlyId}</span>
            </div>
          ) : null}
          {alias ? (
            <div className={`${style.cardContentItem} ${style.itemGap}`}>
              <span className={style.rowTitle}>Alias</span>
              <span className={style.data}>{alias}</span>
            </div>
          ) : null}
          <DebugValueWithClipboard
            className={`${style.cardContentItem} ${style.itemGap}`}
            value={organizationId}
            titleClassName={style.rowTitle}
            valueClassName={style.data}
            title="Organization ID"
          />
        </CardContent>
      </Card>
    </>
  );
};

export default EditableOrganizationHeader;
