import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as MaterialLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import style from './breadcrumb.module.scss';

const Breadcrumb = ({ items }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {items.map((item, index) =>
        item.to !== undefined ? (
          <MaterialLink
            key={index}
            className={style.link}
            component={RouterLink}
            to={item.to}
          >
            <span>{item.text}</span>
          </MaterialLink>
        ) : item.text !== 'undefined' ? (
          <span key={index} className={style.current}>
            {item.text}
          </span>
        ) : (
          ''
        ),
      )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
