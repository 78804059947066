import React from 'react';
import { NoteSkeleton } from 'components/skeletons/skeletons';
import ToolTip from 'components/tooltipV2/tooltip';
import { useGetOrganizationNotes } from 'utils/hooks/organization/useGetOrganizationNotes';
import EditOrganizationNotes from 'containers/organizationManagement/editOrganizationNotes/editOrganizationNotes';
import SavedNotes from 'components/savedNotes/savedNotes';
import style from './internalOrganizationNotesPanel.module.scss';

const InternalOrganizationNotesPanel = ({
  organizationToEditLoading,
  organization,
  renderDeleteSaveOrganizationNotes,
  renderOrganizationNotes,
}) => { 
  const { data } = useGetOrganizationNotes(organization?.organizationId);

  return (
    <div className={style.internalNotesPanelContainer}>
      {organizationToEditLoading ? (
        <div className={style.noteSkeletonContainer}>
          <NoteSkeleton />
        </div>
      ) : (
        <>
          <div className={style.headingWrapper}>
            <h2 className={style.internalNotesHeading}>Internal Notes</h2>
            <ToolTip 
              buttonSize="small"
              title="These notes are NOT visible to the client."
            />
          </div>
          <div className={style.notesContainer}>
            <>
              {renderOrganizationNotes({
                organizationToEdit: organization,
              })}
            </>
            {data?.map((note, index) => {
              return (
                <div key={note.organizationNotesId} className={style.noteCard}>
                  <SavedNotes
                    index={index}
                    note={note}
                    renderDeleteSavedNotes={props =>
                      renderDeleteSaveOrganizationNotes({
                        ...props,
                        currentNote: note,
                      })
                    }
                    renderEditNotes={(props, editNotes, handleClick) =>{
                      return (
                        <EditOrganizationNotes {...props} editNotes={editNotes} handleClick={handleClick} />
                      )
                    }}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default InternalOrganizationNotesPanel;
