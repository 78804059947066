/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Chip } from '@mui/material';

import DebugValueWithClipboard from 'components/debugValueWithClipboard/debugValueWithClipboard';
import { ArrowForwardIconButton } from 'components/buttons/button';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import style from './organization.module.scss';

const chipColors = {
  client: '#FFE1D6',
  demo: '#FFF1D1',
  internal: 'rgba(0, 162, 199, 0.20)',
};

const borderColors = {
  client: '#FFE1D6',
  demo: '#FFF1D1',
  internal: 'transparent',
};

const OrgCategoryPill = props => {
  return (
    <Chip
      className={style.chip}
      style={{
        border: `1px solid ${borderColors[props.pillcolor]}`,
        background: chipColors[props.pillcolor],
      }}
      {...props}
    />
  );
};

const handleViewOrganizationClick = (history, props) => {
  history.push({
    pathname: EDIT_ORGANIZATION_PATH,
    search: window.location.search,
    state: {
      organizationId: props.organizationId,
    },
  });
};

const Organization = props => {
  let {
    organizationName,
    organizationCategory,
    organizationId,
    salesforceReferences,
  } = props;

  const sfAccountId = salesforceReferences[0].accountId;

  const history = useHistory();

  return (
    <>
      <div className={style.orgContainer}>
        <div className={style.orgInformation}>
          <p className={style.orgNameText}>{organizationName}</p>
          <OrgCategoryPill
            pillcolor={organizationCategory.toLowerCase()}
            label={organizationCategory.toUpperCase()}
            size="small"
            variant="outlined"
          />
          <DebugValueWithClipboard
            className={style.orgInformation}
            value={organizationId}
            title="Org ID"
          />
          <DebugValueWithClipboard
            className={style.orgInformation}
            value={sfAccountId}
            title="SF ACCT ID"
          />
        </div>
        <div className={style.orgActions}>
          <ArrowForwardIconButton
            onClick={() => handleViewOrganizationClick(history, props)}
          ></ArrowForwardIconButton>
        </div>
      </div>
    </>
  );
};

Organization.defaultProps = {
  orgName: 'ERROR NAME',
};

Organization.propTypes = {
  orgName: PropTypes.string.isRequired,
};

export default Organization;
