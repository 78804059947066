import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Notification from 'components/notificationV2/notification';

export const NotificationContext = React.createContext();

export const NotificationProvider = ({ children }) => {
  const [n, setNotification] = React.useState();

  const showNotification = React.useCallback(
    (message, isError = false, duration = 5000, messageKey = undefined) => {
      setNotification({
        message,
        isError,
        duration,
        messageKey: messageKey || uuidv4(),
      });
    },
    [setNotification],
  );

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {n ? (
        <Notification
          duration={n.duration}
          messageKey={n.messageKey}
          message={n.message}
          error={n.isError ? true : null}
        />
      ) : null}
      {children}
    </NotificationContext.Provider>
  );
};
