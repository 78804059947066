import { get, post, patch, put, _delete } from 'utils/http';

export async function getPaginatedResult(
  url,
  skip,
  top,
  accessToken,
  search = '',
  orderByField = '',
  orderByDirection = 'asc',
  query = {},
) {
  let resourceLocation = `${url}?skip=${skip}&top=${top}&search=${search}&orderByField=${orderByField}&orderByDirection=${orderByDirection}`;

  Object.keys(query).forEach(
    key => (resourceLocation = `${resourceLocation}&${key}=${query[key]}`),
  );

  const { response, json, error } = await get(resourceLocation, accessToken);

  const pagination = json?.meta?.pagination || {
    returned: 0,
    available: 0,
    skip: 0,
    top: 10,
  };

  pagination.page = pagination.skip / pagination.top + 1;

  const nextPage =
    response?.status === 200 && pagination.available > top + skip
      ? async () => {
          return await getPaginatedResult(
            url,
            skip + pagination.returned,
            top,
            accessToken,
          );
        }
      : null;

  return {
    response,
    data: response?.status === 200 ? json.data : null,
    pagination: pagination,
    nextPage: nextPage,
    error: response?.status === 200 ? null : error || json.data,
  };
}

export async function getResource(url, accessToken) {
  const { response, json, error } = await get(url, accessToken);

  return {
    response,
    data: response?.status === 200 ? json : null,
    etag: response?.headers?.get('etag'),
    error: response?.status === 200 ? null : error || json,
  };
}

export async function createResource(url, body, accessToken) {
  const { response, json, error } = await post(url, body, accessToken);

  return {
    response,
    data: response?.status === 201 ? json : null,
    error: response?.status === 201 ? null : error || json,
  };
}

export async function postWithContent(url, body, accessToken) {
  const { response, json, error } = await post(url, body, accessToken);

  return {
    response,
    data: response?.status === 200 ? json : null,
    error: response?.status === 200 ? null : error || json,
  };
}

export async function postWithoutContent(url, body, accessToken) {
  const { response, json, error } = await post(url, body, accessToken);

  return {
    response,
    data: response?.status === 204 ? json : null,
    error: response?.status === 204 ? null : error,
  };
}

export async function updateResource(url, body, etag, accessToken) {
  const { response, json, error } = await patch(url, body, etag, accessToken);

  return {
    response,
    data: response?.status === 200 ? json : null,
    error: response?.status === 200 ? null : error || json,
    etag: response?.headers?.get('etag'),
  };
}

// NOTE: Watch the order of the parameters, they've been switched
//       in comparison to the other methods.
// SEE: https://healthwise.atlassian.net/browse/P40-488
export async function putResource(url, body, accessToken, etag) {
  const { response, json, error } = await put(url, body, accessToken, etag);

  return {
    response,
    data: response?.status === 200 ? json : null,
    error: response?.status === 200 ? null : error || json,
  };
}

export async function deleteResource(url, etag, accessToken) {
  const { response, json, error } = await _delete(url, etag, accessToken);

  return {
    response,
    data: response?.status === 200 ? json : null,
    error: response?.status === 200 ? null : error || json,
  };
}
