export const INPUT_TYPE_SWITCH = 1;
export const INPUT_TYPE_CHECKBOX = 2;
export const INPUT_TYPE_RADIOGROUP = 3;
export const INPUT_TYPE_SELECT = 4;
export const INPUT_TYPE_TEXTBOX = 5;
export const INPUT_TYPE_CHECKBOXSWITCH = 6;

export const SUBSCRIPTION = 1;
export const ADVISEINTERNAL = 2;
export const ADVISECLIENT = 3;
