import React from 'react';
import { Grid } from '@mui/material';

import { TextButton } from 'components/buttons/button';
import { ApplicationRoleSelectorSelectInput } from 'components/multipleSelectInputV2/multipleSelectInput';
import { SingleSelectInput } from 'components/singleSelectInputV2/singleSelectInput';

import style from './applicationSelectorRow.module.scss';

export const ApplicationSelectorRow = React.forwardRef((props, ref) => {
  const {
    selectedApplication,
    selectedRoles,
    touched,
    errors,
    setFieldTouched,
    applicationChangeHandler,
    roleChangeHandler,
    rowRemovedHandler,
    row,
    subscriptionId,
    applicationOptions,
    readOnly = false,
  } = props;

  const [selectedApplicationInState, setSelectedApplication] =
    React.useState(selectedApplication);
  const [selectedRolesInState, setSelectedRoles] = React.useState(
    selectedRoles || [],
  );

  const handleApplicationSelected = value => {
    if (
      value &&
      (!selectedApplicationInState || value?.id !== selectedRolesInState?.id)
    ) {
      resetRoles();

      setSelectedApplication(value);
      applicationChangeHandler(value, row, subscriptionId);
    }
  };

  const resetRoles = () => {
    if (selectedRolesInState?.length > 0) {
      handleRoleChanged([], selectedApplicationInState.id);
    }
  };

  const handleApplicationRemoved = () => {
    rowRemovedHandler(row, subscriptionId);
  };

  const handleRoleChanged = (value, subscriptionId, applicationId) => {
    setSelectedRoles(value);
    roleChangeHandler(value, subscriptionId, applicationId, row);
  };

  const renderApplication = () => {
    return !readOnly ? (
      <>
        <Grid
          item
          xs={6}
          className={`${style.rowField} ${
            selectedApplicationInState ? style.rowFieldPopulated : ''
          }`}
        >
          <SingleSelectInput
            name="applicationSelect"
            placeholder={selectedApplicationInState ? null : 'Select one'}
            externalLabel="Application"
            variant="outlined"
            optionsList={applicationOptions || []}
            handleChange={(_, value) => {
              handleApplicationSelected(value);
            }}
            handleBlur={setFieldTouched}
            touched={touched['applicationSelect']}
            errorMsg={errors['applicationSelect']}
            selectBlur={false}
            disableCloseOnSelect={false}
            selectedValue={selectedApplicationInState}
            disableClearable={true}
            helpMsg=""
          />
          {selectedRolesInState.length > 0 ? renderRemove() : ''}
        </Grid>
      </>
    ) : (
      <Grid item xs={6} className={`${style.rowField} ${style.name}`}>
        <div>{selectedApplication?.name}</div>
      </Grid>
    );
  };

  const renderRoles = () => {
    return !readOnly ? (
      selectedApplicationInState?.id ? (
        <Grid
          name="subSelect"
          item
          className={`${style.rowField} ${
            selectedRolesInState.length > 0 ? style.rowFieldPopulated : ''
          }`}
        >
          <ApplicationRoleSelectorSelectInput
            className={style.rowField}
            placeholder={
              selectedRolesInState && selectedRolesInState.length > 0
                ? null
                : 'Roles'
            }
            externalLabel="Application Roles"
            handleRoleChanged={handleRoleChanged}
            selectName="roleSelect"
            errors={errors}
            row={row}
            value={selectedRolesInState || []}
            optionsList={selectedApplicationInState?.roles || []}
            subscriptionId={subscriptionId}
            applicationId={selectedApplicationInState?.id}
          />
        </Grid>
      ) : (
        ''
      )
    ) : (
      <Grid item xs={6} className={style.rowField}>
        {selectedRoles?.map(item => (
          <div className={style.viewRoleRow}>
            <div className={style.name}>{item.name}</div>
            <div className={style.description}>{item.description}</div>
          </div>
        ))}
      </Grid>
    );
  };

  const renderRemove = () => {
    let ariaText;
    if (selectedApplication?.name && selectedRoles.length > 0) {
      let roles = [];
      selectedRoles.forEach(role => roles.push(role.name));
      ariaText = `Remove ${
        selectedApplication?.name
      } application with the following roles: ${roles.join(', ')}.`;
    } else if (selectedApplication?.name) {
      ariaText = `Remove ${selectedApplication?.name} application.`;
    }

    return (
      <Grid item container>
        <Grid item>
          <TextButton
            className={style.remove}
            onClick={() => handleApplicationRemoved()}
            aria-label={ariaText}
            buttonText="Remove application"
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container className={style.rowContainer} data-testid="fieldRow">
        {renderApplication()}
        {renderRoles()}
      </Grid>
    </>
  );
});
