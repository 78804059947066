import React, { useState, useContext } from 'react';
import { TimeAndDateFormatReadable } from 'utils/timeAndDate';
import { DEFAULT_BTN_COLOR_CONFIRM_RED } from 'utils/dictionary/overview';
import { TrashIconButton } from 'components/buttons/button';
import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import { AuthContext } from 'contexts/authContext';
import { DeleteIcon } from 'components/icons/icons';
import { NotificationContext } from 'contexts/notificationContext';
import { deleteTenantNote } from 'utils/api/tenant';
import { v4 as uuidv4 } from 'uuid';

const DeleteTenantNotes = props => {
  const [open, setOpen] = useState(false);
  const { accessToken } = useContext(AuthContext);

  const { showNotification } = React.useContext(NotificationContext);

  const {
    subscriptionEtag,
    setTriggerRerender,
    firstName,
    lastName,
    currentNote: { tenantId, tenantNotesId, updatedDate },
  } = props;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteTenantNotes = async () => {
    const { response } = await deleteTenantNote(
      tenantId,
      tenantNotesId,
      subscriptionEtag,
      accessToken,
    );

    if (response?.status === 204) {
      handleClose();
      showNotification('Note deleted successfully.', false, 5000);
      setTriggerRerender(uuidv4());
    } else {
      showNotification(
        'Error deleting note. Please try again or contact your Healthwise developers.',
        true,
        5000,
      );
    }
  };

  const text = (
    <>
      This note from{' '}
      <b>
        {firstName} {lastName}
      </b>{' '}
      posted on {TimeAndDateFormatReadable(new Date(updatedDate + 'Z'))} will be
      removed. This action cannot be undone.
    </>
  );

  return (
    <>
      <TrashIconButton onClick={handleOpen} aria-label="Delete note">
        <DeleteIcon />
      </TrashIconButton>
      <ConfirmationModal
        open={open}
        title="Delete this note?"
        text={text}
        confirmText="Delete Note"
        handleClose={handleClose}
        handleCancel={handleClose}
        handleConfirm={deleteTenantNotes}
        color={DEFAULT_BTN_COLOR_CONFIRM_RED}
      />
    </>
  );
};

export default DeleteTenantNotes;
