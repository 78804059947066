import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import DelegatedAccessAddNewUser from 'components/delegatedAccessUser/delegatedAccessUser';
import Notification from 'components/notificationV2/notification';
import { useDelegatedAccess } from 'contexts/delegatedAccessContext';
import { useReferenceSubscriptions } from 'contexts/referenceSubscriptionsContext';
import { AuthContext } from 'contexts/authContext';
import { putDelegatesCurrent } from 'utils/api/delegates';

const DelegatedAccessAddNewUserSchema = Yup.object().shape({
  selectUser: Yup.object().shape({
    id: Yup.string().required('Select a user'),
  }),
  selectedRoles: Yup.array().min(1, 'Must select at least 1 role'),
});

const AddNewDelegatedUser = props => {
  const {
    availableDelegates,
    currentDelegates,
    setReloadList,
    accessToken,
    organizationId,
  } = useDelegatedAccess();

  // TODO: maybe move this deeper?
  const { referenceSubscriptions } = useReferenceSubscriptions();
  const { organizationAlias } = useContext(AuthContext);

  const [error, setError] = useState(false);
  const [formInitialValues, setSetFormInitialValues] = useState({});
  const history = useHistory();

  useEffect(() => {
    // If we were passed a userId, populate the inital values with that user.
    // Else, nadda!
    // TODO: Un-uglify it.
    const formInitialValues = props?.userId
      ? currentDelegates?.reduce((accumulator, currentValue) => {
          if (currentValue.userId === props?.userId) {
            accumulator = {
              selectUser: {
                id: currentValue.userId,
                value: currentValue.fullName,
                name: currentValue.fullName,
              },
              selectedRoles: currentValue.subscriptions.flatMap(sub =>
                sub.applications.flatMap(app =>
                  app.roles.flatMap(role => {
                    return {
                      id: `${sub.id}::${app.id}::${role.name}`,
                      name: role.name,
                      value: role.name,
                      subscriptionId: `${sub.id}`,
                      applicationId: `${app.id}`,
                    };
                  }),
                ),
              ),
              subscriptions: currentValue.subscriptions.map(sub => {
                return {
                  id: sub.id,
                  name: sub.name,
                  value: sub.name,
                  applications: sub.applications.map(app => {
                    return {
                      id: app.id,
                      name: app?.name,
                      value: app?.name,
                      roles: app.roles.map(role => {
                        return {
                          id: `${sub.id}::${app.id}::${role.name}`,
                          name: role.name,
                          value: role.name,
                        };
                      }),
                    };
                  }),
                };
              }),
            };
          }
          return accumulator;
        }, {})
      : {
          selectUser: {},
          selectedRoles: [],
          subscriptions: [],
          applications: [],
          roles: [],
        };
    setSetFormInitialValues(formInitialValues);
  }, [props?.userId, currentDelegates]);

  /**
   * API call to create/update the delegated user. This does final payload
   * assembly and makes the actual call to the backend service.
   */
  const handleDelegateUser = async ({ selectUser: { id }, selectedRoles }) => {
    const payload = selectedRoles.flatMap(role => {
      return `${organizationId}::${role.id}`;
    });

    const { response, data, error } = await putDelegatesCurrent(
      id,
      payload,
      accessToken,
    );

    return { response, data, error };
  };

  /**
   * Create / Modify delegated user access handler that will be called by formik
   */
  const addNewDelegatedAccessUser = async (
    values,
    { setSubmitting, setStatus },
  ) => {
    try {
      const { response } = await handleDelegateUser(values);

      if (response?.ok) {
        setStatus({ success: true });
        setSubmitting(false);

        setTimeout(() => {
          setReloadList(true);
          history.push({
            pathname: `/${organizationAlias}/delegates`,
            search: window.location.search,
          });
        }, 0);
      } else {
        setStatus({ success: false });
        setSubmitting(false);
        setError(
          'There was an error granting access to this user. Please try again or contact your Healthwise administrator.',
        );
      }
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setError(
        'There was an error granting access error to this user. Please try again or contact your Healthwise administrator.',
      );
    }
  };

  return (
    <>
      {error ? (
        <Notification error message={error} messageKey={uuidv4()} />
      ) : null}
      {availableDelegates === null ? (
        ''
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={DelegatedAccessAddNewUserSchema}
          onSubmit={addNewDelegatedAccessUser}
          children={props => (
            <DelegatedAccessAddNewUser
              {...props}
              delegatedUserOptions={availableDelegates}
              referenceSubscriptions={referenceSubscriptions}
            />
          )}
        />
      )}
    </>
  );
};

export default AddNewDelegatedUser;
