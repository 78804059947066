import { useEffect, useState } from 'react';
import {
  DOC_TITLE_HOME_PAGE,
  DOC_TITLE_MANAGE_USERS,
  DOC_TITLE_ORG_DASHBOARD,
  DOC_TITLE_ORG_OVERVIEW,
  DOC_TITLE_DELEGATED_ACCESS,
  DOC_TITLE_APPLICATIONS,
  MANAGE_USERS_TITLE,
  ORG_DASHBOARD_TITLE,
  ORG_OVERVIEW_TITLE,
  DELEGATED_ACCESS_TITLE,
  APPLICATIONS_TITLE,
  HOME_PAGE_TITLE,
} from 'utils/dictionary/overview';
import useAddAriaLiveRegion from './useAddAriaLiveRegion';

/**
 * Set the document title based on which page are you in
 */
export const useUpdateDocumentTitle = documentID => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (documentID === DOC_TITLE_MANAGE_USERS) {
      setTitle(MANAGE_USERS_TITLE);
    } else if (documentID === DOC_TITLE_ORG_OVERVIEW) {
      setTitle(ORG_OVERVIEW_TITLE);
    } else if (documentID === DOC_TITLE_ORG_DASHBOARD) {
      setTitle(ORG_DASHBOARD_TITLE);
    } else if (documentID === DOC_TITLE_DELEGATED_ACCESS) {
      setTitle(DELEGATED_ACCESS_TITLE);
    } else if (documentID === DOC_TITLE_APPLICATIONS) {
      setTitle(APPLICATIONS_TITLE);
    } else if (documentID === DOC_TITLE_HOME_PAGE) {
      setTitle(HOME_PAGE_TITLE);
    }
  }, [documentID]);
  
  useAddAriaLiveRegion("titleLiveRegion", title)
  document.title = title;
};

export default useUpdateDocumentTitle;
