import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Grid } from '@mui/material';
import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import VerifyModal from 'components/verifyModal/verifyModal';
import {
  SecondaryButton,
  SecondaryDarkButton,
  PrimaryFormSubmitButton,
  DestructiveSecondaryButton,
  PrimaryButton,
  TextButton,
} from 'components/buttons/button';
import { UserCardv2, UserCardContentv2 } from 'components/card/card';
import { USER_MANAGEMENT_PATH } from 'utils/configuration/links';

import Notification from 'components/notificationV2/notification';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';

import RoleAssignment from 'pages/users/modifyUser/components/roleAssignment/roleAssignment';
import { UserDetails as UserDetailsV2 } from 'pages/users/createUser/components/userDetails/userDetails';

import { DAY_MONTH_YEAR } from 'utils/dictionary';
import { FormatISO } from 'utils/timeAndDate';
import ResetPasswordWorkflow from 'components/passwordWorkflows/resetPasswordWorkflow';
import { BackToPrevPageLink } from 'components/users/backToPrevPageLink';
import StatusChip from 'components/users/statusChip/statusChip';
import FeatureToggleHealthwiseOnly from 'components/featureToggleHealthwiseOnly/featureToggleHealthwiseOnly';
import { HorizontalRule } from 'components/horizontalRule/horizontalRule';
import { getMinimumEndDate } from 'containers/users/user';
import { FEATURE_TOGGLE_DEBUG } from 'utils/dictionary/featureToggles';
import { DateFormatReadable, dateFormatter } from 'utils/timeAndDate';
import CardDetails from 'pages/users/createUser/components/cardDetails/cardDetails';
import { getStatusName } from 'utils/statusNames';

import {
  resendInviteUser,
  activateUser,
  deactivateUser,
  deleteUser,
  resync,
} from 'utils/api/users';

import { getDeleteUserProperties } from 'pages/users/modalConfiguration';

import style from './modifyUserForm.module.scss';
import ReactivationModal from 'components/modal/reactivationModal';
import { useIsContentFeedbackEnabled } from 'utils/hooks/common/useIsContentFeedbackEnabled.js';
import PermissionsAssignment from './components/permissionsAssignment/permissionsAssignment';

const UserActionMenu = ({ isSubmitting, userInfo, handleModalAction }) => {
  const allowDeactivation = [
    'ACTIVE',
    'RECOVERY',
    'PROVISIONED',
    'LOCKED_OUT',
    'PASSWORD_EXPIRED',
  ].includes(userInfo.status);

  const allowUnlock = ['LOCKED_OUT'].includes(userInfo.status);

  const allowDelete = ['STAGED', 'SUSPENDED', 'DEPROVISIONED'].includes(
    userInfo.status,
  );

  const handleAction = action => {
    handleModalAction(action, userInfo);
  };

  return (
    <>
      {isSubmitting ? (
        <ProgressIndicator />
      ) : (
        <>
          {allowDelete ? (
            <DestructiveSecondaryButton
              buttonText="Delete User"
              handleClick={() => handleAction('deleteUser')}
            />
          ) : null}
          {allowDelete && <div className={style.buttonDivider} />}
          {allowDeactivation ? (
            <SecondaryDarkButton
              buttonText="Deactivate User"
              handleClick={() => handleAction('deactivateUser')}
            />
          ) : null}
          {allowDeactivation && <div className={style.buttonDivider} />}
          {allowUnlock ? (
            <SecondaryButton
              buttonText="Unlock"
              handleClick={() => handleAction('activateUser')}
            />
          ) : null}
          {allowUnlock && <div className={style.buttonDivider} />}
          <FeatureToggleHealthwiseOnly featureName={FEATURE_TOGGLE_DEBUG}>
            <div className={style.buttonDivider} />
            <PrimaryButton
              buttonText="Resync User from Okta"
              handleClick={() => handleAction('resync')}
            />
          </FeatureToggleHealthwiseOnly>
        </>
      )}
    </>
  );
};

const resyncUser = async (userInfo, handleUserEvent) => {
  const apiInvocation = async () =>
    await resync(userInfo.id, userInfo.accessToken);

  const successMessage = 'User resync was successful.';

  await handleUserEvent(apiInvocation, successMessage);
};

const resendInvitation = async (userInfo, handleUserEvent) => {
  const apiInvocation = async () =>
    await resendInviteUser(userInfo.id, userInfo.accessToken);
  let successMessage = `Invitation emailed to ${userInfo.firstName} ${userInfo.lastName}. This user has 30 days to activate their account.`;
  await handleUserEvent(apiInvocation, successMessage);
};

const deactivate = async ({ id, accessToken }, handleUserEvent) => {
  const apiInvocation = async () => await deactivateUser(id, accessToken);
  await handleUserEvent(apiInvocation, '');
};

const activate = async (
  { id, firstName, lastName, accessToken },
  { startDate, endDate } = {} || null,
  handleUserEvent,
) => {
  const apiInvocation = async () =>
    await activateUser(id, startDate, endDate, accessToken);

  let successMessage = '';

  const today = dateFormatter.format(new Date());
  const newStartDate = dateFormatter.format(new Date(startDate));

  if (today === newStartDate) {
    successMessage = `Invitation emailed to ${firstName} ${lastName}. This user has 30 days to activate their account.`;
  } else {
    successMessage = `Invitation will be emailed to ${firstName} ${lastName} on ${DateFormatReadable(
      Date.parse(startDate),
    )}. This user will have 30 days to activate their account.`;
  }

  await handleUserEvent(apiInvocation, successMessage);
};

const remove = async ({ id, accessToken }, handleUserEvent) => {
  const apiInvocation = async () => await deleteUser(id, accessToken);
  await handleUserEvent(apiInvocation, 'User has been deleted.');
};

const ModifyUserForm = props => {
  const {
    userInfo,
    changeStatus,
    onSubmitUserFields,
    onSubmitUserRoles,
    validationSchemaDefinition,
    getCustomUserValidationSchema,
  } = props;

  const [modalProperties, setModalProperties] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [deleteModalProperties, setDeleteModalProperties] = useState(null);
  const [editUser, setEditUser] = useState(false);
  const [editUserRoles, setEditUserRoles] = useState(false);
  const [allRowsComplete, setAllRowsComplete] = useState(true);
  const [isActivateUser, setActivateUser] = useState(false);
  const { contentFeedbackEnabled } = useIsContentFeedbackEnabled();

  let history = useHistory();
  const { firstName, lastName, status, lastLogin, email, startDate, endDate } =
    props.userInfo;

  const activity = lastLogin ? FormatISO(lastLogin, DAY_MONTH_YEAR) : undefined;

  const formattedStartDate = startDate
    ? FormatISO(startDate, DAY_MONTH_YEAR)
    : undefined;
  const formattedEndDate = endDate
    ? FormatISO(endDate, DAY_MONTH_YEAR)
    : undefined;

  const handleOpenEditUser = () => {
    setEditUser(true);
  };
  const handleCloseEditUser = () => {
    setEditUser(false);
  };

  const handleOpenEditUserRoles = () => {
    setEditUserRoles(true);
  };

  const handleCloseEditUserRoles = () => {
    setEditUserRoles(false);
  };

  const RESENT_INVITATION_ERROR_MESSAGE =
    'Error sending invitation to user. Please try again or contact your Healthwise administrator.';

  const handleModalClose = () => {
    setModalOpen(false);
    setDeleteModelOpen(false);
  };

  const allowReset = ['ACTIVE'].includes(userInfo.status);
  const allowResendInvitation = ['Invite Sent', 'Resend Invite'].includes(
    getStatusName(userInfo.status, userInfo.startDate),
  );
  const allowActivation = ['SUSPENDED', 'DEPROVISIONED'].includes(
    userInfo.status,
  );

  const handleUserEvent = async (asyncApiInvocation, successMessage) => {
    const { error } = await asyncApiInvocation();

    const [message, success] = error
      ? [RESENT_INVITATION_ERROR_MESSAGE, false]
      : [successMessage, true];

    success ? setSuccess(message) : setError(message);

    handleModalClose();
  };

  const redirectToTarget = () => {
    setTimeout(() => {
      history.push({
        pathname: USER_MANAGEMENT_PATH,
        search: window.location.search,
      });
    }, 1000);
  };

  const configureConfirmationModal = (action, userInfo) => {
    let modalProperties;

    switch (action) {
      case 'resync':
        modalProperties = {
          title: 'Resync this user from Okta?',
          text: ' will be resynced from Okta.',
          confirmText: 'Resync',
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          handleConfirm: async () => {
            await resyncUser(userInfo, handleUserEvent);
            redirectToTarget();
          },
        };
        break;

      case 'resendInvitation':
        modalProperties = {
          title: 'Resend invitation to this user?',
          text: ` will be emailed account set up instructions.`,
          confirmText: 'Resend Invitation',
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          handleConfirm: async () =>
            await resendInvitation(userInfo, handleUserEvent),
        };
        setActivateUser(false);
        break;

      case 'deactivateUser':
        modalProperties = {
          title: 'Deactivate this user?',
          text: ` will lose the ability to access all applications.`,
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          confirmText: 'Deactivate User',
          handleConfirm: async () => {
            await deactivate(userInfo, handleUserEvent);
            changeStatus();
          },
        };
        setActivateUser(false);
        break;

      case 'activateUser':
        modalProperties = {
          title: 'Activate this user?',
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          text: ` will be able to access any applications or permissions assigned to them.`,
          confirmText: 'Activate user',
          handleConfirm: async values => {
            await activate(userInfo, values, handleUserEvent);
            changeStatus();
          },
        };
        setActivateUser(true);
        break;

      case 'deleteUser':
        modalProperties = getDeleteUserProperties(userInfo, async () => {
          await remove(userInfo, handleUserEvent);
          redirectToTarget();
        });
        setDeleteModalProperties(modalProperties);
        setDeleteModelOpen(true);
        setActivateUser(false);
        return;

      default:
        modalProperties = {
          title: 'Default Title',
          text: 'Default Text',
          confirmText: 'Default Confirm',
          handleConfirm: () => null,
        };
        break;
    }
    setModalProperties(modalProperties);
    setModalOpen(true);
  };

  const dateValidationSchema = getCustomUserValidationSchema({
    startDate: validationSchemaDefinition.startDate,
    endDate: validationSchemaDefinition.endDate,
  });

  const namesAndDatesValidationSchema = getCustomUserValidationSchema({
    firstName: validationSchemaDefinition.firstName,
    lastName: validationSchemaDefinition.lastName,
    startDate: validationSchemaDefinition.startDate,
    endDate: validationSchemaDefinition.endDate,
  });

  const roleValidationSchema = getCustomUserValidationSchema({
    selectedRoles: validationSchemaDefinition.selectedRoles,
  });

  const reactivateTextProps = {
    dateValidationSchema,
    contentText: `Account activation instructions will be sent to `,
    altText: ' on the start date selected below.',
    dateText: 'Please select a start date for this user to continue.',
  };

  return (
    <section>
      {error ? <Notification error message={error} duration={8000} /> : null}
      {success ? <Notification message={success} /> : null}
      {modalOpen && isActivateUser ? (
        <ReactivationModal
          open={modalOpen}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          {...modalProperties}
          {...reactivateTextProps}
          {...userInfo}
        />
      ) : null}
      {modalOpen && !isActivateUser ? (
        <ConfirmationModal
          open={modalOpen}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          {...modalProperties}
        />
      ) : null}
      {deleteModelOpen ? (
        <VerifyModal
          open={deleteModelOpen}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          {...deleteModalProperties}
        />
      ) : null}
      <BackToPrevPageLink
        text="Back to Manage Users"
        path={USER_MANAGEMENT_PATH}
      />
      <UserCardv2 className={style.userDetailContainer}>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            startDate: userInfo.startDate,
            endDate: userInfo.endDate,
          }}
          validationSchema={namesAndDatesValidationSchema}
          onSubmit={async (values, actions) => {
            const success = await onSubmitUserFields(values, actions);
            if (success) {
              handleCloseEditUser();
            }
          }}
          onReset={() => {
            handleCloseEditUser();
          }}
          children={formikProps => {
            const {
              values: { startDate: startDateForm, endDate: endDateForm },
              setFieldValue,
              setFieldError,
              setFieldTouched,
              initialValues,
              handleReset,
            } = formikProps;

            const setDateField = fieldName => {
              return newValue => {
                if (
                  !newValue ||
                  (newValue instanceof Date && !isNaN(newValue))
                ) {
                  setFieldValue(fieldName, newValue, true);
                } else {
                  setFieldError(fieldName, 'Invalid Date');
                }

                setFieldTouched(fieldName, true, false);
              };
            };

            const setStartDate = setDateField('startDate');
            const setEndDate = setDateField('endDate');

            return (
              <>
                <UserCardContentv2 className={style.userDetail}>
                  {!editUser ? (
                    <>
                      <div className={style.userSection}>
                        <div className={style.userDetails}>
                          <span className={style.userName}>
                            {`${firstName} ${lastName}`}
                          </span>
                        </div>
                        <div className={style.userBtns}>
                          {!editUser ? (
                            <SecondaryButton
                              buttonText="Edit"
                              handleClick={handleOpenEditUser}
                            />
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : (
                    <UserCardContentv2 className={style.editUserContainer}>
                      <Grid container>
                        <Grid item xs>
                          <UserDetailsV2
                            {...userInfo}
                            initialValues={initialValues}
                            createUser={false}
                            startDate={startDateForm}
                            endDate={endDateForm}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            minimumEndDate={
                              getMinimumEndDate
                                ? getMinimumEndDate(startDateForm).date
                                : null
                            }
                          />
                        </Grid>
                        <Grid item xs>
                          <div className={style.editUserBtnContainer}>
                            <TextButton
                              buttonText={`Cancel`}
                              handleClick={handleReset}
                            />
                            <span className={style.itemGap}></span>
                            <PrimaryFormSubmitButton
                              buttonText="Save Changes"
                              {...props}
                              {...formikProps}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </UserCardContentv2>
                  )}

                  <div>
                    {!editUser ? (
                      <div>
                        <StatusChip status={status} startDate={startDate} />
                      </div>
                    ) : null}
                    <div className={style.loginActivity}>
                      {activity ? (
                        <span>
                          Last Login{' '}
                          <span className={style.lastLoginGap}>{activity}</span>
                        </span>
                      ) : null}
                    </div>
                    {!editUser ? (
                      <>
                        <div className={style.startAndEndDate}>
                          {formattedStartDate ? (
                            <span className={style.startDateSpan}>
                              Start Date{' '}
                              <span className={style.startDateGap}>
                                {formattedStartDate}
                              </span>
                            </span>
                          ) : null}
                        </div>
                        <div className={style.startAndEndDate}>
                          {formattedEndDate ? (
                            <span className={style.endDateSpan}>
                              End Date{' '}
                              <span className={style.endDateGap}>
                                {formattedEndDate}
                              </span>
                            </span>
                          ) : null}
                        </div>
                        <div className={style.userEmail}>
                          <span>
                            Email{' '}
                            <span className={style.emailGap}>{`${email}`}</span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className={style.userEmail}>
                        <span>
                          Email{' '}
                          <span className={style.emailGap}>{`${email}`}</span>
                        </span>
                      </div>
                    )}
                  </div>
                  {allowReset ? (
                    <div className={style.resetPassword}>
                      <ResetPasswordWorkflow
                        forOrgAdmin={false}
                        userInfo={userInfo}
                      ></ResetPasswordWorkflow>
                    </div>
                  ) : null}
                  {allowResendInvitation && (
                    <div className={style.buttonDivider} />
                  )}
                  {allowResendInvitation ? (
                    <div className={style.resetPassword}>
                      <SecondaryDarkButton
                        buttonText="Resend Invitation"
                        handleClick={() =>
                          configureConfirmationModal(
                            'resendInvitation',
                            userInfo,
                          )
                        }
                      />
                    </div>
                  ) : null}
                  {allowActivation && <div className={style.buttonDivider} />}
                  {allowActivation ? (
                    <div className={style.resetPassword}>
                      <SecondaryDarkButton
                        buttonText="Activate User"
                        handleClick={() =>
                          configureConfirmationModal('activateUser', userInfo)
                        }
                      />
                    </div>
                  ) : null}
                </UserCardContentv2>
              </>
            );
          }}
        />
      </UserCardv2>

      <UserCardv2 variant="outlined" className={style.roleAssignment}>
        <div className={style.rolesSection}>
          <div className={style.cardDetails}>
            <CardDetails
              primaryHeader="Assigned Roles"
              secondaryHeader="At least 1 application and role must be assigned to a user."
            />
          </div>
          <div className={style.roleBtns}>
            {!editUserRoles ? (
              <SecondaryButton
                buttonText="Edit"
                handleClick={handleOpenEditUserRoles}
              />
            ) : null}
          </div>
        </div>
        <UserCardContentv2 style={{ paddingTop: '0px' }}>
          <Formik
            enableReinitialize
            initialValues={{
              selectedRoles: userInfo.selectedRoles,
              organizationId: userInfo.organizationId,
              tenantId: userInfo.tenantId,
              logService: userInfo.logService,
              contentFeedback: userInfo.contentFeedback,
            }}
            validationSchema={roleValidationSchema}
            onSubmit={onSubmitUserRoles}
            onReset={() => {
              handleCloseEditUserRoles();
            }}
            children={formikProps => (
              <>
                <>
                  <div className={style.submitFormButtonContainer}>
                    {formikProps.isSubmitting ? (
                      <ProgressIndicator />
                    ) : (
                      <>
                        {editUserRoles ? (
                          <>
                            <TextButton
                              onClick={() => {
                                formikProps.resetForm();
                              }}
                              buttonText="Cancel"
                            />
                            <span className={style.itemGap}></span>
                            <PrimaryFormSubmitButton
                              buttonText="Save Changes"
                              {...formikProps}
                              isValid={formikProps.isValid && allRowsComplete}
                            />
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </>

                <RoleAssignment
                  setAllRowsComplete={setAllRowsComplete}
                  readOnly={!editUserRoles}
                  {...props}
                  {...formikProps}
                />

                <HorizontalRule />
                <CardDetails
                  primaryHeader="Assigned Permissions"
                  secondaryHeader=""
                />
                {!editUserRoles ? (
                  <PermissionsAssignment
                    readOnly={true}
                    contentFeedbackEnabled={contentFeedbackEnabled}
                    userHasContentFeedback={userInfo.contentFeedback}
                    userHasLogService={userInfo.logService}
                  />
                ) : (
                  <PermissionsAssignment
                    readOnly={false}
                    contentFeedback={contentFeedbackEnabled}
                    logService={userInfo.logService}
                  />
                )}
              </>
            )}
          />
        </UserCardContentv2>
      </UserCardv2>
      <div className={style.userActionMenu}>
        <UserActionMenu
          {...props}
          {...userInfo}
          handleModalAction={configureConfirmationModal}
        />
      </div>
    </section>
  );
};
export default ModifyUserForm;
