import React, { useState, useContext } from 'react';
 import { OrganizationNotesSchema } from 'components/notesForm/formSchemaAndInitialValues';
import { Formik } from 'formik';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { Redirect } from 'react-router-dom';
import { updateOrganizationNote } from 'utils/api/organizations';
import { AuthContext } from 'contexts/authContext';
import NotesForm from 'components/notesForm/notesForm';

const EditOrganizationNotes = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken } = useContext(AuthContext);
  const { organizationEtag, note, editNotes, handleClick } = props ?? {};
  const { organizationId } = note;

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          search: window.location.search,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const sendNote = async (values, { setStatus }) => {
    const payload = {
        note: values?.note
    };
    
    const { response } = await updateOrganizationNote(
      note.organizationId,
      note.organizationNotesId, 
      payload,
      organizationEtag,
      accessToken,
    );
    switch (response?.status) {
      case 200:
        setStatus({ success: true });
        setRedirect({ isRedirect: true, data: { organizationId } });
        break;
      case 412:
        setStatus({
          success: false,
          message: 'Error. Please refresh the page',
        });
        break;
      case 500:
        setStatus({ success: false, message: 'Internal Server Error' });
        break;
      default:
        if (!response) {
          setStatus({ success: false, message: 'Network error' });
        } else {
          setStatus({
            success: false,
            message: 'Unknown error. Please refresh the page.',
          });
        }
        break;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        note: props.note.note
      }}
      validationSchema={OrganizationNotesSchema}
      onSubmit={sendNote}
      children={props => <NotesForm tenant={false} {...props} editNotes={editNotes} handleClick={handleClick} />}
    />
  );
};

export default EditOrganizationNotes;
