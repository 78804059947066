const handleErrors = res => {
  if (!res.ok) {
    return res.json().then(error => {
      throw error;
    });
  }
  return res;
};

export const createUser = (url, token, payload) => {
  return fetch(url, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(payload),
  })
    .then(handleErrors)
    .then(res => res.json());
};

// TODO Determine if this better lives elsewhere.
export const editUser = (url, token, payload) => {
  return fetch(url, {
    method: 'PUT',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(payload),
  })
    .then(handleErrors)
    .then(res => res.json());
};

export const ResetPassword = (url, token, payload) => {
  return fetch(url, {
    method: 'POST',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(payload),
  }).then(handleErrors);
};
