import React, { useState, useEffect, useRef, useContext } from 'react';
import { Auth0Jwt } from 'components/auth';
import { AuthContext } from 'contexts/authContext';
import { IconButton } from '@mui/material';
import { LOGOUT_PATH } from 'utils/configuration/links';
import {
  AppsIcon,
  HelpMenuIcon,
  UserProfileIcon,
  ToolsIcon,
  FeedbackIcon,
  LogoutIcon,
  KeyboardArrowDownIcon,
} from 'components/icons/icons';
import {
  USER_MENU_MY_PROFILE_BUTTON_TEXT,
  USER_MENU_GET_HELP_TEXT,
  USER_MENU_SIGN_OUT_TEXT,
  USER_MENU_MYHW_BUTTON_TEXT,
  USER_MENU_LOG_SERVICE_REQUEST_TEXT,
  USER_MENU_SUBMIT_CONTENT_FEEDBACK_TEXT,
} from 'utils/dictionary/overview';
import {
  REACT_APP_FRONTDOOR_ISSUER,
  REACT_APP_HELP_URL,
  REACT_APP_LOG_REQUEST_URL,
} from 'utils/dictionary/env';
import style from './userMenu.module.scss';
import { useHistory } from 'react-router-dom';
import { useIsContentFeedbackEnabled } from 'utils/hooks/common/useIsContentFeedbackEnabled.js';

export const ancestorHasId = (el, id) => {
  let parent = el;
  while (parent) {
    parent = parent.parentElement;
    if (parent && parent.id === id) {
      return parent;
    }
  }
  return undefined;
};

const UserMenu = ({ user }) => {
  const { firstName, lastName, email, logService, contentFeedback } = user;
  const name = `${firstName} ${lastName}`;
  const [visible, setVisible] = useState(false);
  const [userMenuItems, setUserMenuItems] = useState();
  const { contentFeedbackEnabled } = useIsContentFeedbackEnabled();

  const userInfoContainerId = 'userInfoContainer';
  const { organizationAlias } = useContext(AuthContext);
  const helpUrl = REACT_APP_HELP_URL + '#' + Auth0Jwt();
  const myHealthwiseUrl = `${REACT_APP_FRONTDOOR_ISSUER}/${organizationAlias}/applications${window.location.search}`;
  const logServiceRequestUrl = REACT_APP_LOG_REQUEST_URL + '#' + Auth0Jwt();
  const contentFeedbackUrl = `${REACT_APP_FRONTDOOR_ISSUER}/${organizationAlias}/feedback${window.location.search}`;
  const history = useHistory();

  const clickListener = e => {
    const isNotControlsParent = !ancestorHasId(e.target, userInfoContainerId);
    if (isNotControlsParent) {
      setVisible(false);
      removeClickHandler();
    }
  };
  const removeClickHandler = () => {
    window.removeEventListener('click', clickListener);
  };

  const addClickHandler = () => {
    window.addEventListener('click', clickListener);
  };

  const firstMenuButton = useRef(null);
  const menuButton = useRef(null);

  useEffect(() => {
    setUserMenuItems(document.querySelectorAll('[role="menuitem"]'));
  }, [visible]);

  useEffect(() => {
    if (firstMenuButton?.current && visible) {
      firstMenuButton.current.focus();
    }
  }, [firstMenuButton, visible]);

  var currentIndex = 0;

  var gotoIndex = function (idx) {
    if (idx === userMenuItems.length) {
      idx = 0;
    } else if (idx < 0) {
      idx = userMenuItems.length - 1;
    }

    userMenuItems[idx].focus();
    currentIndex = idx;
  };

  const handleClick = e => {
    e.stopPropagation();
    setVisible(!visible);
    if (visible && menuButton?.current) {
      menuButton.current.focus();
      removeClickHandler();
    } else {
      addClickHandler();
    }
  };

  //https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
  var keys = {
    tab: 'Tab',
    enter: 'Enter',
    esc: 'Escape',
    space: 'Space',
    left: 'ArrowLeft',
    up: 'ArrowUp',
    right: 'ArrowRight',
    down: 'ArrowDown',
  };

  const keyPressEventListener = event => {
    var prevdef = true;
    switch (event.code) {
      case keys.tab:
        if (event.shiftKey) {
          gotoIndex(currentIndex - 1);
        } else {
          gotoIndex(currentIndex + 1);
        }
        prevdef = true;
        break;
      case keys.right:
        prevdef = true;
        break;
      case keys.left:
        prevdef = true;
        break;
      case keys.esc:
        setVisible(false);
        if (menuButton?.current) {
          menuButton.current.focus();
          removeClickHandler();
        }
        prevdef = true;
        break;
      case keys.down:
        gotoIndex(currentIndex + 1);
        prevdef = true;
        break;
      case keys.up:
        gotoIndex(currentIndex - 1);
        prevdef = true;
        break;
      case keys.enter:
      case keys.space:
        event.target.click();
        prevdef = false;
        break;
      default:
        prevdef = true;
        break;
    }

    if (prevdef) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const goToHelp = () => {
    window.open(helpUrl, '_blank');
  };

  const goToProfile = () => {
    window.location.assign(
      `${REACT_APP_FRONTDOOR_ISSUER}/${organizationAlias}/profile${window.location.search}`,
    );
  };

  const goToApplications = () => {
    window.location.assign(myHealthwiseUrl);
  };

  const goToSignOut = () => {
    history.push(`${organizationAlias}/${LOGOUT_PATH}`);
  };

  const goToLogServiceRequest = () => {
    window.open(logServiceRequestUrl, '_blank');
  };

  const goToContentFeedback = () => {
    window.location.assign(contentFeedbackUrl);
  };

  return (
    <>
      <IconButton
        id="usermenubutton"
        aria-controls="userMenu"
        aria-haspopup="true"
        aria-label={`${name} Account Menu`}
        aria-expanded={Boolean(visible)}
        onClick={handleClick}
        ref={menuButton}
        style={{ padding: '0 12px' }}
        className={style.profileButton}
        disableFocusRipple
      >
        <KeyboardArrowDownIcon
          className={`${style.arrowDownIcon} ${
            visible ? style.buttonActive : ''
          }`}
        />
      </IconButton>
      <div
        id={userInfoContainerId}
        data-testid={userInfoContainerId}
        className={`${style.userInfoContainer} ${
          visible ? '' : style.invisible
        }`}
      >
        <nav className={style.nav}>
          <div className={style.header}>
            <p className={style.name}>{name}</p>
            <p className={style.email}>{email}</p>
          </div>

          <div className={style.divider} />

          <ul id="userMenu" role="menu" className={style.menu}>
            <li
              role="menuitem"
              className={style.myHealthwiseItem}
              onClick={goToApplications}
              onKeyDown={keyPressEventListener}
              ref={firstMenuButton}
              tabIndex="0"
            >
              <AppsIcon className={style.icon} />
              <span
                ref={firstMenuButton}
                className={style.link}
                aria-label={`${USER_MENU_MYHW_BUTTON_TEXT} - Link`}
              >
                {USER_MENU_MYHW_BUTTON_TEXT}
              </span>
            </li>
          </ul>

          <div className={style.divider} />

          <ul id="userMenu" role="menu" className={style.menu}>
            <li
              role="menuitem"
              className={style.menuItem}
              onClick={goToProfile}
              onKeyDown={keyPressEventListener}
              tabIndex="0"
            >
              <span
                className={style.link}
                aria-label={`${USER_MENU_MY_PROFILE_BUTTON_TEXT} - Link`}
              >
                <UserProfileIcon className={style.icon} />
                {USER_MENU_MY_PROFILE_BUTTON_TEXT}
              </span>
            </li>
            <li
              role="menuitem"
              className={style.menuItem}
              onClick={goToHelp}
              onKeyDown={keyPressEventListener}
              tabIndex="0"
            >
              <span
                className={style.link}
                rel="noreferrer"
                aria-label={`${USER_MENU_GET_HELP_TEXT} - Link - opens new tab`}
              >
                <HelpMenuIcon className={style.icon} />
                {USER_MENU_GET_HELP_TEXT}
              </span>
            </li>
            {logService === 'true' ? (
              <li
                role="menuitem"
                className={style.menuItem}
                onClick={goToLogServiceRequest}
                onKeyDown={keyPressEventListener}
                tabIndex="0"
              >
                <span
                  className={style.link}
                  rel="noreferrer"
                  aria-label={`${USER_MENU_LOG_SERVICE_REQUEST_TEXT} - Link - opens new tab`}
                >
                  <ToolsIcon className={style.icon} />
                  <span className={style.textPadding}>
                    {USER_MENU_LOG_SERVICE_REQUEST_TEXT}
                  </span>
                </span>
              </li>
            ) : (
              <li tabIndex="-1" className={style.menuLiHide}></li>
            )}
            {contentFeedback === 'true' && contentFeedbackEnabled ? (
              <li
                role="menuitem"
                className={style.menuItem}
                onClick={goToContentFeedback}
                onKeyDown={keyPressEventListener}
                tabIndex="0"
              >
                <span
                  className={style.link}
                  rel="noreferrer"
                  aria-label={`${USER_MENU_SUBMIT_CONTENT_FEEDBACK_TEXT} - Link`}
                >
                  <FeedbackIcon className={style.icon} />
                  <span className={style.textPadding}>
                    {USER_MENU_SUBMIT_CONTENT_FEEDBACK_TEXT}
                  </span>
                </span>
              </li>
            ) : (
              <li tabIndex="-1" className={style.menuLiHide}></li>
            )}
          </ul>

          <div className={style.divider} />

          <ul role="menu" className={style.menu}>
            <li
              role="menuitem"
              className={style.menuItem}
              onClick={goToSignOut}
              onKeyDown={keyPressEventListener}
              tabIndex="0"
              aria-label="Sign Out - Link"
            >
              <span className={style.link}>
                <LogoutIcon className={style.icon} />
                <span className={style.textPadding}>
                  {USER_MENU_SIGN_OUT_TEXT}
                </span>
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default UserMenu;
