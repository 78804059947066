import React, { useState, useRef } from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogSectionTitle,
  DialogSubSectionText,
  DialogTitle,
  Divider,
} from 'components/dialog/dialog';
import { SingleSelectInput } from 'components/singleSelectInputV2/singleSelectInput';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import { PrimaryButton, GeneralCancelButton } from 'components/buttons/button';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import style from './createOrganizationSubscriptionModal.module.scss';
import FriendlyIdInput from 'components/organizationManagement/friendlyIdInput/friendlyIdInput';
import AliasInput from 'components/organizationManagement/aliasInput/aliasInput';
import Tooltip from '@mui/material/Tooltip';

//This method also filter out production if the limit is reached
const getEnvironmentOptions = (environmentOptions, prodAvailable) => {
  const option =
    environmentOptions?.map(({ subscriptionTypeId, name }) => ({
      name: name,
      id: subscriptionTypeId,
      value: name,
    })) ?? [];
  return prodAvailable === 0 ? [] : option;
};

const CreateOrganizationSubscriptionModal = props => {
  const [open, setOpen] = useState(false);

  const {
    modalButtonText,
    environmentOptions,
    closeModal,
    isValid,
    dirty,
    isSubmitting,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    productionSubscriptionsLimitInfo,
    handleReset,
    values,
  } = props;

  const {
    availableProductionSubscriptions = 0,
    productionSubscriptionsLimit = 0,
  } = productionSubscriptionsLimitInfo ?? {};

  const disableAddTenantBtn =
    availableProductionSubscriptions === 0 ? true : false;

  const CREATE_ORGANIZATION_SUBSCRIPTION_MODAL_ENVIRONMENT_HELPER_TEXT = (
    availableEnvironments = 0,
    limit = 0,
  ) =>
    `${availableEnvironments} of ${limit} production subscriptions are available.`;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (handleReset) handleReset();
    setOpen(false);
  };

  //Close modal when form is submitted successfully
  //Couldn't think of a better way to implement this
  if (closeModal.current.close) {
    closeModal.current = { close: false };
    setOpen(false);
  }

  const inputRef = useRef(null);
  useAddFocus(inputRef, open);

  return (
    <>
      {disableAddTenantBtn ? (
        <Tooltip
          title="Max tenant limit reached."
          aria-describedby="Max tenant limit reached."
          placement="top"
          arrow
        >
          <span className={style.createButton}>
            <PrimaryButton
              buttonText={modalButtonText}
              key="add"
              disable={disableAddTenantBtn}
            />
          </span>
        </Tooltip>
      ) : (
        <PrimaryButton
          buttonText={modalButtonText}
          key="add"
          handleClick={handleOpen}
          className={style.createButton}
          disable={disableAddTenantBtn}
        />
      )}
      <Dialog
        role="dialog"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle id="dialog-title">Add New Tenant</DialogTitle>
          <DialogContent className={style.dialogContent}>
            <DialogSubSectionText id="dialog-description">
              A new tenant will create a container for managing and isolating a
              set of Healthwise resources.
            </DialogSubSectionText>

            <DialogSectionTitle id="dialog-step1">
              1. Create a Tenant Name
            </DialogSectionTitle>
            <DialogSubSectionText id="dialog-step1-desc">
              Provide a unique client-friendly name.
            </DialogSubSectionText>

            <div className={style.gridItem}>
              <BaselineTextInput
                externalLabel={'Tenant Name'}
                id="subscriptionName"
                name="subscriptionName"
                errorlabel="Tenant Name"
                inputRef={inputRef}
                arialabel="New Tenant Name"
                ariadescribedby="dialog-step1-desc"
                isRequiredErrorMessage={true}
                requiredErrorMessage="A tenant name is required"
              />
            </div>

            <Divider className={style.dividr} />
            <DialogSectionTitle id="dialog-step2">
              2. Select an Environment
            </DialogSectionTitle>
            <DialogSubSectionText id="dialog-step2-desc">
              Select a production or non-production environment for this tenant.
            </DialogSubSectionText>

            <div
              className={`${style.gridItem} ${style.rowField} ${
                values.selectEnvironment?.id
                  ? style.itemGridRowFieldPopulated
                  : ''
              }`}
            >
              <SingleSelectInput
                name="selectEnvironment"
                externalLabel={'Environment'}
                placeholder={'Add an environment'}
                optionsList={getEnvironmentOptions(
                  environmentOptions,
                  availableProductionSubscriptions,
                )}
                variant="outlined"
                selectedValue={values.selectEnvironment}
                disableCloseOnSelect={false}
                handleChange={setFieldValue}
                handleBlur={setFieldTouched}
                touch={touched['selectEnvironment']}
                error={errors['selectEnvironment']}
                errorMsg={errors['selectEnvironment']}
                helpMsg={CREATE_ORGANIZATION_SUBSCRIPTION_MODAL_ENVIRONMENT_HELPER_TEXT(
                  availableProductionSubscriptions,
                  productionSubscriptionsLimit,
                )}
                arialabelledby="dialog-step2"
                ariadescribedby="dialog-step2-desc"
              />
            </div>
            <FriendlyIdInput
              inputClassName={style.gridItem}
              title="3. Create a Unique Content Identifier"
              description="This name is used to differentiate the content from other tenants and organizations. Please make sure this name is unique and matches the following:"
              value={values.friendlyId}
              onKeyUp={() => {
                if (values.friendlyId && !touched['friendlyId']) {
                  setFieldTouched('friendlyId');
                }
              }}
            />
            <AliasInput
              inputClassName={style.gridItem}
              title="4. Create a Unique Alias"
              description="This name is used in the browser url to navigate to applications that the organization has purchased. Please make sure this name is unique and matches the following:"
              value={values.alias}
              onKeyUp={() => {
                if (values.alias && !touched['alias']) {
                  setFieldTouched('alias');
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <GeneralCancelButton onClick={handleClose} disabled={isSubmitting}>
              Cancel
            </GeneralCancelButton>
            <PrimaryButton
              variant="contained"
              type="submit"
              disabled={!(isValid && dirty) || isSubmitting}
              buttonText="Create New Tenant"
            ></PrimaryButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

CreateOrganizationSubscriptionModal.propTypes = {
  modalButtonText: PropTypes.string.isRequired,

  environmentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      subscriptionTypeId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  closeModal: PropTypes.shape({
    current: PropTypes.shape({
      close: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CreateOrganizationSubscriptionModal;
