import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Menu, MenuItem, ListItemText } from '@mui/material';
import MaterialTable from '@material-table/core';
import { SectionHeader } from 'components/sectionHeader/sectionHeader';
import ResetPasswordWorkflow from 'components/passwordWorkflows/resetPasswordWorkflow';
import CreateTenantAdministrator from 'containers/tenantManagement/createTenantAdministrator/createTenantAdministrator';
import TenantAdministratorResendInvitation from 'containers/tenantManagement/editTenantAdministrator/tenantAdministratorResendInvitation';
import TenantAdministratorActivate from 'containers/tenantManagement/editTenantAdministrator/tenantAdministratorActivate';
import TenantAdministratorEditDates from 'containers/tenantManagement/editTenantAdministrator/tenantAdministratorEditDates';
import StatusChip from 'components/users/statusChip/statusChip';
import { TableSkeleton } from 'components/skeletons/skeletons';
import Padding from 'components/padding/padding';
import style from './tenantAdministratorPanel.module.scss';
import useSetTableCaption from 'utils/hooks/common/useSetTableCaption';
import { ORG_ADMIN_TABLE_ID } from 'utils/dictionary/overview';
import { getStatusName } from 'utils/statusNames';
import StyledTableCell from 'components/table/tableCell/styledTableCell';
import { FEATURE_TOGGLE_REMOVE_TENANT_ADMINISTRATOR } from 'utils/dictionary/featureToggles';
import RemoveTenantAdministrator from 'containers/tenantManagement/editTenantAdministrator/removeTenantAdministrator';

/**
 * Render the Organization Administrator's table with or without action buttons.
 * Note: if `readOnly` is `false`, `organizationToEdit`,
 * `renderOrganizationAdministratorResendInvitation`, and
 * `renderRemoveOrganizationAdministrator` _must_ be defined. Those fields are
 * optional if `readOnly` is `true`.
 */
const AdministratorsTable = props => {
  const {
    readOnly,
    administratorsData,
    organization,
    tenant,
    organizationName,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [administrators, setAdministrators] = useState([]);

  useEffect(() => {
    //table row needs id to mount the menu correctly
    administratorsData.forEach(element => {
      element.id = element.userId;
    });
    setAdministrators(administratorsData);
  }, [administratorsData]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isResendVisible = row => {
    return ['Invite Sent', 'Resend Invite'].includes(
      getStatusName(row.status, row.startDate),
    );
  };

  // update state
  const removeAdministratorFromState = useCallback(
    val => {
      const adminData = [...administrators.filter(item => item.userId !== val)];
      setAdministrators(adminData);
    },
    [administrators],
  );

  const removeTenantAdmin =
    sessionStorage
      .getItem('features')
      ?.includes(FEATURE_TOGGLE_REMOVE_TENANT_ADMINISTRATOR) === true;

  const resendRef = useRef();
  const resetRef = useRef();
  const editDatesRef = useRef();
  const activateRef = useRef();
  const removeRef = useRef();

  const menuAndModals = (
    <>
      <Menu
        className={style.menu}
        id="action-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {currentRow && isResendVisible(currentRow) && (
          <MenuItem
            className={style.menuItem}
            onClick={() => resendRef.current.handleOpen()}
          >
            <ListItemText primary="Resend Invite" />
          </MenuItem>
        )}
        {currentRow &&
          (currentRow.status === 'STAGED' ||
            currentRow.status === 'DEPROVISIONED') && (
            <>
              <MenuItem
                className={style.menuItem}
                onClick={() => editDatesRef.current.handleOpen()}
              >
                <ListItemText primary="Edit Start and End Date" />
              </MenuItem>
              <MenuItem
                className={style.menuItem}
                onClick={() => removeRef.current.handleOpen()}
              >
                <ListItemText primary="Remove Administrator" />
              </MenuItem>
            </>
          )}
        {currentRow && currentRow.status === 'ACTIVE' && (
          <MenuItem
            className={style.menuItem}
            onClick={() => resetRef.current.handleResetModalOpen()}
          >
            <ListItemText primary="Reset Password" />
          </MenuItem>
        )}
        {currentRow && currentRow.status === 'SUSPENDED' && (
          <MenuItem
            className={style.menuItem}
            onClick={() => activateRef.current.handleOpen()}
          >
            <ListItemText primary="Activate User" />
          </MenuItem>
        )}
        {currentRow && removeTenantAdmin && (
          <MenuItem
            className={`${style.menuItem}`}
            onClick={() => removeRef.current.handleOpen()}
          >
            <ListItemText primary="Remove Administrator Role" />
          </MenuItem>
        )}
      </Menu>
      {currentRow && (
        <>
          <TenantAdministratorResendInvitation
            rowData={currentRow}
            closeMenu={handleClose}
            ref={resendRef}
            tenantId={tenant}
          />
          <ResetPasswordWorkflow
            forOrgAdmin={true}
            userInfo={currentRow}
            organization={organization}
            ref={resetRef}
            tenantId={tenant}
          />
          <TenantAdministratorEditDates
            rowData={currentRow}
            organization={organization}
            closeMenu={handleClose}
            ref={editDatesRef}
            tenantId={tenant}
          />
          <TenantAdministratorActivate
            rowData={currentRow}
            organization={organization}
            closeMenu={handleClose}
            ref={activateRef}
            tenantId={tenant}
          />
          <RemoveTenantAdministrator
            rowData={currentRow}
            tenantId={tenant}
            closeMenu={handleClose}
            ref={removeRef}
            removeAdministratorFromState={removeAdministratorFromState}
          />
        </>
      )}
    </>
  );

  // Default columns to show
  const columns = [
    {
      title: 'Last Name',
      defaultSort: 'asc',
      field: 'lastName',
      cellStyle: {
        fontWeight: '500',
      },
      render: ({ lastName }) => <StyledTableCell displayString={lastName} />,
    },
    {
      title: 'First Name',
      field: 'firstName',
      cellStyle: {
        fontWeight: '500',
      },
      render: ({ firstName }) => <StyledTableCell displayString={firstName} />,
    },
    {
      title: 'Email',
      field: 'email',
      render: ({ email }) => <StyledTableCell displayString={email} />,
    },
    {
      title: 'Status',
      field: 'status',
      width: '15%',
      render: ({ status, startDate }) => (
        <StatusChip status={status} startDate={startDate} />
      ),
    },
  ];

  //A11y - Setting caption to table
  useSetTableCaption(ORG_ADMIN_TABLE_ID);

  const actionProps = readOnly
    ? {}
    : {
        actions: [
          {
            icon: 'more_horiz',
            onClick: (event, rowData) => {
              handleClick(event, rowData);
            },
          },
        ],
      };

  return (
    <>
      <MaterialTable
        style={{ boxShadow: '0px 3px 6px #00000029' }}
        columns={columns}
        data={administrators}
        localization={{
          pagination: {
            labelRowsPerPage: 'Rows per page',
            labelRowsSelect: '',
          },
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 5,
          toolbar: readOnly ? false : true,
          search: true,
          sorting: true,
          thirdSortClick: false,
          draggable: false,
          headerStyle: {
            backgroundColor: '#f8f8f8',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#424242',
          },
          rowStyle: {
            fontSize: '1rem',
            color: '#424242',
            fontFamily: 'Roboto',
          },
        }}
        {...actionProps}
        components={{
          Toolbar: props => (
            <div className={style.toolbar}>
              <CreateTenantAdministrator
                organization={organization}
                tenant={tenant}
                organizationName={organizationName}
              />
            </div>
          ),
        }}
      />
      {readOnly ? <></> : menuAndModals}
    </>
  );
};

const DisplayAdministrators = props => {
  const {
    administratorsData = {},
    organization = {},
    organizationName,
    tenant = {},
    readOnly = false,
  } = props;

  const { administrators = [] } = administratorsData;
  const description = readOnly
    ? 'Tenant administrators manage users and their roles at your organization. You can assign administrators on the Manage Users page.'
    : "Tenant Administrators are provisioned by Healthwise. Administrators manage users and their roles at the client's organization.";

  return (
    <>
      <SectionHeader title="Tenant Administrators" secondary={description} />

      <AdministratorsTable
        readOnly={readOnly}
        administratorsData={administrators}
        organization={organization}
        tenant={tenant}
        organizationName={organizationName}
      />
    </>
  );
};

/**
 * Render the Organization Administrator panel.
 * Note: if `readOnly` is `false`, `organizationToEdit`,
 * `renderOrganizationAdministratorResendInvitation`, and
 * `renderRemoveOrganizationAdministrator` _must_ be defined. Those fields are
 * optional if `readOnly` is `true`.
 */
export const TenantAdministratorPanel = props => {
  const { administratorsData = {}, organization = {} } = props;
  const { organizationAdministratorsLoading = false } = administratorsData;
  const { organizationToEditLoading = false } = organization;

  const Loading = () =>
    organizationToEditLoading || organizationAdministratorsLoading;

  return (
    <section>
      {Loading() ? (
        <Padding>
          <TableSkeleton />
        </Padding>
      ) : (
        <DisplayAdministrators {...props} />
      )}
    </section>
  );
};

export default TenantAdministratorPanel;
