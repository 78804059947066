import { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'contexts/authContext';
import { getTenantNotes } from 'utils/api/tenant';
import { setApiCallStatus } from '../useToCallApiEndpoints';

export const useGetTenantNotes = (
    tenantId,
    triggerRerender,
    top = 100,
    skip = 0,
  ) => {
    const { accessToken, loading, isProvisioningManager } = useContext(AuthContext);
    const [pagination, setPagination] = useState({});
    const [status, setStatus] = useState();
    const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
      const response = async () => {
        if(tenantId){
          const { response, data, pagination, error } = await getTenantNotes(
            skip,
            top,
            tenantId,
            accessToken,
            isProvisioningManager,
          );
          setPagination(pagination);
          setApiCallStatus(response, data, error, setStatus, setIsLoading)
        }
      };
      if (!loading) {
        response();
      }
    }, [
      skip,
      top,
      accessToken,
      loading,
      tenantId,
      isProvisioningManager,
      triggerRerender
    ]);
  
   return {
    data: status?.data ?? [],
    errorMessage: status?.message,
    success: status?.success,
    notificationKey: status?.key,
    isLoading,
    pagination,
  };
};
  