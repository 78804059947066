const getStatusNameWithStartDate = (baseName, alternateName, startDate) => {
  const now = new Date();
  const startDateObject = startDate !== null ? new Date(startDate) : now;

  if (
    startDateObject instanceof Date &&
    !isNaN(startDateObject) &&
    startDateObject >= now
  ) {
    return alternateName;
  }

  return baseName;
};

const getStatusName = (name, startDate) => {
  // Backend statuses:
  //
  // UNKNOWN,
  // ACTIVE,
  // DEPROVISIONED,
  // LOCKED_OUT,
  // PROVISIONED,
  // RECOVERY,
  // STAGED,
  // SUSPENDED,
  // PASSWORD_EXPIRED

  switch (name.toString().trim().toLowerCase()) {
    case 'unknown':
      return 'Unknown';
    case 'active':
      return 'Active';
    case 'deprovisioned':
      return getStatusNameWithStartDate('Removed', 'Invite Pending', startDate);
    case 'locked_out':
      return 'Locked Out';
    case 'provisioned':
      return 'Invite Sent';
    case 'recovery':
      return 'Password Recovery';
    case 'staged':
      return getStatusNameWithStartDate(
        'Resend Invite',
        'Invite Pending',
        startDate,
      );
    case 'suspended':
      return 'Inactive';
    case 'password_expired':
      return 'Password Expired';
    default:
      return name;
  }
};

export { getStatusName };
