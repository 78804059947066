import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  InputAdornment,
  FormControlLabel,
  RadioGroup,
} from '@mui/material';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  DialogContent,
  DialogSectionTitle,
  DialogSubSectionText,
} from 'components/dialog/dialog';
import { AddIcon, SearchIcon } from 'components/icons/icons';
import Notification from 'components/notificationV2/notification';
import { SingleSelectInput } from 'components/singleSelectInputV2/singleSelectInput';
import {
  BaselineTextInput,
  BaselineTextField,
} from 'components/textInputV2/textInput';
import { useAddFocus } from 'utils/hooks/useAddFocus';
import {
  GreenRadio,
  GeneralIconButton,
  GeneralCancelButton,
  PrimaryButton,
} from 'components/buttons/button';
import { Typography } from 'components/typography/typography';
import FriendlyIdInput from 'components/organizationManagement/friendlyIdInput/friendlyIdInput';

import style from './createOrganizationModal.module.scss';

//TODO: Change color of close icon and change color of search icon on focus.
//TODO: Look into slowness of search query, data takes long to return and UI render is slow
const SearchInput = props => {
  const {
    onChange,
    onBlur,
    name,
    optionsList,
    touched,
    errorMsg,
    helpMsg,
    handleSearchQuery,
    values,
    inputRef,
    arialabelledby,
    ariadescribedby,
  } = props;

  const handleChange = (event, value) => {
    onChange(name, value ?? {});
  };

  const handleBlur = () => {
    onBlur(name, true);
  };

  /**
   * Card for displaying in the autocomplete when searching for clients.
   */
  const RenderSalesforceSelectCard = props => {
    const { name, sfid, manager } = props;
    return (
      <div>
        <Typography className={style.header}>{name}</Typography>
        <Typography className={style.data}>
          Account Manager: {manager}
        </Typography>
        <Typography className={style.data}>Salesforce ID: {sfid}</Typography>
      </div>
    );
  };

  return (
    <SingleSelectInput
      name={name}
      selectedValue={values[name]}
      variant="outlined"
      optionsList={optionsList}
      disableCloseOnSelect={false}
      disableClearable={true}
      handleChange={handleChange}
      handleBlur={handleBlur}
      freeSolo
      helpMsg={helpMsg}
      arialabelledby={arialabelledby}
      ariadescribedby={ariadescribedby}
      renderOptionCard={option => (
        <RenderSalesforceSelectCard
          name={option.name}
          sfid={option.id}
          manager={option.accountManager}
        />
      )}
      renderInputCard={params => (
        <BaselineTextField
          externalLabel={'Salesforce Account'}
          {...params}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            startAdornment: (
              <InputAdornment className={style.inputAdornment} position="end">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchQuery}
          helperText={touched && !!errorMsg ? errorMsg : helpMsg}
          error={!!errorMsg && touched}
          onBlur={handleBlur}
          className={style.textField}
          inputRef={inputRef}
          arialabelledby={arialabelledby}
          ariadescribedby={ariadescribedby}
        />
      )}
    />
  );
};

const CreateOrganizationModal = props => {
  const [open, setOpen] = useState(false);

  const {
    modalButtonText,
    isValid,
    dirty,
    isSubmitting,
    closeModal,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    searchResult,
    setSearchQuery,
    handleReset,
    status,
    searchStatus = {},
    values,
    categories,
    values: { friendlyId },
  } = props;

  const emDash = `${'\u2014'}`;
  const { success, message = '', messageKey } = status ?? {};
  const { searchSuccess, notificationKey, errorMessage } = searchStatus;
  const [categoryValue, setCategoryValue] = useState(null);

  const inputRef = useRef(null);
  useAddFocus(inputRef, open);

  const onAccountChange = (field, value, shouldValidate) => {
    if (Object.keys(value).length !== 0) {
      setFieldValue(field, value, true);
      setFieldValue('organizationName', value?.name, true);
      setFieldTouched('organizationName', true, false);
    } else {
      handleReset();
    }
  };

  const onCategoryChange = e => {
    const value = parseInt(e.target.value);
    setFieldValue('organizationCategoryId', value, true);
    setCategoryValue(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCategoryValue(null);
    if (handleReset) handleReset();
  };

  // Filtering for the select box
  const handleInputChange = event => {
    debounceSearchQuery(event.target.value);
  };
  // 'debounceSearchQuery' is the function setup.
  const debounceSearchQuery = debounce(value => handleSearchQuery(value), 500);
  const handleSearchQuery = value => {
    if (value) {
      setSearchQuery(value);
    }
  };

  useEffect(() => {
    if (closeModal.current.close) {
      closeModal.current = { close: false };
      setOpen(false);
      if (handleReset) handleReset();
    }
  }, [closeModal, handleReset]);

  return (
    <>
      {success === false ? (
        <Notification error message={message} messageKey={messageKey} />
      ) : null}
      {searchSuccess === false ? (
        <Notification
          error
          message={errorMessage}
          messageKey={notificationKey}
        />
      ) : null}
      <GeneralIconButton
        className={style.modalButton}
        buttonText={modalButtonText}
        icon={<AddIcon />}
        handleClick={handleOpen}
      />
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        aria-labelledby="dialog-title"
      >
        <Form>
          <DialogTitle id="dialog-title">Create New Organization </DialogTitle>
          <DialogContent>
            <DialogSectionTitle id="dialog-step1">
              1. Link Salesforce Account
            </DialogSectionTitle>
            <DialogSubSectionText id="dialog-step1-desc">
              Search for an existing Licensee Salesforce account name or ID.
            </DialogSubSectionText>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item xs={12} className={style.gridItemSalesForce}>
                <SearchInput
                  name="primarySalesforceAccount"
                  optionsList={searchResult}
                  onChange={onAccountChange}
                  onBlur={setFieldTouched}
                  handleSearchQuery={handleInputChange}
                  touched={touched['primarySalesforceAccount']}
                  errorMsg={errors['primarySalesforceAccount']?.id}
                  helpMsg=""
                  values={values}
                  inputRef={inputRef}
                  arialabelledby="dialog-step1"
                  ariadescribedby="dialog-step1-desc"
                />
              </Grid>
            </Grid>
            <Divider />
            <DialogSectionTitle id="dialog-step2">
              2. Create an Organization Name
            </DialogSectionTitle>
            <DialogSubSectionText id="dialog-step2-desc">
              Include the client name in the organization followed by any
              descriptors that may be pertinent to this organization. (ie:
              Healthwise - Behavioral Health)
            </DialogSubSectionText>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} className={style.gridItem}>
                <BaselineTextInput
                  externalLabel={'Organization Name'}
                  arialabelledby="dialog-step2"
                  ariadescribedby="dialog-step2-desc"
                  name="organizationName"
                  errorlabel="Organization Name"
                  requiredErrorMessage="An organization name is required"
                  margin="none"
                  size="medium"
                />
              </Grid>
            </Grid>
            <Divider />
            <DialogSectionTitle id="dialog-step3">
              3. Select Organization Type
            </DialogSectionTitle>
            <DialogSubSectionText id="dialog-step3-desc">
              Organization type determines which organizations will be visible
              to clients. This selection cannot be changed at a later date.
            </DialogSubSectionText>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} className={style.gridItem}>
                <RadioGroup
                  aria-labelledby="dialog-step3"
                  aria-describedby=" dialog-step3-desc"
                  value={categoryValue}
                  onChange={onCategoryChange}
                  className={style.radioGroup}
                >
                  {categories.map(option => (
                    <FormControlLabel
                      key={option.name}
                      value={option.organizationCategoryId}
                      label={
                        option.name + ' ' + emDash + ' ' + option.description
                      }
                      control={<GreenRadio />}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            <FriendlyIdInput
              inputClassName={style.gridItem}
              title="4. Create a Unique Content Identifier"
              description="This name is used to differentiate the content from other organizations. Please make sure this name is unique and matches the following:"
              value={friendlyId}
              onKeyUp={() => {
                if (friendlyId && !touched['friendlyId']) {
                  setFieldTouched('friendlyId');
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <GeneralCancelButton onClick={handleClose} disabled={isSubmitting}>
              Cancel
            </GeneralCancelButton>
            <PrimaryButton
              variant="contained"
              type="submit"
              disabled={!(isValid && dirty) || isSubmitting}
              buttonText="Create New Organization"
            ></PrimaryButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

CreateOrganizationModal.propTypes = {
  modalButtonText: PropTypes.string.isRequired,
  closeModal: PropTypes.shape({
    current: PropTypes.shape({
      close: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  searchResult: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired }),
  ).isRequired,
  setSearchQuery: PropTypes.func.isRequired,
};

export default CreateOrganizationModal;
