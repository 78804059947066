import React, { useState, useRef } from 'react';
import { Icon, MenuItem, ListItemText, Menu } from '@mui/material';
import MaterialTable from '@material-table/core';
import CreateOrganizationSubscription from 'containers/organizationManagement/createOrganizationSubscription';
import DeleteOrganizationSubscription from 'containers/organizationManagement/deleteOrganizationSubscription';
import { SectionHeader } from 'components/sectionHeader/sectionHeader';
import { TableSkeleton } from 'components/skeletons/skeletons';
import Padding from 'components/padding/padding';
import SubscriptionSwitch from 'containers/organizationManagement/subscriptionSwitch/subscriptionSwitch';
import EditOrganizationSubscription from 'containers/organizationManagement/editOrganizationSubscription/editOrganizationSubscription';
import style from './subscriptionPanel.module.scss';
import useSetTableCaption from 'utils/hooks/common/useSetTableCaption';
import { SUBSCRIPTIONS_TABLE_ID } from 'utils/dictionary/overview';
import DebugValueWithClipboard from 'components/debugValueWithClipboard/debugValueWithClipboard';
import { FEATURE_TOGGLE_DEBUG } from 'utils/dictionary/featureToggles';

const getAvailableProductionSubscription = (
  subscriptions,
  productionSubscriptionsLimit,
) => {
  var activeSubscriptions = subscriptions.filter(x => x.active === true).length;
  return Math.max(productionSubscriptionsLimit - activeSubscriptions, 0);
};

const AVAILABLE_ENVIRONMENT_TEXT = (availableEnvironments, limit) =>
  `${availableEnvironments} of ${limit} tenants are currently in use`;
const SUBSCRIPTION_HEADER_HELPFUL_TEXT = (
  availableEnvironments = 0,
  limit = 0,
) => {
  const used =
    availableEnvironments <= limit ? limit - availableEnvironments : limit;
  return `${AVAILABLE_ENVIRONMENT_TEXT(used, limit)}`;
};

const SubscriptionsTable = props => {
  const {
    readOnly,
    subscriptionTypes,
    organization,
    subscriptionsData,
    productionSubscriptionsLimit,
    renderSubscriptionDetailPanel,
    renderCreateSubscription,
  } = props;
  const availableProductionSubscriptions = getAvailableProductionSubscription(
    subscriptionsData,
    productionSubscriptionsLimit,
  );

  const { organizationId } = organization ?? {};

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const deleteRef = useRef();
  const switchRef = useRef();
  const editRef = useRef();
  const containerRef = useRef();

  subscriptionsData.forEach((element, index) => {
    element.id = index;
  });

  const fixButtons = () => {
    const buttonsThatNeedAriaLabelFixed = document.querySelectorAll(
      '[aria-label="Detail panel visibility toggle"]',
    );

    buttonsThatNeedAriaLabelFixed.forEach(buttonToFix => {
      const iconWithLabel = buttonToFix.querySelector(
        'span[data-subscription-name]',
      );
      if (
        !iconWithLabel ||
        !iconWithLabel.getAttribute('data-subscription-name')
      ) {
        return;
      }

      buttonToFix.setAttribute(
        'aria-label',
        `${iconWithLabel.getAttribute(
          'data-subscription-name',
        )} detail panel visibility toggle`,
      );
    });
  };

  const addDetailHeaderText = () => {
    if (!containerRef.current) {
      return;
    }

    const tableHeader = containerRef.current.querySelector('th:first-of-type');

    if (tableHeader && !tableHeader.innerHTML) {
      tableHeader.innerHTML = 'More Detail';
      tableHeader.setAttribute(
        'style',
        `${tableHeader.getAttribute('style')}; font-size: 0`,
      );
    }
  };

  // Fix table a11y issues
  const fixTable = React.useCallback(() => {
    fixButtons();
    addDetailHeaderText();
  }, []);

  React.useEffect(() => fixTable(), [subscriptionsData, fixTable]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function renderEdit() {
    return (
      <MenuItem
        className={style.menuItem}
        onClick={() => editRef.current.handleOpen()}
      >
        <ListItemText primary="Rename Tenant" />
      </MenuItem>
    );
  }

  function renderDeactivate() {
    return currentRow.active ? (
      <MenuItem
        className={style.menuItem}
        onClick={() => switchRef.current.handleOpen()}
      >
        <ListItemText primary="Deactivate Tenant" />
      </MenuItem>
    ) : null;
  }

  function renderActivate(availableProductionSubscriptions) {
    return !currentRow.active && availableProductionSubscriptions !== 0 ? (
      <MenuItem
        className={style.menuItem}
        onClick={() => switchRef.current.handleOpen()}
      >
        <ListItemText primary="Activate Tenant" />
      </MenuItem>
    ) : null;
  }

  function renderDelete() {
    return (
      <MenuItem
        className={style.menuItem}
        onClick={() => deleteRef.current.configureDeleteModal()}
      >
        <ListItemText primary="Delete Tenant" />
      </MenuItem>
    );
  }

  const menuAndModals = (
    <>
      <Menu
        id="simple-menu"
        className={style.menu}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {currentRow && renderEdit()}
        {currentRow && renderDeactivate()}
        {currentRow && renderActivate(availableProductionSubscriptions)}
        {!currentRow?.active && renderDelete()}
      </Menu>
      {currentRow && (
        <>
          <DeleteOrganizationSubscription
            rowData={currentRow}
            organization={organization}
            closeMenu={handleClose}
            ref={deleteRef}
          />
          <SubscriptionSwitch
            rowData={currentRow}
            organization={organization}
            closeMenu={handleClose}
            ref={switchRef}
          />
          <EditOrganizationSubscription
            subscription={currentRow}
            environments={subscriptionTypes ?? []}
            identityProviders={organization?.identityProviders ?? []}
            productionSubscriptionsLimitInfo={availableProductionSubscriptions}
            organizationId={organizationId}
            closeMenu={handleClose}
            ref={editRef}
          />
        </>
      )}
    </>
  );

  const columns = [
    {
      title: 'Tenant Name',
      field: 'subscriptionName',
      defaultSort: 'asc',
    },
    {
      title: 'Environment',
      field: 'subscriptionType',
    },
    {
      title: 'Identity Provider',
      sorting: false,
      render: rowData => {
        const { identityProviders } = rowData;
        return identityProviders?.[0]?.name ?? '';
      },
    },
    {
      title: 'Status',
      field: 'active',
      width: '12%',
      render: rowData => {
        const { active } = rowData;
        return (
          <>
            {active ? (
              <span className={`${style.status} ${style.active}`}>ACTIVE</span>
            ) : (
              <span className={`${style.status} ${style.inactive}`}>
                INACTIVE
              </span>
            )}
          </>
        );
      },
    },
  ];

  const debugEnabled =
    sessionStorage.getItem('features')?.includes(FEATURE_TOGGLE_DEBUG) === true;

  if (debugEnabled) {
    columns.splice(1, 0, {
      title: 'ID',
      field: 'subscriptionId',
      render: rowData => {
        return <DebugValueWithClipboard value={rowData.subscriptionId} />;
      },
    });
  }

  //A11y - Setting caption to Subscriptions table
  useSetTableCaption(SUBSCRIPTIONS_TABLE_ID);

  const actionProps = readOnly
    ? {}
    : {
        actions: [
          {
            icon: 'more_horiz',
            onClick: (event, rowData) => {
              handleClick(event, rowData);
            },
          },
        ],
      };

  return (
    <div ref={containerRef} className={style.subTable}>
      <MaterialTable
        style={{ boxShadow: '0px 3px 6px #00000029' }}
        data={subscriptionsData}
        columns={columns}
        onPageChange={() => fixTable()}
        onRowsPerPageChange={() => fixTable()}
        onOrderChange={() => fixTable()}
        localization={{
          pagination: {
            labelRowsPerPage: 'Rows per page',
            labelRowsSelect: '',
          },
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 5,
          toolbar: readOnly ? false : true,
          sorting: true,
          thirdSortClick: false,
          draggable: false,
          headerStyle: {
            backgroundColor: '#f8f8f8',
            color: '#424242',
            fontSize: '1rem',
            fontWeight: 'bold',
          },
          detailPanelColumnStyle: {
            marginLeft: '1em',
          },
          rowStyle: rowData => ({
            backgroundColor: selectedRows.includes(rowData.id)
              ? '#f8f8f8'
              : '#FFF',
            fontSize: '1rem',
            color: '#424242',
            fontFamily: 'Roboto',
          }),
          cellStyle: (data, rowData) => ({
            fontWeight: data === rowData.subscriptionName ? '500' : 'normal',
          }),
        }}
        {...actionProps}
        detailPanel={[
          row => ({
            icon: () => (
              <Icon
                data-subscription-name={row.subscriptionName}
                onClick={event => {
                  setSelectedRows(oldArray => [...oldArray, row.id]);
                }}
              >
                chevron_right
              </Icon>
            ),
            openIcon: () => (
              <Icon
                data-subscription-name={row.subscriptionName}
                onClick={event => {
                  setSelectedRows(
                    selectedRows.filter(function (id) {
                      return id !== row.id;
                    }),
                  );
                }}
              >
                keyboard_arrow_down_icon
              </Icon>
            ),
            render: row => {
              return renderSubscriptionDetailPanel({
                rowData: row.rowData,
                organization,
                readOnly,
              });
            },
          }),
        ]}
        components={{
          Toolbar: props => (
            <div className={style.toolbar}>{renderCreateSubscription}</div>
          ),
        }}
      />
      {readOnly ? <></> : menuAndModals}
    </div>
  );
};

/**
 * Standalone component that renders the 'Create Subscription' button and modal.
 */
const CreateSubscriptionAction = props => {
  const {
    subscriptionTypesData,
    organization,
    subscriptionsData,
    productionSubscriptionsLimit,
  } = props;
  const availableProductionSubscriptions = getAvailableProductionSubscription(
    subscriptionsData,
    productionSubscriptionsLimit,
  );

  const identityProviderOptions = organization?.identityProviders ?? [];
  const environmentOptions = subscriptionTypesData?.subscriptionTypes ?? [];
  const modalButtonText = 'Add New Tenant';

  return (
    <CreateOrganizationSubscription
      modalButtonText={modalButtonText}
      identityProviderOptions={identityProviderOptions}
      environmentOptions={environmentOptions}
      organizationId={organization?.organizationId}
      productionSubscriptionsLimitInfo={{
        availableProductionSubscriptions,
        productionSubscriptionsLimit,
      }}
    />
  );
};

const DisplaySubscriptions = props => {
  const {
    readOnly = false,
    subscriptionTypesData,
    organization,
    productionSubscriptionsLimit,
    renderCreateOrganizationSubscriptionModal,

    renderSubscriptionDetailPanel,
  } = props;

  const subscriptionsData = organization?.organization?.subscriptions;

  const availableProductionSubscriptions = getAvailableProductionSubscription(
    subscriptionsData,
    productionSubscriptionsLimit,
  );

  // Only render the 'Create Subscription' button _and_ modal if we have permissions to edit it.
  const createSubscription = readOnly
    ? null
    : CreateSubscriptionAction({
        subscriptionTypesData,
        organization: organization?.organization,
        subscriptionsData,
        productionSubscriptionsLimit,
        renderCreateOrganizationSubscriptionModal,
      });

  return (
    <>
      <SectionHeader
        title="Tenants"
        third={SUBSCRIPTION_HEADER_HELPFUL_TEXT(
          availableProductionSubscriptions,
          productionSubscriptionsLimit,
        )}
      />
      <SubscriptionsTable
        readOnly={readOnly}
        subscriptionTypes={subscriptionTypesData?.subscriptionTypes}
        organization={organization?.organization}
        subscriptionsData={subscriptionsData}
        renderSubscriptionDetailPanel={renderSubscriptionDetailPanel}
        renderCreateSubscription={createSubscription}
        productionSubscriptionsLimit={productionSubscriptionsLimit}
      />
    </>
  );
};

/**
 * Render the Organization Subscriptions panel.
 * Note: if `readOnly` is `false`, `renderCreateOrganizationSubscriptionModal`,
 * `renderEditOrganizationSubscriptionModal`, `renderSubscriptionSwitchModal`,
 * and `organizationEnvironments` _must_ be defined. Those fields are optional
 * if `readOnly` is `true`.
 */
export const SubscriptionPanel = props => {
  const { subscriptionTypesData = {}, organization = {} } = props;

  const { organizationToEditLoading = false } = organization;
  const { subscriptionTypesLoading = false } = subscriptionTypesData;

  const Loading = () => organizationToEditLoading || subscriptionTypesLoading;

  return (
    <section>
      {Loading() ? (
        <Padding>
          <TableSkeleton />
        </Padding>
      ) : (
        <DisplaySubscriptions {...props} />
      )}
    </section>
  );
};

export default SubscriptionPanel;
