import style from './cardDetails.module.scss';

export const CardDetails = props => {
  const { primaryHeader, secondaryHeader } = props;
  return (
    <>
      <p className={style.cardHeadline}>{primaryHeader}</p>
      <p className={style.cardDescription}>{secondaryHeader}</p>
    </>
  );
};

export default CardDetails;
