import React, { useState, useContext } from 'react';
import NotesForm from 'components/notesForm/notesForm';
import {
  OrganizationNotesSchema,
  FormInitialValues,
} from 'components/notesForm/formSchemaAndInitialValues';
import { Formik } from 'formik';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { Redirect } from 'react-router-dom';
import { createOrganizationNote } from 'utils/api/organizations';
import { AuthContext } from 'contexts/authContext';

const CreateOrganizationNotes = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken } = useContext(AuthContext);
  const { organizationToEdit: { organizationId } } = props ?? {};

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          search: window.location.search,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const sendNote = async (values, { setStatus }) => {

    const payload = {
      note: values?.note
    };

    const { response } = await createOrganizationNote(
      organizationId,
      payload,
      accessToken,
    );

    switch (response?.status) {
      case 201:
        setStatus({ 
          success: true,
          message: 'Note successfully created'
        });
        setRedirect({ isRedirect: true, data: { organizationId } });
        break;
      case 401:
        setStatus({
          success: false,
          message: 'Unauthorized',
        });
        break;
      case 500:
        setStatus({ success: false, message: 'Error creating new organization note. Please try again or contact your Healthwise developers.' });
        break;
      default:
        if (!response) {
          setStatus({ success: false, message: 'Network error' });
        } else {
          setStatus({
            success: false,
            message: 'Unknown error. Please refresh the page.',
          });
        }
        break;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={FormInitialValues}
      validationSchema={OrganizationNotesSchema}
      onSubmit={sendNote}
      children={props => <NotesForm {...props} />}
    />
  );
};

export default CreateOrganizationNotes;
