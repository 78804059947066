import * as configuration from 'utils/configuration/organizations';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;
const tenantUrl = baseUrl + configuration.TENANT_ADMINISTRATOR_ROOT;

export async function resendTenantAdminInvite(
  tenantId,
  userId,
  payload,
  accessToken,
) {
  return await utils.postWithoutContent(
    `${tenantUrl}/${tenantId}/${configuration.TENANT_ADMINISTRATOR}/${userId}/${configuration.TENANT_ADMINISTRATOR_RESEND_INVITE}`,
    {},
    accessToken,
  );
}

export async function createTenantAdministrator(
  tenantId,
  organizationAdministrator,
  accessToken,
) {
  return await utils.createResource(
    `${tenantUrl}/${tenantId}/${configuration.TENANT_ADMINISTRATOR_CREATE}`,
    organizationAdministrator,
    accessToken,
  );
}

export async function getTenantAdministrators(
  subscriptionId = null,
  accessToken = null,
  skip = 0,
  top = 10,
) {
  return await utils.getPaginatedResult(
    `${tenantUrl}/${subscriptionId}/${configuration.TENANT_ADMINISTRATOR}`,
    skip,
    top,
    accessToken,
  );
}

export async function updateTenantAdministratorDates(
  tenantId,
  organizationAdministratoToUpdate,
  accessToken,
) {
  return await utils.postWithoutContent(
    `${tenantUrl}/${tenantId}/${configuration.TENANT_ADMINISTRATOR_DATES_UPDATE}`,
    organizationAdministratoToUpdate,
    accessToken,
  );
}

export async function reactivateTenantAdministrator(
  tenantId,
  userId,
  payload,
  accessToken,
) {
  return await utils.postWithoutContent(
    `${tenantUrl}/${tenantId}/${configuration.TENANT_ADMINISTRATOR}/${userId}/${configuration.TENANT_ADMINISTRATOR_REACTIVATE}`,
    payload,
    accessToken,
  );
}

export async function removeTenantAdministrator(tenantId, userId, accessToken) {
  return await utils.deleteResource(
    `${tenantUrl}/${tenantId}/${configuration.TENANT_ADMINISTRATOR}/${userId}`,
    {},
    accessToken,
  );
}

export async function resetPasswordTenantAdministrator(
  tenantId,
  userId,
  accessToken,
) {
  var body = {
    sendEmail: true,
    userId: userId,
  };

  return await utils.postWithContent(
    `${tenantUrl}/${tenantId}/${configuration.TENANT_ADMINISTRATOR}/${configuration.RESET_PASSWORD}`,
    body,
    accessToken,
  );
}

export async function searchUsers(
  tenantId = '',
  skip = 0,
  top = 10,
  accessToken = null,
  search = '',
  orderByField = 'firstName',
  orderByDirection = 'asc',
) {
  return await utils.getPaginatedResult(
    `${tenantUrl}/${tenantId}/${configuration.TENANT_USERS}`,
    skip,
    top,
    accessToken,
    encodeURIComponent(search),
    orderByField,
    orderByDirection,
  );
}
