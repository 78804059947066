import React from 'react';
import { Link as MaterialLink } from '@mui/material';
import { Link } from 'react-router-dom';

import { ArrowBackIcon } from 'components/icons/icons';

import style from './backToPrevPageLink.module.scss';

export const BackToPrevPageLink = ({ path, text }) => {
  return (
    <div className={style.container}>
      <MaterialLink className={style.backToPrevPage} component={Link} to={{ pathname: path, search: window.location.search }}>
        <ArrowBackIcon />
        <span className={style.text}>{text}</span>
      </MaterialLink>
    </div>
  );
};
