import React from 'react';
import { Card, CardContent } from '@mui/material';
import Organization from 'pages/organizations/components/organization/organization';
import { useHistory } from 'react-router-dom';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import style from './organizationListPanel.module.scss';

const OrganizationsListPanel = ({ organizations }) => {
  const history = useHistory();
  const handleViewOrganizationClick = orgId => {
    history.push({
      pathname: EDIT_ORGANIZATION_PATH,
      search: window.location.search,
      state: {
        organizationId: orgId,
      },
    });
  };

  return (
    <ul className={style.ul}>
      {organizations.map(organization => {
        return (
          <li key={`li-${organization.organizationId}`}>
            <Card
              className={style.card}
              onClick={() =>
                handleViewOrganizationClick(organization.organizationId)
              }
            >
              <CardContent className={style.cardContent}>
                <Organization
                  key={organization.organizationId}
                  {...organization}
                />
              </CardContent>
            </Card>
          </li>
        );
      })}
    </ul>
  );
};

export default OrganizationsListPanel;
