import React from 'react';

const NotFoundPage = () => {
  const [showMessage, setShowMessage] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 2000);
    return () => clearTimeout(timer);
  },[]);

  return <h2 style={{visibility: showMessage ? 'visible' : 'hidden'}}>The requested page was not found</h2>;
}

export default NotFoundPage;
