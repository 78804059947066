import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { TimeAndDateFormatReadable } from 'utils/timeAndDate';
import { useGetUser } from 'utils/hooks/useToCallApiEndpoints';
import { EditIconButton } from 'components/buttons/button';
import style from './savedNotes.module.scss';

const SavedNotes = props => {
  const {
    note: { note: noteText, updatedBy, updatedDate },
    renderDeleteSavedNotes,
    renderEditNotes,
    tenant
  } = props;
  const [showAllText, setShowAllText] = useState(false);
  const [noteTextHeight, setNoteTextHeight] = useState(0);
  const [cutOff, setCutOff] = useState(false);
  const [ editNote, setEditNote ] = useState(false);
  const { data: {firstName, lastName} } = useGetUser(updatedBy);
  let noteRef = useRef(null);

  useEffect(() => {
    setNoteTextHeight(noteRef?.current?.clientHeight);
    if (noteTextHeight > 125) {
      setCutOff(true);
    }
  }, [noteTextHeight]);

  const toggleText = () => {
    setShowAllText(!showAllText);
  };

  const handleClick = () => {
    setEditNote(!editNote)
  }


  return (
      <Card className={`${style.noteCard} ${tenant ? style.tenantCard : ""}`}>
        <CardHeader
          className={style.cardHeader}
          title={<div>
                  <span className={style.name}>{firstName} {lastName} </span><span className={style.slash}>/</span>
                  <span className={style.dateTime}>{TimeAndDateFormatReadable(new Date(updatedDate+'Z'), "MMM dd, yyyy 'at' h:mm aaa")}</span>  
                </div>}
          //Have to wrap the actions in a div with a key bc that's the only way to remove a MUI console.log error
          action={[!editNote && <div key='editButton' style={{display: 'inline'}}><EditIconButton className={style.editBtn} aria-label="Edit note" onClick={handleClick} /></div>, 
                   <div key='deleteButton' className={style.deleteBtn} style={{display: 'inline'}}>{renderDeleteSavedNotes({ firstName, lastName })}</div>]}
        />
        <CardContent className={style.cardContent}>
          {editNote ? 
          renderEditNotes(props, editNote, handleClick) :
          <div>
            <p
              ref={noteRef}
              className={`${style.noteText} ${cutOff ? style.cutOff : ''} ${
                showAllText ? style.showAll : ''
              }`}
            >
              {noteText}
            </p>
            {cutOff && (
              <button onClick={toggleText} className={style.textBtn}>
                {showAllText ? 'Show less' : 'Show more'}
              </button>
            )}
          </div>
          }
        </CardContent>
      </Card>
  );
};
SavedNotes.prototype = {
  editor: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  note: PropTypes.string,
  renderDeleteSaveOrganizationNotes: PropTypes.func.isRequired,
};

export default SavedNotes;
