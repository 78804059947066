import * as configuration from 'utils/configuration/contract';
import { get } from 'utils/http';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;
const searchContactUrl = `${baseUrl}${configuration.CONTRACT_SEARCH}`;

export async function getContractSearch(searchQuery, accessToken) {
  const response = await get(`${searchContactUrl}${searchQuery}`, accessToken);
  return { response, data: response?.json, error: response.error };
}
