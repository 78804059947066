/*
 * Make the http request using fetch(...).
 * If the fetch response in not ok (200-299),
 * the error is packed with the status and message.
 *
 * return values:
 *   response: the full response object
 *   json: the parsed json response
 *   error: any logical or network errors encountered.
 * Any value can be null or undefined
 */
export async function http(request) {
  let response = null;
  let json = null;
  let error = null;

  try {
    response = await fetch(request);

    if (response.ok) {
      if (response.status !== 204) {
        json = await response.json();
      }
    } else {
      let details;

      try {
        details = await response.json();
      } catch {
        /* Doing nothing on purpose */
      }

      // Pack the error message with the status and text
      error = {
        status: response.status,
        message: response.statusText,
        details,
      };
    }
  } catch (e) {
    // This error is a network error.
    // TODO: Need to somehow populate the response object with a failure.
    // Otherwise we have to keep doing response?.ok to check.
    error = e;
  }

  return { response, json, error };
}

export async function get(url, accessToken, headers) {
  var requestHeaders = {
    Accept: 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...headers,
  };

  return await http(
    new Request(url, { method: 'GET', headers: new Headers(requestHeaders) }),
  );
}

export async function post(url, body, accessToken, headers) {
  var requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...headers,
  };

  return await http(
    new Request(url, {
      method: 'POST',
      headers: new Headers(requestHeaders),
      body: body ? JSON.stringify(body) : null,
    }),
  );
}

export async function patch(url, body, etag, accessToken, headers) {
  var requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...(etag && { 'If-Match': `${etag}` }),
    ...headers,
  };

  return await http(
    new Request(url, {
      method: 'PATCH',
      headers: new Headers(requestHeaders),
      body: JSON.stringify(body),
    }),
  );
}

// NOTE: Watch the order of the parameters, they've been switched
//       in comparison to the other methods.
// SEE: https://healthwise.atlassian.net/browse/P40-488
export async function put(url, body, accessToken, etag, headers) {
  var requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...(etag && { 'If-Match': `${etag}` }),
    ...headers,
  };

  return await http(
    new Request(url, {
      method: 'PUT',
      headers: new Headers(requestHeaders),
      body: JSON.stringify(body),
    }),
  );
}

export async function _delete(url, etag, accessToken, headers) {
  var requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...(etag && { 'If-Match': `${etag}` }),
    ...headers,
  };

  return await http(
    new Request(url, {
      method: 'DELETE',
      headers: new Headers(requestHeaders),
    }),
  );
}
