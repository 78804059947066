import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  GeneralCancelButton,
  ConfirmationModalButton,
} from 'components/buttons/button';
import { DEFAULT_BTN_COLOR_CONFIRM_GREEN } from 'utils/dictionary/overview';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import AccountLifespanFields from 'components/users/accountLifespanFields/accountLifespanFields';
import { getMinimumEndDate } from 'containers/users/user';

import style from './reactivationModal.module.scss';

const ReactivationModal = forwardRef((props, ref) => {
  const {
    dateValidationSchema = null,
    personFullName,
    confirmText,
    handleClose,
    handleCancel,
    handleConfirm,
    open,
    title,
    email,
    contentText,
    altText,
    dateText,
    color = DEFAULT_BTN_COLOR_CONFIRM_GREEN,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const userStatus = props.status;

  const internalConfirmationHandler = async eventHandler => {
    setSubmitting(true);
    await eventHandler();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          startDate: userStatus !== 'SUSPENDED' ? props.startDate : '',
          endDate: userStatus !== 'SUSPENDED' ? props.endDate : '',
        }}
        validationSchema={dateValidationSchema}
        children={formikProps => {
          const {
            values: {
              startDate: startDateForm = '',
              endDate: endDateForm = '',
            },
            isValid,
            dirty,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            initialValues,
          } = formikProps;

          const setDateField = fieldName => {
            return newValue => {
              if (!newValue || (newValue instanceof Date && !isNaN(newValue))) {
                setFieldValue(fieldName, newValue, true);
              } else {
                setFieldError(fieldName, 'Invalid Date');
              }

              setFieldTouched(fieldName, true, false);
            };
          };
          const setStartDate = setDateField('startDate');
          const setEndDate = setDateField('endDate');

          return (
            <Dialog
              role="dialog"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
            >
              <DialogTitle className={style.title} id="dialog-title">
                {title}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  className={style.bodyText}
                  id="dialog-description"
                >
                  {contentText}
                  <span className={style.fullName}>
                    <b>{personFullName}</b> at
                  </span>
                  <b> {email}</b>
                  {altText}
                  <p className={style.dateText}>
                    <span>{dateText}</span>
                  </p>
                </DialogContentText>
                <AccountLifespanFields
                  startDate={startDateForm}
                  setStartDate={setStartDate}
                  endDate={endDateForm}
                  setEndDate={setEndDate}
                  focusStartDate={true}
                  minimumEndDate={
                    getMinimumEndDate
                      ? getMinimumEndDate(startDateForm).date
                      : null
                  }
                  initialValues={initialValues}
                />
              </DialogContent>
              <DialogActions className={style.rightAlignedButtonGroup}>
                <GeneralCancelButton
                  onClick={handleCancel}
                  disableRipple
                ></GeneralCancelButton>
                {submitting ? (
                  <ProgressIndicator />
                ) : (
                  <ConfirmationModalButton
                    type="submit"
                    disabled={!(isValid && dirty)}
                    handleClick={() => {
                      internalConfirmationHandler(() =>
                        handleConfirm(formikProps.values),
                      );
                    }}
                    buttonText={confirmText}
                    backgroundColor={color}
                    disableRipple
                  >
                    {confirmText}
                  </ConfirmationModalButton>
                )}
              </DialogActions>
            </Dialog>
          );
        }}
      />
    </>
  );
});

export default ReactivationModal;

ReactivationModal.propTypes = {
  confirmText: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  open: PropTypes.bool.isRequired,
  color: PropTypes.string,
  personFullName: PropTypes.string,
  email: PropTypes.string,
  altText: PropTypes.string,
  dateText: PropTypes.string,
  contentText: PropTypes.string,
};
