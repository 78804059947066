import React, { useState, useEffect, Fragment, useContext } from 'react';
import {
  Checkbox,
  Card,
  CardActions,
  CardContent,
  Collapse,
  FormControlLabel,
} from '@mui/material';
import { ADVISE } from 'utils/dictionary';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import { getRedoxIdForSelectedApplicationsV2 } from 'containers/organizationManagement/editSubscriptionConfigureProducts/utils';
import style from './applicationSelection.module.scss';
import { ExpandButton } from 'components/buttons/button';

import SettingSelect from '../../settingSelect/settingSelect';
import { validateCredential } from 'utils/api/provisioning';
import { AuthContext } from 'contexts/authContext';

const ApplicationSelection = props => {
  const {
    onChange,
    onBlur,
    application = {},
    name,
    selectedApplications = {},
    setFieldTouched,
    setFieldValue,
    errors,
    settingList,
    subscription,
    adviseOptions,
    setRedoxError,
    setFieldError,
  } = props;

  const [redoxId, setRedoxId] = useState('');
  const [redoxFound, setRedoxFound] = useState(false);
  const [selectedApp, setSelectedApp] = useState({});

  const [expanded, setExpanded] = useState({});
  const [adviseApp, setAdviseApp] = useState();
  const [adviseSettings, setAdviseSetting] = useState([]);
  const { accessToken } = useContext(AuthContext);

  const { id: appId, name: appName } = application;

  const appObj = {
    sourceId: appId,
    features: [],
    ...(appId === ADVISE && {
      applicationCredentialId: redoxId ?? '',
    }),
  };

  useEffect(() => {
    if (errors.applicationCredentialId) {
      setFieldTouched('applicationCredentialId');
      if (adviseApp) {
        handleExpand(adviseApp, true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, setFieldTouched]);

  useEffect(() => {
    const adviseApp = application.id === ADVISE;
    if (adviseApp) {
      setAdviseApp(application.id);
      setAdviseSetting(settingList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  useEffect(() => {
    const app = selectedApplications[appId];
    if (app) {
      setSelectedApp(currentState => {
        return {
          ...currentState,
          [appId]: { checked: true },
        };
      });
    }

    const foundRedox =
      getRedoxIdForSelectedApplicationsV2(selectedApplications);
    setRedoxFound(foundRedox.found);
    setRedoxId(foundRedox?.redoxId ?? '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExpand = (id, expand) => {
    if (id === ADVISE) {
      setExpanded(expanded => ({
        ...expanded,
        [id]: expand,
      }));
    }
  };

  const handleChange = e => {
    const checkedValue = e.target.checked;
    setSelectedApp(currentState => {
      return {
        ...currentState,
        [appId]: { checked: checkedValue },
      };
    });
    if (onBlur) {
      onBlur(`${name}.${appId}`, true, false);
    }

    let applications = Object.assign({}, props.values.applications);

    if (!checkedValue) {
      delete applications[appId];

      if (appId === ADVISE) {
        setRedoxId('');
        setRedoxFound(false);
        setAdviseSetting([]);
        setFieldValue('settings', []);
      }
    } else {
      applications[appId] = appObj;
      if (appId === ADVISE) {
        setRedoxFound(true);
      }

      handleExpand(appId, true);
    }
    onChange(`applications`, applications);
  };

  useEffect(() => {
    if (redoxFound && props.values.applications[appId]) {
      let copy = JSON.parse(JSON.stringify(props.values.applications[appId]));
      const isAdvise = copy.sourceId === ADVISE;
      if (isAdvise) {
        copy.applicationCredentialId = redoxId;
        let applications = Object.assign({}, props.values.applications);
        applications[appId] = copy;
        onChange(`applications`, applications);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redoxId, redoxFound, appId]);

  const handleRedoxChange = e => {
    setFieldTouched('applicationCredentialId');
    setFieldValue('applicationCredentialId', redoxId);
    setRedoxId(e.target.value);
  };

  const verifyCredential = async e => {
    if (!e.target.value) {
      return;
    }

    const { error } = await validateCredential(
      subscription.subscriptionId,
      e.target.value,
      accessToken,
    );

    if (error) {
      setFieldError('applicationCredentialId', error.details.error.message);
      if (setRedoxError) {
        setRedoxError({ applicationCredentialId: error.details.error.message });
      }
    } else {
      delete errors['applicationCredentialId'];
      if (setRedoxError) {
        setRedoxError({});
      }
    }
    setFieldTouched('applicationCredentialId');
  };

  function getExpandLabel(expand, appName) {
    return expand ? `Collapse ${appName}` : `Expand ${appName}`;
  }
  return (
    <Card className={style.card}>
      <div className={style.cardModule}>
        <FormControlLabel
          className={style.moduleCheckboxLabel}
          control={
            <Checkbox
              onChange={handleChange}
              inputProps={{ 'aria-label': appName }}
              checked={selectedApp[appId]?.checked ?? false}
              className={style.moduleCheckBox}
              style={{ pointerEvents: 'auto' }}
            />
          }
          label={appName}
        />

        <CardActions className={style.cardActions}>
          {application.id === ADVISE && (
            <ExpandButton
              className={style.expand}
              expand={expanded[appId] ? +expanded[appId] : undefined}
              onClick={() => handleExpand(appId, !expanded[appId])}
              aria-label={getExpandLabel(expanded[appId], appName)}
            />
          )}
        </CardActions>
      </div>
      <Collapse in={expanded[appId]}>
        <CardContent className={style.cardContent}>
          <Fragment>
            {application.id === ADVISE && (
              <>
                <div>
                  <span className={style.identifier}>Identifiers</span>
                </div>
                <div className={style.redoxInput}>
                  <span className={style.input}>Redox Id</span>
                  <BaselineTextInput
                    isRequiredErrorMessage={false}
                    id="applicationCredentialId"
                    name="applicationCredentialId"
                    margin="none"
                    size="small"
                    value={redoxId}
                    onChange={handleRedoxChange}
                    onBlur={verifyCredential}
                    className={style.input}
                    disabled={!redoxFound}
                    hideValidationAdornment={true}
                  />
                </div>
                <SettingSelect
                  {...props}
                  subscriptionId={subscription.subscriptionId}
                  field="settings"
                  options={adviseOptions}
                  settingList={adviseSettings}
                  id="advise"
                  addDefault={selectedApp[appId]?.checked}
                ></SettingSelect>
              </>
            )}
          </Fragment>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ApplicationSelection;
