import React from 'react';

import { Chip } from '@mui/material';

import { getStatusName } from 'utils/statusNames';

import style from './statusChip.module.scss';

const StatusChip = ({ status, startDate }) => {
  const statusName = getStatusName(status, startDate);
  const className = statusName
    .trim()
    .toLowerCase()
    .replace(/[^a-z]/g, '');

  return (
    <Chip className={`${style.statusChip} ${className}`} label={statusName} />
  );
};

export default StatusChip;
