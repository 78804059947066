import {
  Button as MaterialButton,
  Dialog as MaterialDialog,
  DialogTitle as MaterialDialogTitle,
  DialogContent as MaterialDialogContent,
  DialogContentText as MaterialDialogContentText,
  DialogActions as MaterialDialogActions,
  Divider as MaterialDivider,
} from '@mui/material';
import style from './dialog.module.scss';

export const ConfirmDialogButton = props => (
  <MaterialButton {...props}>{props.children}</MaterialButton>
);

export const Dialog = props => (
  <MaterialDialog className={style.dialog} {...props}>
    {props.children}
  </MaterialDialog>
);

export const DialogTitle = props => (
  <MaterialDialogTitle
    className={style.dialogTitle}
    {...props}
    aria-hidden="true"
  >
    {props.children}
  </MaterialDialogTitle>
);

export const DialogSectionTitle = props => (
  <div className={style.dialogSectionTitle} {...props}>
    {props.children}
  </div>
);

export const DialogContent = ({ className, ...props }) => (
  <MaterialDialogContent
    className={`${style.dialogContent} ${className}`}
    {...props}
  >
    {props.children}
  </MaterialDialogContent>
);

export const DialogContentText = props => (
  <MaterialDialogContentText className={style.dialogContentText} {...props}>
    {props.children}
  </MaterialDialogContentText>
);

export const DialogSubSectionText = props => (
  <p className={style.dialogSubSectionText} {...props}>
    {props.children}
  </p>
);

export const DialogActions = props => (
  <MaterialDialogActions className={style.dialogActions} {...props}>
    {props.children}
  </MaterialDialogActions>
);

export const Divider = props => (
  <MaterialDivider className={style.divider} {...props} aria-hidden="true" />
);
