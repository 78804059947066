import { useEffect } from 'react';

/**
 * Adds an aria live region to index.html bc live regions in SPA tend to not work or are inconsistent
 * bc screen reads look for the live region on intial page load
 */

export const useAddAriaLiveRegion = ( id, message) => {
  const liveRegionContainer = document.getElementById("ariaLiveRegionContainer")
  liveRegionContainer?.setAttribute("aria-hidden", false)

  useEffect(()=> {
    if(document.getElementById(id)){
    document.getElementById(id).textContent = message;

    } else {
      const liveRegion = document.createElement("div")
      liveRegion.setAttribute("id", id);
      liveRegion.textContent = message;
      liveRegionContainer?.appendChild(liveRegion);
    }

  },[id, message, liveRegionContainer])
};

export default useAddAriaLiveRegion;
