import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Container } from '@mui/material';

import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import { PageLink } from 'components/pageLink/pageLink';
import Notification from 'components/notificationV2/notification';
import { PageHeader } from 'components/pageHeader/pageHeader';
import DelegatedUserCard from './delegatedUserCard';
import { useDelegatedAccess } from 'contexts/delegatedAccessContext';
import { CardSkeleton } from 'components/skeletons/skeletons';
import { AddIcon } from 'components/icons/icons';
import {
  DEFAULT_BTN_COLOR_CONFIRM_RED,
  REVOKE_ACCESS_BTN_TEXT,
  REVOKE_ACCESS_MODAL_TITLE,
  REVOKE_ACCESS_MODAL_TEXT,
  DOC_TITLE_DELEGATED_ACCESS,
} from 'utils/dictionary/overview';
import { useUpdateDocumentTitle } from 'utils/hooks/common/useUpdateDocumentTitle';
import style from './delegatedAccessList.module.scss';

const returnAllCounts = subscriptions => {
  let totalCounts = {
    subs: 0,
    apps: 0,
    roles: 0,
  };

  if (subscriptions.length) {
    totalCounts.subs = subscriptions.length;

    subscriptions.forEach(sub => {
      if (sub.applications.length) {
        totalCounts.apps += sub.applications.length;

        sub.applications.map(app => {
          return app.roles.length
            ? (totalCounts.roles += app.roles.length)
            : null;
        });
      }
    });
  }

  return totalCounts;
};

const DelegatedAccessList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [userToRemove, setUserToRemove] = useState(null);

  let { path } = useRouteMatch();

  let {
    accessToken,
    listIsLoading,
    currentDelegates,
    setReloadList,
    putDelegatesCurrent,
    availableDelegates,
  } = useDelegatedAccess();

  const handleModalOpen = function (rowData) {
    setUserToRemove(rowData);
    setModalOpen(true);
  };

  const handleModalClose = () => setModalOpen(false);

  const handleRemoveAccess = async () => {
    try {
      const { response, error } = await putDelegatesCurrent(
        userToRemove.userId,
        [],
        accessToken,
      );
      if (response?.ok) {
        setSuccess('User’s access revoked.');
        setReloadList(true);
      } else {
        setError(error.message);
      }
    } catch (error) {
      setError(error.message);
    }
    setModalOpen(false);
  };

  useUpdateDocumentTitle(DOC_TITLE_DELEGATED_ACCESS);

  return (
    <section>
      {error ? <Notification error message={error} messageKey={error} /> : null}
      {success ? <Notification message={success} messageKey={success} /> : null}
      <PageHeader
        title="Delegate Access"
        secondary="Provide a Healthwise Employee access to your applications for technical assistance."
      />
      {modalOpen ? (
        <ConfirmationModal
          open={modalOpen}
          title={REVOKE_ACCESS_MODAL_TITLE}
          personFullName={`${userToRemove.fullName}`}
          text={REVOKE_ACCESS_MODAL_TEXT}
          confirmText={REVOKE_ACCESS_BTN_TEXT}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          handleConfirm={handleRemoveAccess}
          color={DEFAULT_BTN_COLOR_CONFIRM_RED}
        />
      ) : null}
      <Container maxWidth="xl">
        <div style={{ padding: '0px 4%' }}>
          {listIsLoading ? (
            <>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </>
          ) : currentDelegates?.length ? (
            currentDelegates.map(delegate => (
              <DelegatedUserCard
                handleModalOpen={handleModalOpen}
                returnAllCounts={returnAllCounts}
                delegate={delegate}
                path={path}
                key={delegate.userId}
              />
            ))
          ) : (
            <p className={style.noDelegatesText}>
              There are no Healthwise employees with delegated access. Add a new
              delegate to provide access.
            </p>
          )}
          <div className={style.addNewDiv}>
            <PageLink
              icon={<AddIcon />}
              text="Add New Delegate"
              to={{
                pathname: `${path}/edit`,
                search: window.location.search,
              }}
              disabled={!availableDelegates || !availableDelegates.length}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default DelegatedAccessList;
