import { useEffect } from 'react';

export function useAddFocus(refObject, focus = false) {
  useEffect(() => {
    if (refObject && focus) {
      setTimeout(() => {
        refObject.current?.focus();
      }, 0);
    }
  }, [refObject, focus]);
}
